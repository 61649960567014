.teaser.teaser--link-list {
  container: link-list-teaser / inline-size;
  position: relative;
  overflow: hidden;
  background-color: $link-list-teaser-background;

  .cmp-teaser {
    position: relative;
    padding-bottom: 149.6%;

    @container link-list-teaser (inline-size > 650px) {
      padding-bottom: 125%;
    }

    @container link-list-teaser (inline-size > 900px) {
      padding-bottom: 110%;
    }

    @container link-list-teaser (inline-size > 1150px) {
      padding-bottom: 92%;
    }

    @container link-list-teaser (inline-size > 1300px) {
      padding-bottom: 75%;
    }
  }

  .cmp-teaser__content {
    position: absolute;
    left: 0;
    top: 52.5%;
    height: 47.5%;
    padding: rem(20) 6% 0;
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;

    a {
      text-decoration: none;
    }

    @container link-list-teaser (inline-size > 399px) {
      padding-left: rem(28);
      padding-right: rem(28);
    }
  }

  .cmp-teaser__pretitle, .cmp-teaser__back-pretitle {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    height: rem(73);
    align-items: center;
    overflow: hidden;
    width: 100%;
    color: $link-list-teaser-description-color;
    cursor: pointer;
    transition: color 0.15s ease-in-out;
    padding: rem(14) calc(6% + #{rem(36)}) rem(26) 6%;
    font-size: $font-size-2;
    line-height: $font-height-2;
    margin: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: rem(26);
      width: rem(33);
      height: rem(33);
      border-radius: 50%;
      border: 1px solid $link-list-teaser-icon-background;
      background-color: $link-list-teaser-icon-border;
      transition: background-color 0.15s ease-in-out;
      right: 6%;

      @container link-list-teaser (inline-size > 399px) {
        right: rem(28);
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: rem(26);
      width: rem(33);
      height: rem(33);
      background-color: $link-list-teaser-icon-color;
      mask-size: 100%;
      transition: background-color 0.15s ease-in-out;
      right: 6%;

      @container link-list-teaser (inline-size > 399px) {
        right: rem(28);
      }
    }

    &:hover, &:focus {
      color: $link-list-teaser-description-hover-color;
      text-decoration: underline;
      outline: none;

      p {
        color: $link-list-teaser-description-hover-color;
      }

      &::before {
        background-color: $link-list-teaser-icon-background-hover;
        border-color: $link-list-teaser-icon-border-hover;
      }

      &::after {
        background-color: $link-list-teaser-icon-color-hover;
      }
    }

    @container link-list-teaser (inline-size > 310px) {
      font-size: $font-size-1;
      line-height: $font-height-1;
    }

    @container link-list-teaser (inline-size > 380px) {
      font-size: $font-flagtitle-size;
      line-height: $font-flagtitle-height;
    }

    @container link-list-teaser (inline-size > 399px) {
      padding-left: rem(28);
      padding-right: rem(70);
    }
  }

  .cmp-teaser__pretitle-in, .cmp-teaser__back-pretitle-in {
    overflow: hidden;
    max-height: 3rem;
  }

  .cmp-teaser__title, .cmp-teaser__back-title {
    position: relative;
    top: 0;
    left: 0;
    color: $link-list-teaser-title-color;
    text-shadow: none;
    font-weight: $link-list-teaser-title-weight;
    overflow: hidden;
    z-index: 2;
    font-size: $font-title-size-8-mobile;
    line-height: $font-title-height-8-mobile;
    height: 2 * $font-title-height-8-mobile * $font-title-size-8-mobile;
    hyphens: auto;
    overflow-wrap: break-word;

    @if $link-list-teaser-title-uppercase {
      text-transform: uppercase;
    }

    .cmp-teaser__title-link {
      color: $link-list-teaser-title-color;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }

    @container link-list-teaser (inline-size > 310px) {
      font-size: $font-title-size-4-mobile;
      line-height: $font-title-height-4-mobile;
      height: 2 * $font-title-height-4-mobile * $font-title-size-4-mobile;
    }

    @container link-list-teaser (inline-size > 380px) {
      font-size: $font-title-size-5;
      line-height: $font-title-height-5;
      height: 2 * $font-title-height-5 * $font-title-size-5;
    }
  }

  .cmp-teaser__description {
    display: block;
    position: relative;
    left: 0;
    top: 0;
    color: $link-list-teaser-pretitle-color;
    overflow: hidden;
    font-size: $font-size-2;
    line-height: $font-height-2;
    height: 2 * $font-height-2 * $font-size-2;
    margin: 0 0 rem(5) 0;

    .cmp-teaser__description-in {
      overflow: hidden;
      max-height: $font-size-2 * $font-height-2 * 2;

      @container link-list-teaser (inline-size > 310px) {
        max-height: $font-size-1 * $font-height-1 * 2;
      }

      @container link-list-teaser (inline-size > 380px) {
        max-height: $font-flagtitle-size * $font-flagtitle-height * 2;
      }
    }

    p {
      color: $link-list-teaser-description-color;
      margin: 0;
      transition: color 0.15s ease-in-out;
      font-size: $font-size-2;
      line-height: $font-height-2;
      max-height: 2 * $font-height-2 * $font-size-2;

    @container link-list-teaser (inline-size > 310px) {
      font-size: $font-size-1;
      line-height: $font-height-1;
      max-height: 2 * $font-height-1 * $font-size-1;
    }

    @container link-list-teaser (inline-size > 380px) {
      font-size: $font-flagtitle-size;
      line-height: $font-flagtitle-height;
      max-height: none;
    }
    }

    @container link-list-teaser (inline-size > 310px) {
      margin: 0 0 rem(15) 0;
      font-size: $font-size-1;
      line-height: $font-height-1;
      height: 2 * $font-height-1 * $font-size-1;
    }

    @container link-list-teaser (inline-size > 380px) {
      margin: 0 0 rem(30) 0;
      font-size: $font-flagtitle-size;
      line-height: $font-flagtitle-height;
      height: 2 * $font-flagtitle-height * $font-flagtitle-size;
    }
  }

  .cmp-teaser__pretitle {

    &::after {
      @extend .icon-plus;
    }
  }

  .cmp-teaser__back-pretitle {
    color: $link-list-teaser-back-description-color;

    p {
      color: $link-list-teaser-back-description-color;
    }

    &:before {
      background-color: $link-list-teaser-back-icon-background;
      border-color: $link-list-teaser-back-icon-border;
    }

    &::after {
      @extend .icon-minus;
      background-color: $link-list-teaser-back-icon-color;
    }

    &:hover {
      color: $link-list-teaser-back-description-hover-color;

      &::before {
        background-color: $link-list-teaser-back-icon-background-hover;
        border-color: $link-list-teaser-back-icon-border-hover;
      }

      &::after {
        background-color: $link-list-teaser-back-icon-color-hover;
      }
    }
  }

  .cmp-teaser__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 52.5%;
    cursor: pointer;
    overflow: hidden;

    & .cmp-image {
      margin: 0;
      height: 100%;
    }

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      display: none;
    }
  }

  .cmp-teaser__action-container {
    display: none;
  }

  .cmp-teaser__action-link {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
  }

  .cmp-teaser__back-side {
    position: absolute;
    z-index: 2;
    top: 100%;
    height: 100%;
    background-color: $link-list-teaser-back-background;
    transition: top 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    left: 0;
    width: 100%;
    padding: rem(40) 6% rem(26);

    @container link-list-teaser (inline-size > 280px) {
      padding-top: rem(58);
    }

    @container link-list-teaser (inline-size > 399px) {
      padding-left: rem(28);
      padding-right: rem(28);
    }
  }

  &.active .cmp-teaser__back-side {
    top: 0;
  }

  .cmp-teaser__back-title {
    color: $link-list-teaser-back-title-color;
  }

  .cmp-teaser__back-action-links {
    margin: rem(20) 0 0 0;
    padding-left: 0;

    li {
      list-style-type: none;
      display: none;
      margin-bottom: rem(6);

      &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        display: flex;
      }

      @container link-list-teaser (inline-size > 310px) {
        margin-bottom: rem(12);
      }
    }

    a {
      color: $link-list-teaser-back-link-color;
      font-weight: $font-weight-semi-bold;
      text-decoration: none;
      display: inline-block;
      padding-top: rem(2);
      padding-bottom: rem(2);
      overflow: hidden;
      padding-left: rem(49);
      position: relative;
      font-size: $font-flagtitle-size;
      line-height: $font-flagtitle-height;
      height: 2 * $font-flagtitle-size * $font-flagtitle-height + 0.25;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: rem(33);
        height: rem(33);
        background-color: $link-list-teaser-back-icon-background;
        border-radius: 50%;
        border: 1px solid $link-list-teaser-back-icon-border;
        transition: background-color 0.15s ease-in-out;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: rem(33);
        height: rem(33);
        @extend .icon-arrow-diagonal;
        mask-size: 100%;
        background-color: $link-list-teaser-back-icon-color;
        transition: background-color 0.15s ease-in-out;
      }

      &:hover, &:focus {
        text-decoration: underline;
        outline: none;

        &::before {
          background-color: $link-list-teaser-back-icon-background-hover;
            border-color: $link-list-teaser-back-icon-border-hover;
        }

        &::after {
          background-color: $link-list-teaser-back-icon-color-hover;
        }
      }

      @container link-list-teaser (inline-size > 380px) {
        font-size: $font-title-size-8;
        line-height: 1.2727;
        height: 2 * $font-title-size-8 * 1.2727 + 0.25;
      }
    }
  }

  &.teaser--style-secondary {
    background-color: $link-list-teaser-secondary-background;

    .cmp-teaser__pretitle, .cmp-teaser__back-pretitle {
      color: $link-list-teaser-secondary-description-color;

      &::before {
        border: 1px solid $link-list-teaser-secondary-icon-background;
        background-color: $link-list-teaser-secondary-icon-border;
      }

      &::after {
        background-color: $link-list-teaser-secondary-icon-color;
      }

      &:hover, &:focus {
        color: $link-list-teaser-secondary-description-hover-color;

        p {
          color: $link-list-teaser-secondary-description-hover-color;
        }

        &::before {
          background-color: $link-list-teaser-secondary-icon-background-hover;
          border-color: $link-list-teaser-secondary-icon-border-hover;
        }

        &::after {
          background-color: $link-list-teaser-secondary-icon-color-hover;
        }
      }
    }

    .cmp-teaser__title, .cmp-teaser__back-title {
      color: $link-list-teaser-secondary-title-color;

      .cmp-teaser__title-link {
        color: $link-list-teaser-secondary-title-color;
      }
    }

    .cmp-teaser__description {
      color: $link-list-teaser-secondary-pretitle-color;

      p {
        color: $link-list-teaser-secondary-description-color;
      }
    }

    .cmp-teaser__back-pretitle {
      color: $link-list-teaser-back-secondary-description-color;

      p {
        color: $link-list-teaser-back-secondary-description-color;
      }

      &:before {
        background-color: $link-list-teaser-back-secondary-icon-background;
        border-color: $link-list-teaser-back-secondary-icon-border;
      }

      &::after {
        background-color: $link-list-teaser-back-secondary-icon-color;
      }

      &:hover {
        color: $link-list-teaser-back-secondary-description-hover-color;

        &::before {
          background-color: $link-list-teaser-back-secondary-icon-background-hover;
          border-color: $link-list-teaser-back-secondary-icon-border-hover;
        }

        &::after {
          background-color: $link-list-teaser-back-secondary-icon-color-hover;
        }
      }
    }

    .cmp-teaser__back-side {
      background-color: $link-list-teaser-back-secondary-background;
    }

    .cmp-teaser__back-title {
      color: $link-list-teaser-back-secondary-title-color;
    }

    .cmp-teaser__back-action-links {

      a {
        color: $link-list-teaser-back-secondary-link-color;

        &::before {
          background-color: $link-list-teaser-back-secondary-icon-background;
          border: 1px solid $link-list-teaser-back-secondary-icon-border;
        }

        &::after {
          background-color: $link-list-teaser-back-secondary-icon-color;
        }

        &:hover, &:focus {
          &::before {
            background-color: $link-list-teaser-back-secondary-icon-background-hover;
            border-color: $link-list-teaser-back-secondary-icon-border-hover;
          }

          &::after {
            background-color: $link-list-teaser-back-secondary-icon-color-hover;
          }
        }
      }
    }
  }

  &.teaser--style-tertiary {
    background-color: $link-list-teaser-tertiary-background;

    .cmp-teaser__pretitle, .cmp-teaser__back-pretitle {
      color: $link-list-teaser-tertiary-description-color;

      &::before {
        border: 1px solid $link-list-teaser-tertiary-icon-background;
        background-color: $link-list-teaser-tertiary-icon-border;
      }

      &::after {
        background-color: $link-list-teaser-tertiary-icon-color;
      }

      &:hover, &:focus {
        color: $link-list-teaser-tertiary-description-hover-color;

        p {
          color: $link-list-teaser-tertiary-description-hover-color;
        }

        &::before {
          background-color: $link-list-teaser-tertiary-icon-background-hover;
          border-color: $link-list-teaser-tertiary-icon-border-hover;
        }

        &::after {
          background-color: $link-list-teaser-tertiary-icon-color-hover;
        }
      }
    }

    .cmp-teaser__title, .cmp-teaser__back-title {
      color: $link-list-teaser-tertiary-title-color;

      .cmp-teaser__title-link {
        color: $link-list-teaser-tertiary-title-color;
      }
    }

    .cmp-teaser__description {
      color: $link-list-teaser-tertiary-pretitle-color;

      p {
        color: $link-list-teaser-tertiary-description-color;
      }
    }

    .cmp-teaser__back-pretitle {
      color: $link-list-teaser-back-tertiary-description-color;

      p {
        color: $link-list-teaser-back-tertiary-description-color;
      }

      &:before {
        background-color: $link-list-teaser-back-tertiary-icon-background;
        border-color: $link-list-teaser-back-tertiary-icon-border;
      }

      &::after {
        background-color: $link-list-teaser-back-tertiary-icon-color;
      }

      &:hover {
        color: $link-list-teaser-back-tertiary-description-hover-color;

        &::before {
          background-color: $link-list-teaser-back-tertiary-icon-background-hover;
          border-color: $link-list-teaser-back-tertiary-icon-border-hover;
        }

        &::after {
          background-color: $link-list-teaser-back-tertiary-icon-color-hover;
        }
      }
    }

    .cmp-teaser__back-side {
      background-color: $link-list-teaser-back-tertiary-background;
    }

    .cmp-teaser__back-title {
      color: $link-list-teaser-back-tertiary-title-color;
    }

    .cmp-teaser__back-action-links {

      a {
        color: $link-list-teaser-back-tertiary-link-color;

        &::before {
          background-color: $link-list-teaser-back-tertiary-icon-background;
          border: 1px solid $link-list-teaser-back-tertiary-icon-border;
        }

        &::after {
          background-color: $link-list-teaser-back-tertiary-icon-color;
        }

        &:hover, &:focus {
          &::before {
            background-color: $link-list-teaser-back-tertiary-icon-background-hover;
            border-color: $link-list-teaser-back-tertiary-icon-border-hover;
          }

          &::after {
            background-color: $link-list-teaser-back-tertiary-icon-color-hover;
          }
        }
      }
    }
  }
}
