.cmp-header__search {
  pointer-events: none;
  padding: 0 8.2% 0 13.7%;
  display: flex;
  flex-wrap: wrap;

  form {
    width: 100%;
  }

  @include until($breakpoint-medium) {
    padding: 0 5% 0 5%;
    height: 100dvh;
    align-content: flex-start;

    &::before {
      width: 100%;
    }
  }
}

.cmp-header__search--active {
  pointer-events: auto;
  padding: rem(17) 8.2% rem(194) 13.7%;

  @include until($breakpoint-medium) {
    padding: 0 5% rem(80) 5%;
  }
}

.cmp-header__search-field-wrap {
  width: 100%;
  position: relative;
  padding-bottom: rem(29);

  @include until($breakpoint-medium) {
    padding-bottom: rem(20);
  }
}

.cmp-header__search-field {

}

.cmp-header__search-label {
  display: none;
}

input[type=text].cmp-header__search-input, input[type=text].cmp-header__search-autocomplete {
  padding: rem(24) rem(74) rem(24) 0;
  border-radius: 0;
  width: 100%;
  max-width: 100%;
  font-weight: $font-weight-extra-bold;
  font-size: $font-title-size-5;
  line-height: 1.222;
  color: $header-search-color;
  position: relative;
  left: rem(-3);
  background-color: $header-background;
  border: none;
  z-index: 1;

  &::placeholder {
    font-weight: $font-weight-light;
    color: $header-search-placeholder-color;
  }

  @include until($breakpoint-medium) {
    padding: rem(18) rem(46) rem(18) 0;
    font-size: $font-title-size-6-mobile;
  }
}

input[type=text].cmp-header__search-input {

  @include until($breakpoint-medium) {
    border-bottom: 1px solid $header-main-border-color;
  }
}

input[type=text].cmp-header__search-input--active {
  background-color: transparent;
}

input[type=text].cmp-header__search-autocomplete {
  color: $header-search-autocomplete-color;
  z-index: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &:disabled {
    color: $header-search-autocomplete-color;
  }
}

.cmp-header__search-button {
  position: absolute;
  right: rem(7);
  top: rem(10);
  z-index: 2;
  width: rem(60);
  height: rem(60);
  overflow: hidden;
  text-indent: 1000%;
  white-space: nowrap;
  border: none;
  background-color: $header-search-icon-color;
  mask-size: rem(44) rem(44);
  mask-repeat: no-repeat;
  mask-position: center;
  cursor: pointer;
  transition: background-color 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  transition-property: background-color, top;
  @extend .icon-lupe-xl;

  &:hover, &:focus {
    background-color: $header-generic-secondary-icon-active-color;
  }

  @include until($breakpoint-medium) {
    width: 2.5rem;
    height: 2.5rem;
    top: .6875rem;
    mask-size: 2.375rem 2.375rem;
  }
}

.cmp-header__search-suggestion-wrapper {
  position: relative;
  width: 100%;

  @include until($breakpoint-medium) {
    margin-top: 4px;
  }
}

.cmp-header__search-suggestion {
  width: 100%;
  position: relative;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  border-top: 0 solid $header-main-border-color;

  @include until($breakpoint-medium) {
    transition-property: max-height, margin-top;
    margin-top: 0;
  }
}

.cmp-header__search-suggestion--active {
  max-height: rem(600);
  border-top: 1px solid $header-main-border-color;

  @include until($breakpoint-medium) {
    margin-top: rem(15);
    border-top: 0;
  }
}

.cmp-header__search-suggestion-label {
  font-weight: $font-weight-semi-bold;
  font-size: $font-date;
  line-height: $font-date-height;
  letter-spacing: 1px;
  position: absolute;
  top: rem(-9);
  left: rem(-111);
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  @include until($breakpoint-medium) {
    top: rem(-4);
    left: rem(0);
  }
}

.cmp-header__search-suggestion-label--active {
  opacity: 1;
}

.cmp-header__search-suggestion-list {
  padding-left: 0;
  margin-top: rem(10);
  margin-bottom: 0;
}

.cmp-header__search-suggestion-item {
  list-style-type: none;
  font-weight: $font-weight-regular;
  font-size: $font-title-size-7;
  line-height: $font-title-height-7;
  color: $header-search-suggestion-color;
  margin-bottom: rem(6);

  @include until($breakpoint-medium) {
    font-size: $font-title-size-8-mobile;
    line-height: $font-title-height-8-mobile;
  }
}

.cmp-header__search-suggestion-item--active {

  .cmp-header__search-suggestion-link {
    text-decoration: underline;
  }
}

.cmp-header__search-suggestion-link {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: $font-weight-regular;
  font-size: $font-title-size-7;
  line-height: $font-title-height-7;
  color: $header-search-suggestion-color;

  @include until($breakpoint-medium) {
    font-size: $font-title-size-8-mobile;
    line-height: $font-title-height-8-mobile;
  }

  &:hover, &:focus {
    text-decoration: underline;
  }
}

.cmp-header__search-top-line {
  font-weight: $font-weight-extra-bold;
  font-size: $font-title-size-5;
  line-height: 1.222;
  position: absolute;
  left: rem(-3);
  top: rem(-1);
  background-color: $header-search-top-line-color;
  height: 2px;
  overflow: hidden;
  z-index: 10;
  transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  @include until($breakpoint-medium) {
    font-size: $font-title-size-3-mobile;
    top: auto;
    bottom: 20px;
  }
}
