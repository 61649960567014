.cmp-download {
  display: flex;
  align-items: center;
  width: 88%;
  margin: rem(60) 6%;
  padding-top: rem(44);
  padding-bottom: rem(23);
  border-top: 2px solid $download-border-color;
  border-bottom: 2px solid $download-border-color;

  @include until($breakpoint-small) {
    width: 90%;
    margin: rem(40) 5%;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    padding-top: rem(30);
    padding-bottom: rem(40);
  }
}

.cmp-download__pretitle {
  width: 17%;
  font-size: $font-title-size-8;
  line-height: $font-title-height-8;
  font-weight: $font-weight-regular;
  color: $download-text-color;
  padding-right: rem(16);

  span {
    text-transform: uppercase;
  }

  @include until($breakpoint-large) {
    font-size: $font-size-1;
    line-height: $font-height-1;
  }

  @include until($breakpoint-small) {
    display: none;
  }
}

.cmp-download__title {
  display: flex;
  width: 42.7%;
  color: $download-text-color;
  font-size: $font-title-size-8;
  line-height: 1.27;
  font-weight: $font-weight-bold;
  padding: 0 rem(16) 0 0;
  transition: padding 0.4s ease-in-out;
  position: relative;

  &:hover {
    padding-left: rem(89);
  }

  @include until($breakpoint-large) {
    font-size: $font-size-1;
    line-height: $font-height-1;
  }

  @include until($breakpoint-small) {
    font-size: $font-flagtitle-size;
    line-height: $font-flagtitle-height;
    position: static;
    padding-left: rem(89);
    margin-bottom: rem(24);
    width: 100%;

    &.cmp-download__title--no-description {
      margin-bottom: rem(34);
      min-height: rem(48);
    }
  }
}

.cmp-download__title-link {
  color: $download-text-color;
  text-decoration: none;
  display: block;
  width: 100%;

  &:hover,
  &:focus {
    color: $download-text-color;
    text-decoration: none;
  }
}

.cmp-download__title-type {
  position: absolute;
  left: 0;
  top: 50%;
  width: rem(72);
  height: rem(72);
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: $download-icon-background;
  color: $download-icon-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: $font-date;
  line-height: $font-date-height;
  font-weight: $font-weight-regular;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;

  &::before {
    content: '';
    width: rem(24);
    height: rem(24);
    background-color: $download-icon-color;
    mask-repeat: no-repeat;
    mask-size: 100%;
    @extend .icon-file;
    margin-bottom: rem(3);
  }

  .cmp-download__title:hover & {
    opacity: 1;
  }

  @include until($breakpoint-small) {
    opacity: 1;
    top: rem(30);
    transform: none;
  }
}

.cmp-download__title-size {
  display: none;
  position: absolute;
  left: 0;
  bottom: rem(50);
  font-size: $font-button-mobile;
  line-height: $font-button-height-mobile;
  font-weight: $font-weight-semi-bold;
  width: 4.5rem;
  text-align: center;

  @include until($breakpoint-small) {
    display: block;
  }
}

.cmp-download__description,
.cmp-download__description--secondary {
  font-size: $font-size-2;
  line-height: $font-height-2;
  font-weight: $font-weight-semi-bold;
  color: $download-text-color;

  p {
    font-size: $font-size-2;
    line-height: $font-height-2;
    font-weight: $font-weight-semi-bold;
    color: $download-text-color;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.cmp-download__description {
  display: none;
  padding-left: rem(89);
  margin-bottom: rem(40);

  @include until($breakpoint-small) {
    display: block;
  }
}

.cmp-download__description--secondary {
  display: block;
  width: 23.28%;
  margin-left: 6.7%;

  @include until($breakpoint-small) {
    display: none;
  }
}

.cmp-download__action {
  @include add_button_base();
  @include add_button_style($download--button-type);
  @include add_button_medium();
  display: flex;
  flex-direction: row-reverse;
  margin: 0 0 0 auto !important;
  align-items: center;

  &::before {
    content: "";
    display: inline-block;
    width: rem(22);
    height: 1.75rem;
    margin: -0.3125rem -0.625rem -0.3125rem 0.75rem;
    background-color: map-get($buttons, $download--button-type, font-color);
    transition: background-color 0.2s ease-in-out;
    position: relative;
    z-index: 1;
    mask-size: rem(22) rem(22);
    mask-repeat: no-repeat;
    mask-position: center;
    @extend .icon-download;
  }

  &:focus {
    &::before {
      content: "";
      display: inline-block !important;
      background-color: $download-icon-color;
      position: relative;
      width: rem(22);
      height: 1.75rem;
    }
  }

  &:hover {
    &::before {
      background-color: map-get($buttons, secondary, hover-font-color);
    }
  }

  @include until($breakpoint-small) {
    margin-left: rem(89) !important;
  }
}

.cmp-download__properties {
  display: none;
}

.download {

  &+& {

    .cmp-download {
      margin-top: rem(-60);
      border-top: none;

      @include until($breakpoint-small) {
        margin-top: rem(-40);
      }
    }
  }
}
