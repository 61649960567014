.list--text {

  .cmp-list {
    list-style-type: none;
    padding-left: 0;
    margin-top: rem(25);
    margin-bottom: rem(25);
  }

  .cmp-list__item {
    padding-top: rem(3);
    padding-bottom: rem(3);
  }

  .cmp-list__item-link {
    color: $list-text-text-color;
    font-size: $font-size-2;
    line-height: $font-height-2;
    font-weight: $font-weight-semi-bold;
    text-decoration: none;

    &:hover {
      color: $list-text-text-color;
      text-decoration: underline;
    }

    @include until($breakpoint-small) {
      font-size: $font-size-2-mobile;
      line-height: $font-height-2-mobile;
    }
  }

  .cmp-list__item-title {

  }

  .cmp-list__item-date {

  }
}

.list--block {

  .cmp-list {
    padding-left: 6%;
    padding-right: 6%;
    list-style-type: none;
    margin-top: rem(155);
    margin-bottom: rem(123);

    @include between($breakpoint-small, $breakpoint-medium) {
      margin-top: rem(110);
      margin-bottom: rem(80);
    }

    @include until($breakpoint-small) {
      margin-top: rem(70);
      margin-bottom: rem(60);
    }
  }

  .cmp-list__item {
    margin-bottom: rem(75);

    @include between($breakpoint-small, $breakpoint-medium) {
      margin-bottom: rem(65);
    }

    @include until($breakpoint-small) {
      margin-bottom: rem(55);
    }

    .cmp-teaser {
      display: flex;
      flex-direction: row-reverse;
      border-top: 1px solid $list-block-line-color;

      @include until($breakpoint-small) {
        flex-wrap: wrap;
      }
    }

    .cmp-teaser__link {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      color: $list-block-text-color;
      text-decoration: none;
      position: relative;

      @include until($breakpoint-small) {
        flex-wrap: wrap;
      }

      &::before {
        content: '';
        position: absolute;
        top: rem(15);
        right: 0;
        width: rem(48);
        height: rem(48);
        border-radius: 50%;
        background-color: $list-block-icon-background;

        @include until($breakpoint-small) {
          top: rem(10);
          right: 0;
          width: rem(38);
          height: rem(38);
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: rem(16);
        right: 0;
        width: rem(48);
        height: rem(48);
        background-color: $list-block-icon-color;
        mask-repeat: no-repeat;
        mask-size: 100%;
        mask-position: center;
        @extend .icon-arrow-diagonal;
        transform: rotate(45deg);

        @include until($breakpoint-small) {
          top: rem(10);
          right: 0;
          width: rem(38);
          height: rem(38);
        }
      }
    }

    .cmp-teaser__image {
      width: 32.91%;
      padding-right: rem(14);

      @include between($breakpoint-small, $breakpoint-medium) {
        width: 40%;
        padding-right: rem(10);
      }

      @include until($breakpoint-small) {
        width: 100%;
        padding-right: 0;
      }
    }

    .cmp-image {
      margin-top: rem(17);
      padding-bottom: 69.53%;
      position: relative;
      width: 100%;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include until($breakpoint-small) {
        margin-top: rem(10);
      }
    }

    .cmp-teaser__content {
      width: 67.09%;
      padding-left: rem(14);
      padding-bottom: rem(35);
      position: relative;

      @include between($breakpoint-small, $breakpoint-medium) {
        width: 60%;
        padding-left: rem(10);
      }

      @include until($breakpoint-small) {
        width: 100%;
        padding-left: 0;
        padding-bottom: 0;
        margin-bottom: rem(20);
      }
    }

    .cmp-teaser__title {
      color: $list-block-title-color;
      font-size: $font-title-size-4;
      line-height: $font-title-height-4;
      font-weight: $list-block-title-weight;
      margin-bottom: rem(19);
      margin-top: rem(8);
      width: 48.32%;

      @if $list-block-title-uppercase {
        text-transform: uppercase;
      }

      @include between($breakpoint-small, $breakpoint-medium) {
        width: 66%;
      }

      @include until($breakpoint-small) {
        font-size: $font-title-size-4-mobile;
        line-height: $font-title-height-4-mobile;
        margin-bottom: rem(12);
        margin-top: rem(12);
        width: 100%;
        padding-right: rem(45);
      }
    }

    .cmp-teaser__description {
      color: $list-block-text-color;
      font-size: $font-size-2;
      line-height: $font-height-2;
      font-weight: $font-weight-regular;
      margin-bottom: rem(19);
      width: 48.32%;

      @include between($breakpoint-small, $breakpoint-medium) {
        width: 66%;
      }

      @include until($breakpoint-small) {
        font-size: $font-size-2-mobile;
        line-height: $font-height-2-mobile;
        width: 100%;
      }
    }

    .cmp-teaser__action-container {
      position: absolute;
      left: rem(14);
      bottom: 0;

      @include until($breakpoint-small) {
        left: 0;
        position: relative;
      }
    }

    .cmp-teaser__action-link {
      color: $list-block-link-color;
      font-size: $font-size-3;
      line-height: $font-height-3;
      font-weight: $font-weight-semi-bold;
      text-decoration: none;
      display: inline-block;
      margin-right: rem(14);

      &:hover {
        color: $list-block-link-color;
        text-decoration: underline;
      }

      @include until($breakpoint-small) {
        font-size: $font-size-3-mobile;
        line-height: $font-height-3-mobile;
        margin-right: rem(10);
      }
    }

    &:last-child {

      .cmp-teaser {
        border-bottom: 1px solid $list-block-line-color;
        padding-bottom: rem(75);

        @include between($breakpoint-small, $breakpoint-medium) {
          padding-bottom: rem(65);
        }

        @include until($breakpoint-small) {
          padding-bottom: rem(55);
        }
      }
    }
  }

  .cmp-list__item-link {

  }

  .cmp-list__item-title {

  }

  .cmp-list__item-date {

  }
}
