.v-tooltip {
  position: absolute;
  background-color: $tooltip-background;
  color: $tooltip-font-color;
  border-radius: 2rem;
  font-size: $font-date;
  line-height: $font-date-height;
  padding: rem(2) rem(16);
  z-index: 1000;
  transform: translate(-50%, -100%);
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - #{rem(4)});
    width: 0;
    height: 0;
    border-style: solid;
    border-width: rem(4) rem(4) 0 rem(4);
    border-color: $tooltip-background transparent transparent transparent;
  }
}
