
//== Font loading

@mixin fontface($name, $file, $weight: normal, $style: normal) {
    @font-face {
        font-weight: $weight;
        font-family: '#{$name}';
        font-style: $style;
        src: url('../default/resources/fonts/#{$file}.woff2') format('truetype');
        font-display: swap;
    }
}

@include fontface(defaultFont, 'Assistant/Assistant200', 200);
@include fontface(defaultFont, 'Assistant/Assistant300', 300);
@include fontface(defaultFont, 'Assistant/Assistant400', 400);
@include fontface(defaultFont, 'Assistant/Assistant500', 500);
@include fontface(defaultFont, 'Assistant/Assistant600', 600);
@include fontface(defaultFont, 'Assistant/Assistant700', 700);
@include fontface(defaultFont, 'Assistant/Assistant800', 800);
@include fontface(titleFont, 'Assistant/Assistant200', 200);
@include fontface(titleFont, 'Assistant/Assistant300', 300);
@include fontface(titleFont, 'Assistant/Assistant400', 400);
@include fontface(titleFont, 'Assistant/Assistant500', 500);
@include fontface(titleFont, 'Assistant/Assistant600', 600);
@include fontface(titleFont, 'Assistant/Assistant700', 700);
@include fontface(titleFont, 'Assistant/Assistant800', 800);
