.cmp-social-sharing {
  position: relative;

  &.cmp-social-sharing--is-horizontal {
    display: flex;

    @include until($breakpoint-small) {
      padding-right: 0;
    }
  }

  @include until($breakpoint-small) {
    padding-right: rem(16);
  }
}

.cmp-social-sharing__button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: rem(2) 0 0 rem(35);
  min-height: rem(24);
  font-weight: $font-weight-regular;
  font-size: $font-size-3;
  line-height: $font-height-3;
  color: $black;
  position: relative;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &::before {
    content: '';
    width: rem(24);
    height: rem(24);
    position: absolute;
    left: 0;
    top: 0;
    @extend .icon-share;
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: $black;
  }

  &:hover {
    opacity: 0.7;
  }
}

.cmp-social-sharing__overlay {
  padding: 0 rem(23) 0 rem(40);
  background: $white;
  min-width: rem(253);
  z-index: 1;
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  transition-property: max-height, box-shadow, padding, opacity;

  @include until($breakpoint-small) {
    left: auto;
    right: 0;
    padding: 0 rem(18) 0 rem(26);
    min-width: rem(190);
  }
}

.cmp-social-sharing__overlay--active {
  padding: rem(23) rem(23) rem(21) rem(40);
  max-height: rem(400);
  box-shadow: 0 0 rem(64) rem(-15) rgba(0, 0, 0, 0.25);
  opacity: 1;

  @include until($breakpoint-small) {
    padding: rem(18);
  }
}

.cmp-social-sharing__title {
  font-weight: $font-weight-bold;
  font-size: $font-size-3;
  line-height: $font-height-3;
  margin-bottom: rem(33);
  padding-left: rem(7);

  @include until($breakpoint-small) {
    margin-bottom: rem(20);
    padding-left: rem(5);
  }
}

.cmp-social-sharing__list {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  .cmp-social-sharing--is-horizontal & {
    display: flex;
    gap: rem(32);

    @include until($breakpoint-small) {
      gap: rem(16);
    }
  }
}

.cmp-social-sharing__item {
  display: flex;
  list-style-type: none;
}

.cmp-social-sharing__link {
  display: flex;
  align-items: center;
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-2;
  line-height: $font-height-2;
  color: $black;
  text-decoration: none;
  transition: opacity 0.15s ease-in-out;

  &:hover {
    opacity: 0.7;
    color: $black;
  }
}

.cmp-social-sharing__icon {
  width: rem(40);
  height: rem(40);
  margin-right: rem(27);
  display: flex;
  align-items: center;
  justify-content: center;

  .cmp-social-sharing--is-horizontal & {
    margin-right: 0;

    @include until($breakpoint-small) {
      width: rem(32);
      height: rem(32);
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @include until($breakpoint-small) {
    margin-right: rem(20);
  }
}
