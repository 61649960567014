.cmp-text {
  margin: 0;
  color: $color-text;
  font-weight: $font-weight-regular;
  font-size: $font-size-1;
  line-height: $font-height-1;
  padding: 0 $content-offset-mobile;

  @include until($breakpoint-small) {
    font-size: $font-size-1-mobile;
    line-height: $font-height-1-mobile;
  }

  @include from($breakpoint-small) {
    padding: 0 $content-offset;
  }

  p, blockquote {
    @include grid-item;
    @include grid-item--12;

    @include from($breakpoint-small) {
      @include grid-item--10;
    }

    @include from($breakpoint-large) {
      @include grid-item--8;
    }
  }

  ul, ol {
    @include grid-item;
    @include grid-item--12;
    margin-block: 1.5em;

    @include from($breakpoint-small) {
      @include grid-item--8;
    }

    @include from($breakpoint-large) {
      @include grid-item--6;
    }
  }

  & ul li, & ol li {
    position: relative;
    list-style-type: none;
    margin-bottom: rem(22);

    &:before {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  & ul li {
    padding-left: rem(24);

    @include from($breakpoint-small) {
      padding-left: rem(28);
    }

    &:before {
      content: '•';
    }
  }

  & ol {
    counter-reset: count;

    li {
      padding-left: rem(38);
      counter-increment: count;

      @include from($breakpoint-small) {
        padding-left: rem(44);
      }

      &:before {
        content: counter(count);
      }

      &:nth-child(-n+9):before {
        content: '0'counter(count);
      }
    }
  }

  & b, & strong {
    font-weight: $font-weight-bold;
  }

  & i, & em {
    font-style: normal;
  }

  & p a {
    color: $link-color;
    text-decoration: $link-decoration;

    &:hover {
      color: $link-color-hover;
      text-decoration: $link-decoration-hover;
      text-decoration-color: $link-color-hover;
      text-decoration-style: solid;
    }

    &:focus {
      text-decoration: $link-decoration;
      outline: 1px dashed $link-color;
      text-decoration-color: $link-color;
      text-decoration-style: solid;
    }
  }

  & blockquote {
    margin: $gap 0;
    font-size: $font-title-size-5;
    line-height: $font-title-height-5;
    font-family: titleFont, serif;
    margin-inline: 0;
  }

  table, thead, tbody, tfoot, th, td, tr {
    border: none;
  }

  table {
    margin: 1.4444em $grid-half-gap;
    max-width: calc(100% + #{$grid-half-gap * 2});
    border: none;
    border-collapse: collapse;

    @include until($breakpoint-small) {
      margin: 1.5em $grid-half-gap;
    }
  }

  th, td {
    border-right: rem(28) solid transparent;

    &:last-child {
      border-right: none;
    }

    @include until($breakpoint-small) {
      border-right: rem(14) solid transparent;
    }
  }

  th {
    border-bottom: 1px solid $table-th-border-color;
    text-align: left;
    font-weight: $font-weight-bold;
    padding: rem(50) 0 rem(50);

    @include until($breakpoint-small) {
      padding: rem(25) 0 rem(25);
    }
  }

  td {
    padding: rem(14) 0;
    border-bottom: 1px solid $table-border-color;

    @include until($breakpoint-small) {
      padding: rem(8) 0;
    }
  }

  .text--bullet-list-sm & {

    & ul li {
      font-size: $font-size-2;
      line-height: $font-height-2;

      @include until($breakpoint-small) {
        font-size: $font-size-3;
        line-height: $font-height-3;
      }
    }
  }

  .text--check-list & {

    & ul li {
      padding-left: rem(57);
      margin-bottom: rem(26);

      &:before {
        content: '';
        width: rem(33);
        height: rem(33);
        @extend .icon-checkmark;
        background-color: $color-text;
        mask-size: 100%;
        mask-repeat: no-repeat;
        mask-position: center;
        top: rem(-1);

        @include until($breakpoint-small) {
          top: rem(-3);
        }
      }
    }
  }
}

.cmp-text__paragraph {
}

// editation styles
.section.text, .text.is-edited {

  > p, blockquote, ul, ol, table {
    margin-left: #{$grid-half-gap + $content-offset-mobile};
    margin-right: #{$grid-half-gap + $content-offset-mobile};
    max-width: calc(100% - #{$grid-half-gap * 2 + $content-offset-mobile * 2});

    @include from($breakpoint-small) {
      margin-left: #{$grid-half-gap + $content-offset};
      margin-right: #{$grid-half-gap + $content-offset};
      max-width: calc(100% - #{$grid-half-gap * 2 + $content-offset * 2});
    }
  }
}

.cmp-text__table-wrapper {
    overflow-x: auto;

  td, th {
    min-width: 140px;
  }
}
