//== Defaults

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  color: $color-text;
  font-size: 100%;
  font-family: defaultFont, serif;
  line-height: $base-font-height;
  background: $color-background;
  scroll-behavior: smooth;
}

[hidden] {
  display: none !important;
}

a {
  color: $link-color;
  text-decoration: $link-decoration;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: $link-color-hover;
    text-decoration: $link-decoration-hover;
  }
}

p {
  font-size: $font-size-1;
  line-height: $font-height-1;
  margin-block: 1.4444em;

  @include until($breakpoint-small) {
    font-size: $font-size-1-mobile;
    line-height: $font-height-1-mobile;
    margin-block: 1.5em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@media (max-width: 768px) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}

//== Containers
.container-limited {

  .cmp-container {
    max-width: $container-limited-width;
    margin: 0 auto;
  }
}