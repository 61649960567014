.back-to-top-button {
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: $header-main-mobile-menu-icon-color;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  z-index: 50;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  transition: all 500ms ease;

  @include until($breakpoint-small) {
    width: 2.5rem;
    height: 2.5rem;
    right: calc(50% - 1rem);
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0px);
  }

  &::after {
    content: '';
    @extend .icon-arrow-down-cool;
    transform: rotate(180deg);
    width: 2rem;
    height: 2rem;
    background-color: $header-main-mobile-menu-icon-shape-color;
    background-size: 100%;
    mask-size: 100%;

    @include until($breakpoint-small) {
      width: 1.25rem;
      height: 1.25rem;
      min-width: 1.25rem;
      min-height: 1.25rem;
    }
  }
}

@include from($breakpoint-small) {
  .back-to-top-button:hover {
    background-color: darken($color: $header-main-mobile-menu-icon-color, $amount: 10);
  }
}