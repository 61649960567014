.#{$select-ns}-wrapper.plugin-drag_drop {
	.ts-dragging{
		color:transparent !important;
	}
		
	.ts-dragging > * {
		visibility:hidden !important;
	}

}
