.ad {
  border-bottom: 2px solid #00000033;
  width: 88.2%;
  margin: 0 auto;
  position: relative;
  padding-top: 60px;
  margin-bottom: 60px;

  @include until($breakpoint-small) {
    width: 90.2%;
    border-bottom: 1px solid #00000033;
    padding-top: 30px;
    margin-bottom: 30px;
  }
  
  &::before {
    content: "Anzeige";
    display: block;
    margin: 0 auto;
    text-align: center;
    border-bottom: 2px solid #00000033;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: black;
    padding-bottom: 3px;
    @include until($breakpoint-small) {
      border-bottom: 1px solid #00000033;
      font-size: 10px;
      padding-bottom: 0px;
    }
  }

  &::after {
    content: "";
    border-top: 2px solid #2CB8C7;
    width: 56px;
    position: absolute;
    left: calc(50% - 28px);
    bottom: calc(100% - 82px);
    @include until($breakpoint-small) {
      bottom: calc(100% - 49px);
    }
  }

  >div {
    // width: 88%;
    // margin: 0 6%;
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
    }

    a {
      display: flex;
    }

    // @include until($breakpoint-small) {
      // width: 90%;
      // margin: 0 5%;
    // }
  }

  .ad-leaderboard {
    max-width: rem(970);
    margin: rem(40) auto;

    @include until($breakpoint-small) {
      max-width: rem(600);
      margin: rem(20) auto;
    }
  }

  .ad-billboard {
    max-width: rem(970);
    margin: rem(40) auto;

    @include until($breakpoint-small) {
      max-width: rem(600);
      margin: rem(20) auto;
    }
  }

  .ad-medium-rectangle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(40);
    margin-bottom: rem(40);

    >div {
      flex: 1 1 0;
      max-width: rem(300);
      margin-left: 1.036%;
      margin-right: 1.036%;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    @include until($breakpoint-small) {
      flex-direction: column;
      margin-top: rem(20);
      margin-bottom: rem(20);

      >div {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: rem(20);
        display: none;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .ad-interstitial {
    max-width: rem(1400);

    @include until($breakpoint-small) {
      max-width: rem(1080);
    }
  }
}