.cmp-header__generic-nav {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 13;

  @include until($breakpoint-medium) {
    position: static;
  }
}

.cmp-header__generic-nav-list-wrapper,
.cmp-header__generic-nav-search-wrapper {
  position: relative;

  &::before {
    content: '';
    background-color: $header-generic-background;
    position: absolute;
    width: calc(100% - #{rem(104)});
    height: 100%;
    left: rem(52);
    top: 0;
  }

  @include until($breakpoint-medium) {
    display: none;
  }
}

.cmp-header__generic-nav-list-wrapper {
  @include until($breakpoint-medium) {
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 12;
    background-color: $header-background;
    padding: 0 5% 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    transition-property: max-height, padding;

    &::before {
      content: '';
      position: absolute;
      top: rem(16);
      left: 0;
      width: 0;
      height: 1px;
      background-color: $header-main-border-color;
      transition: width 0.8s cubic-bezier(0.33, 1, 0.68, 1);
      z-index: 10;
    }
  }

  @include from($breakpoint-medium) {
    max-height: 3.5rem;
    overflow: hidden;
  }
}

.cmp-header__generic-nav-list-wrapper--active {
  padding: rem(35) 5% rem(70);
  max-height: rem(800);

  @include until($breakpoint-medium) {

    &::before {
      width: 91.87%;
    }
  }

  @include until(500px) {

    &::before {
      width: 88%;
    }
  }
}

.cmp-header__generic-nav-list-wrapper .navigation-generic,
.cmp-header__generic-nav-search {
  position: relative;
  padding-left: rem(36);
  padding-right: rem(36);
  height: rem(56);
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: rem(52);
    height: rem(56);
    background-color: $header-generic-background;
    mask-repeat: no-repeat;
    mask-size: cover;
    @extend .cmp-header__background-shape-left;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: rem(52);
    height: rem(56);
    background-color: $header-generic-background;
    mask-repeat: no-repeat;
    mask-size: cover;
    @extend .cmp-header__background-shape-right;
  }
}

.cmp-header__generic-nav-search-mobile {
  display: none;

    @include until($breakpoint-medium) {
      display: block;
      margin-top: 4px;
    }

  a {
    color: $header-main-active-color !important;

    &:visited {
      color: $header-main-active-color !important;
    }
  }
}

.cmp-header__generic-nav-list-wrapper .navigation-generic {

  .cmp-navigation__group {
    padding-left: 0;
    display: flex;
    align-items: center;

    @include until($breakpoint-medium) {
      margin: 0;
      flex-direction: column;
    }
  }

  @include until($breakpoint-medium) {
    flex-direction: column;
    height: auto;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }

}

.item-link-submenu {
  position: relative;

  &::after {
    @extend .icon-arrow-navi;

    @include until($breakpoint-medium) {
      content: '';
      position: absolute;
      background-color: $header-main-font-color;
      mask-repeat: no-repeat;
      mask-size: cover;
      transform: rotate(-90deg);
      width: rem(24);
      height: rem(24);
      top: rem(13);
      right: rem(21);
    }
  }
}

.navigation-generic.navigation-generic__main {
  background-color: $haze-grey;

  .cmp-navigation__group {
    list-style-type: none;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .cmp-navigation__item {
    // display: flex;

    a {
      display: block;
      text-align: left;
      width: 100%;
      padding: 13px 5%;
      color: $soot-grey;
      text-decoration: none;
    }

    &.cmp-navigation__item--active {
      a {
        color: $deep-sea-baby;

        position: relative;

        &::after {
          @extend .icon-checkmark;

          @include until($breakpoint-large) {
            content: '';
            position: absolute;
            background-color: $header-main-font-color;
            mask-repeat: no-repeat;
            mask-size: cover;
            width: rem(24);
            height: rem(24);
            top: rem(13);
            right: rem(21);
          }
        }
      }
    }
  }

  .cmp-navigation__item:not(:last-of-type) {
    border-bottom: 1px solid $pebble-grey;
  }

  @include from($breakpoint-medium) {
    display: none;
  }

}

.cmp-header__generic-nav-list-wrapper .cmp-navigation__item {
  list-style-type: none;
  position: relative;
  z-index: 1;
}

.cmp-header__generic-nav-list-wrapper .cmp-navigation__item-link {
  display: inline-block;
  padding: rem(12) rem(16);
  font-weight: $font-weight-semi-bold;
  font-size: 0.9rem;
  line-height: $font-height-2;
  color: $header-generic-secondary-font-color;
  text-decoration: none;
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    bottom: rem(11);
    left: rem(16);
    width: 0;
    height: 1px;
    background-color: $header-main-active-color;
    transition: width 0.4s cubic-bezier(0.33, 1, 0.68, 1);

    @include until($breakpoint-medium) {
      bottom: rem(6);
      left: rem(12);
    }
  }

  &:hover,
  &:focus {
    color: $header-generic-font-color;
    text-decoration: none;

    &::before {
      width: calc(100% - #{rem(32)});

      @include until($breakpoint-medium) {
        width: calc(100% - #{rem(24)});
      }
    }
  }

  @include until($breakpoint-medium) {
    padding: rem(16) rem(23) rem(16);
    font-size: $font-title-size-8-mobile;
    line-height: $font-title-height-8-mobile;
  }
}

.cmp-header__generic-nav-list-wrapper .cmp-navigation__item--active .cmp-navigation__item-link {
  color: $header-generic-font-color;
  text-decoration: none;

  &::before {
    width: calc(100% - #{rem(32)});

    @include until($breakpoint-medium) {
      width: calc(100% - #{rem(24)});
    }
  }
}

.cmp-header__generic-nav-more {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  padding: rem(16) 0 rem(10) rem(9);

  .z-window {
    background-color: transparent;
  }

  .z-window-content {
    @extend .icon-login;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100%;
    background-color: $header-generic-secondary-icon-color;

    img {
      opacity: 0;
    }
  }

  @include until($breakpoint-medium) {
    position: absolute;
    top: rem(30);
    right: calc(5% + #{rem(54)});
    padding: 0;
  }
}

.cmp-header__generic-nav-more-item {
  list-style-type: none;

  @include until($breakpoint-medium) {

    &:nth-child(2) {
      display: none;
    }
  }
}

.cmp-header__generic-nav-more-link {
  width: rem(30);
  height: rem(30);
  overflow: hidden;
  text-indent: 1000%;
  white-space: nowrap;
  border: none;
  background-color: $header-generic-secondary-icon-color;
  mask-size: 100%;
  mask-repeat: no-repeat;
  cursor: pointer;
  transition: background-color 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  position: relative;
  z-index: 1;

  &:hover,
  &:focus,
  &.cmp-header__generic-nav-more-link--active {
    background-color: $header-generic-secondary-icon-active-color;
  }

  @include until($breakpoint-medium) {
    width: rem(36);
    height: rem(36);
    mask-size: rem(24) rem(24);
    mask-position: center;
  }
}

.cmp-header__generic-nav-more-link--search {
  @extend .icon-lupe;
  margin-right: rem(19);
  transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  &.cmp-header__generic-nav-more-link--active {
    @extend .icon-close-x;

    &::after {
      content: '';
      width: rem(24);
      height: rem(24);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $header-main-mobile-menu-icon-shape-color;
      mask-repeat: no-repeat;
      mask-size: cover;
      transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);
      @extend .icon-close-x;
      opacity: 0;
    }

    @include until($breakpoint-medium) {
      position: absolute;
      right: -3.375rem;
      top: rem(-2);
      z-index: 14;
      width: rem(40);
      height: rem(40);
      border: none;
      background-color: $header-main-mobile-menu-icon-color;
      border-radius: 50%;
      padding: 0;
      overflow: hidden;
      cursor: pointer;
      mask: none;

      &::after {
        opacity: 1;
      }
    }
  }

  .cmp-header__logged-in--active & {
    pointer-events: none;
    opacity: 0;
  }

  @include until($breakpoint-medium) {
    margin-right: 0;
  }
}

.cmp-header__generic-nav-more-link--user {
  @extend .icon-login;

  &.cmp-header__generic-nav-more-link--active {
    background-color: $header-generic-secondary-icon-color;
    @extend .icon-close-x;

    &:hover {
      background-color: $header-generic-secondary-icon-active-color;
    }
  }

  #header-logged-in & {
    background-color: $header-generic-secondary-icon-active-color;

    &:hover {
        background-color: $header-generic-secondary-icon-color;
    }
  }
}

.cmp-header__generic-nav-auth-overlay {
  position: absolute;
  left: rem(52);
  top: 0;
  width: calc(100% - #{rem(104)});
  height: 100%;
  background-color: $header-generic-background;
  z-index: 1;
  font-size: $font-size-2;
  line-height: $font-height-2;
  display: flex;
  align-items: center;
  font-weight: $font-weight-bold;
  overflow: hidden;
  transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  opacity: 0;
  pointer-events: none;

  @include until($breakpoint-medium) {
    width: calc(100% - #{rem(88)});
    left: rem(44);
    font-size: $font-size-1-b-mobile;
    line-height: $font-height-1-b-mobile;
  }
}

.cmp-header__generic-nav-auth-overlay--active {
  opacity: 1;
  pointer-events: auto;
}

.cmp-header__generic-nav-search-wrapper {
  position: absolute;
  top: 0;
  right: rem(87);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  .cmp-header__logged-in--active &, .cmp-header__logged-in-first--active & {
    pointer-events: none;
    opacity: 0;
  }

  &::before {
    background-color: $header-search-overlay-background;
  }
}

.cmp-header__generic-nav-search-wrapper--active {
  opacity: 1;
  pointer-events: auto;
}

.cmp-header__generic-nav-search {
  padding-right: rem(69);
  padding-left: rem(56);
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  &::before {
    background-color: $header-search-overlay-background;
  }

  &::after {
    background-color: $header-search-overlay-background;
  }

  .cmp-header__generic-nav-search-wrapper--active & {
    max-width: rem(800);
  }

  @include until($breakpoint-medium) {
    padding-right: rem(46);
    padding-left: rem(36);
  }
}

.cmp-header__generic-nav-list-wrapper {
  transition: 200ms;

  &.cmp-header__generic-nav-search-wrapper--active {
    opacity: 0;
    pointer-events: none;
  }
}

.cmp-header__generic-nav-search-mobile {
  @include from($breakpoint-medium) {
    display: none;
  }

  &>div {
    font-size: .75rem;
    font-weight: 500;
    margin-top: -.625rem;
  }

  &>ul {
    list-style-type: none;
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;

    li {
      display: flex;
      align-items: center;
      gap: 8px;

      a {
        text-decoration: none;
        font-size: 18px;
      }

      &::before {
        content: '';
        @extend .icon-arrow-diagonal-round;
        width: 24px;
        height: 24px;
        background-color: black;
        display: inline-block;
      }
    }
  }
}

.cmp-header__search-suggestion-wrapper.cmp-header__search-suggestion-wrapper--active+.cmp-header__generic-nav-search-mobile {
  display: none;
}

.cmp-header__generic-nav-search-label {
  display: inline-block;
  margin-right: rem(24);
  font-size: $font-date;
  line-height: $font-date-height;
  color: $header-search-overlay-color;
  white-space: nowrap;
  position: relative;
  z-index: 1;
  letter-spacing: 1px;

  @include until($breakpoint-medium) {
    margin-right: rem(50);
  }
}

.cmp-header__generic-nav-search-list {
  padding-left: 0;
  display: flex;
  position: relative;
  z-index: 1;
  min-width: 290px;
  justify-content: flex-end;

  li {
    list-style-type: none;
    margin-right: rem(30);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: rem(-2);
      left: 0;
      width: rem(25);
      height: rem(25);
      border: 1px solid $header-search-overlay-color;
      border-radius: 50%;
    }

    a {
      display: inline-block;
      color: $header-search-overlay-color;
      text-decoration: none;
      font-weight: $font-weight-bold;
      font-size: $font-size-2;
      line-height: $font-height-2;
      white-space: nowrap;
      position: relative;
      padding-left: rem(33px);

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: rem(33);
        width: 0;
        height: 1px;
        background-color: $header-search-overlay-color;
        transition: width 0.4s cubic-bezier(0.33, 1, 0.68, 1);
      }

      &::after {
        content: '';
        position: absolute;
        top: rem(-2);
        left: 0;
        width: rem(25);
        height: rem(25);
        background-color: $header-search-overlay-color;
        @extend .icon-arrow-diagonal;
        mask-repeat: no-repeat;
        mask-size: cover;
      }

      &:hover,
      &:focus {
        color: $header-search-overlay-color;
        text-decoration: none;

        &::before {
          width: calc(100% - #{rem(33)});
        }
      }

      @include until($breakpoint-medium) {
        font-size: $font-size-1-b-mobile;
        line-height: $font-height-1-b-mobile;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @include until($breakpoint-medium) {
      margin-right: rem(24);
    }
  }
}

.cmp-header__generic-nav-mobile-btn {
  display: none;
  position: absolute;
  left: calc(50% - #{rem(70)});
  top: 0;
  z-index: 12;
  width: rem(140);
  height: rem(28);
  border: none;
  padding: 0 rem(23);
  background-color: transparent;
  color: $header-generic-font-color;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: rem(26);
    height: rem(28);
    background-color: $header-generic-background;
    mask-repeat: no-repeat;
    mask-size: cover;
    @extend .cmp-header__background-shape-left;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: rem(26);
    height: rem(28);
    background-color: $header-generic-background;
    mask-repeat: no-repeat;
    mask-size: cover;
    @extend .cmp-header__background-shape-right;
  }

  >span {
    display: flex;
    position: relative;
    z-index: 1;
    background-color: $header-generic-background;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    font-weight: $font-weight-bold;
    font-size: $font-size-4-mobile;
    line-height: $font-height-4-mobile;
    color: $header-generic-font-color;
  }

  // @include until($breakpoint-medium) {
  //   display: block;
  // }
}

.cmp-header__generic-nav-mobile-icon {
  display: inline-block;
  width: rem(15);
  height: rem(15);
  margin-left: rem(2);
  background-color: $header-generic-secondary-icon-color;
  mask-repeat: no-repeat;
  mask-size: cover;
  @extend .icon-arrow-navi;
  position: relative;
  top: rem(-2);

  .cmp-header__generic-nav-mobile-btn--active & {
    @extend .icon-arrow-navi-hi;
    background-color: $header-generic-secondary-icon-active-color;
  }
}

.cmp-header__generic-nav-logged-in-menu-wrapper {

  &::after {
    @extend .cmp-header__background-shape-left;
  }

  @include from($breakpoint-medium) {
    position: absolute;
    right: rem(-26);
    top: 0;
    padding-top: rem(62);
    overflow: hidden;
    border-radius: 0 0 rem(8) rem(8);
    pointer-events: none;

    &::before {
      content: '';
      background-color: $header-logged-in-background;
      position: absolute;
      width: 100%;
      height: 0;
      right: 0;
      top: 0;
      transition: width 0.5s cubic-bezier(0.33, 1, 0.68, 1);
      transition-property: height;
    }

    &::after {
      content: '';
      background-color: $white;
      position: absolute;
      width: rem(9);
      height: rem(56);
      left: 0;
      top: rem(56);
      z-index: 1;
      mask-repeat: no-repeat;
      mask-size: cover;
      transform: scaleX(-1);
      opacity: 0;
    }

    &.cmp-header__generic-nav-logged-in-menu-wrapper--active {
      pointer-events: auto;

      &::before {
        height: 100%;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  .header-hidden &.cmp-header__generic-nav-logged-in-menu-wrapper--active {

    @include from($breakpoint-medium) {
      pointer-events: none;
      opacity: 0;
    }
  }
}

.cmp-header__generic-nav-logged-in-menu {
  position: relative;
  z-index: 1;
  transition: opacity 0.7s cubic-bezier(0.33, 1, 0.68, 1);

  @include from($breakpoint-medium) {
    opacity: 0;
  }

  .cmp-header__generic-nav-logged-in-menu-wrapper--active & {
    opacity: 1;
  }
}

.cmp-header__generic-nav-logged-in-menu-list {
  padding: rem(31) rem(26);
  min-width: rem(288);

  @include until($breakpoint-medium) {
    padding: rem(20) 0 rem(26);
  }
}

.cmp-header__generic-nav-logged-in-menu-item {
  list-style-type: none;

  a {
    display: block;
    position: relative;
    color: $header-logged-in-text-color;
    font-size: $font-size-2;
    font-weight: $font-weight-semi-bold;
    padding: rem(12) 0 rem(10);
    text-decoration: none;

    @include until($breakpoint-medium) {
      padding: rem(14) 5%;
    }

    @include from($breakpoint-medium) {

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $header-logged-in-line-color;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $header-logged-in-active-line-color;
        transition: width 0.4s cubic-bezier(0.33, 1, 0.68, 1);
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;

      &::after {
        width: 100%;
      }
    }
  }

  &.favorites {

  }

  &.logout {
    position: relative;

    a {
      padding-right: rem(24);
    }

    &::after {
      content: '';
      @extend .icon-logout;
      position: absolute;
      right: rem(-4);
      top: 50%;
      transform: translateY(-50%);
      width: rem(24);
      height: rem(24);
      background-color: $header-logged-in-icon-color;
      pointer-events: none;

      @include until($breakpoint-medium) {
        right: calc(5% - #{rem(4)});
      }
    }
  }
}

.cmp-header__generic-nav-user-menu-overlay {
  position: absolute;
  padding-left: rem(52);
  padding-right: rem(52);
  height: rem(56);
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 1;
  width: 0;
  opacity: 0;
  transition: width 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  transition-property: width, opacity;
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: rem(52);
    height: rem(56);
    background-color: $header-logged-in-overlay-background;
    mask-repeat: no-repeat;
    mask-size: cover;
    @extend .cmp-header__background-shape-left;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: rem(52);
    height: rem(56);
    background-color: $header-logged-in-overlay-background;
    mask-repeat: no-repeat;
    mask-size: cover;
    @extend .cmp-header__background-shape-right;
  }

  .cmp-header__logged-in--active &, .cmp-header__logged-in-first--active & {
    width: 100%;
    opacity: 1;
    pointer-events: auto;
  }
}

.cmp-header__generic-nav-user-menu-overlay-inner {
  width: 100%;
  height: 100%;
  color: $header-logged-in-overlay-text-color;
  background-color: $header-logged-in-overlay-background;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: rem(70);

  &::after {
    content: '';
    display: block;
    width: rem(30);
    height: rem(30);
    background-color: $header-logged-in-overlay-text-color;
    mask-repeat: no-repeat;
    mask-size: cover;
    @extend .icon-login;
  }

  .cmp-header__logged-in-first--active & {

    &::after {
      display: none;
    }
  }
}
