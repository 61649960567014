.experiencefragment.footer {
  .cmp-text {
    & p a {
      color: $footer-link-color;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .cmp-experiencefragment--footer {}

  .footer--top {
    background-color: $footer-background-color;
    color: $footer-text-color;

    >.cmp-container {
      padding-left: 6%;
      padding-right: 6%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: rem(97);
      margin-bottom: 0 !important;

      >* {
        width: 48.9%;
        margin-bottom: rem(48);

        @include until($breakpoint-small) {
          width: 100%;
        }
      }

      // Logo
      >.image:first-child {
        margin-bottom: rem(100);

        .cmp-image {
          margin-top: 0;
        }

        img {
          max-width: rem(44);
          height: auto;

          @include until($breakpoint-small) {
            max-width: rem(32);
          }
        }

        @include until($breakpoint-small) {
          margin-bottom: rem(38);
        }
      }

      // Text
      >.text:nth-child(2) {
        margin-bottom: rem(100);

        .cmp-text {
          padding-left: 0;
          padding-right: 0;
        }

        p {
          padding-left: 0;
          padding-right: 0;
          width: 84.24%;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          @include until($breakpoint-small) {
            width: 74.645%;
          }
        }

        &,
        p {
          font-size: $font-title-size-6;
          line-height: $font-title-height-6;
          font-weight: $font-weight-regular;
          color: $footer-text-color;
        }

        @include until($breakpoint-small) {
          margin-bottom: rem(38);
        }
      }

      // Subscribe
      >.text:nth-child(3) {
        margin-bottom: rem(48);

        .cmp-text {
          padding-left: 0;
          padding-right: 0;

          &,
          p {
            font-size: $font-flagtitle-size;
            line-height: $font-flagtitle-height;
            font-weight: $font-weight-semi-bold;
            color: $footer-text-color;
          }

          a {
            color: $footer-text-color;
          }

          p {
            padding-left: 0;
            padding-right: 0;

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        @include until($breakpoint-small) {
          margin-bottom: rem(38);
        }
      }

      // Links
      >.container:nth-child(4) {
        margin-bottom: rem(48);

        .cmp-container {
          background-color: transparent;
          padding: 0;

          @include until($breakpoint-small) {
            grid-template-columns: repeat(2, 48.925%);
          }
        }

        .text {
          &:first-child {
            @include until($breakpoint-medium) {
              grid-column: 1 / -1;
            }
          }
        }

        .cmp-text {
          padding-left: 0;
          padding-right: 0;

          div:first-child {
            font-size: $font-flagtitle-size;
            line-height: $font-flagtitle-height;
            color: $footer-text-color;
            margin-bottom: rem(16);

            strong {
              font-weight: $font-weight-semi-bold;
            }
          }

          ul {
            margin: 0;
            width: 100%;
          }

          li {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: rem(3);
            color: $footer-link-color;
            font-size: $font-size-2;
            line-height: $font-height-2;
            font-weight: $font-weight-semi-bold;
            // opacity: 0.7;

            a {
              color: $footer-link-color;
              text-decoration: none;

              &:hover,
              &:focus {
                text-decoration: underline;
              }
            }

            &::before {
              display: none;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      @include until($breakpoint-small) {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: rem(52);
      }
    }
  }

  .footer--bottom {
    background-color: $footer-sub-background-color;
    color: $footer-sub-text-color;
    padding: rem(17) 0 rem(13);

    .cmp-container {
      display: flex;
      padding-left: 6%;
      padding-right: 6%;
      justify-content: space-between;

      .text {
        width: 48.9%;
        opacity: 0.7;

        .cmp-text,
        .cmp-text p {
          font-size: $font-size-3;
          line-height: $font-height-3;
          font-weight: $font-weight-semi-bold;
          color: $footer-sub-text-color;
          padding: 0;
        }

        .cmp-text {
          margin: 0;
        }

        .cmp-text p {
          width: 100%;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        @include until($breakpoint-small) {
          width: 100%;
          text-align: left;
          margin-bottom: rem(25);
        }
      }

      .list {
        width: 48.9%;
        opacity: 0.7;

        .cmp-list {
          display: flex;
          list-style-type: none;
          gap: rem(49);
          padding: 0;
          margin: 0;

          @media (max-width: 1200px) {
            gap: rem(20);
          }

          @media (max-width: 1000px) {
            flex-wrap: wrap;
          }

          @include until($breakpoint-small) {
            justify-content: center;
            flex-wrap: wrap;
            row-gap: 10px;
            justify-content: flex-start;
            column-gap: 20px;
          }
        }

        @include until($breakpoint-small) {
          .cmp-list__item {
            &:not(:last-child):after {
              content: '/';
              position: relative;
              left: 9px;
            }
          }
        }

        .cmp-list__item-link {
          color: $footer-sub-link-color;
          text-decoration: none;
          font-size: $font-size-3;
          line-height: $font-height-3;
          font-weight: $font-weight-semi-bold;

          &:hover {
            color: $footer-sub-link-color;
            text-decoration: underline;
          }
        }

        @include until($breakpoint-small) {
          width: 100%;
        }
      }

      @include until($breakpoint-small) {
        padding-left: 5%;
        padding-right: 5%;
        flex-wrap: wrap;
      }
    }

    @include until($breakpoint-small) {
      padding: rem(36) 0 rem(76);
    }
  }
}
