.carousel--testimonial {

  .cmp-carousel {
    background-color: $testimonial-carousel-background;
    overflow: hidden;
    position: relative;
    padding: rem(165) 20.85% rem(285);

    @include until($breakpoint-small) {
      padding: rem(35) 5% rem(120);
    }
  }

  .cmp-carousel__content {

  }

  .cmp-carousel__item {
    transition: opacity 0.6s cubic-bezier(0.33, 1, 0.68, 1);
    opacity: 0.5;

    &.swiper-slide-active {
      opacity: 1;
    }
  }

  .cmp-carousel__prev, .cmp-carousel__next {
    cursor: none;
    position: absolute;
    top: calc(7.65% + #{rem(165)});
    width: rem(74);
    height: rem(74);
    background-color: $testimonial-carousel-button-background;
    border: none;
    z-index: 1;
    transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
    overflow: hidden;
    text-indent: 1000%;
    white-space: nowrap;

    &.swiper-button-disabled {
      opacity: 0;
      pointer-events: none;
    }

    @include until($breakpoint-small) {
      display: none;
    }
  }

  .cmp-carousel__prev {
    left: calc(20.85% + #{rem(14)});
    @extend .icon-carousel-prev;
  }

  .cmp-carousel__next {
    right: calc(20.85% - #{rem(196)});
    @extend .icon-carousel-next;
  }

  .cmp-carousel__pagination {
    position: absolute;
    width: 88%;
    height: rem(74);
    left: 6%;
    bottom: rem(80);
    display: flex;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $testimonial-carousel-pagination-background;
      top: rem(36);
      left: 0;
      z-index: 1;
    }

    @include until($breakpoint-small) {
      height: rem(66);
      bottom: rem(24);
      left: 5%;
      width: 90%;
    }
  }

  .cmp-carousel__pagination-item {
    flex-grow: 1;
    transition: opacity 0.6s cubic-bezier(0.33, 1, 0.68, 1);
    opacity: 0;
    height: 100%;
    position: relative;
    z-index: 2;
    cursor:pointer;

    &.swiper-pagination-bullet-active {
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $testimonial-carousel-pagination-active;
      top: rem(36);
      left: 0;
      z-index: 1;
    }
  }

  .cmp-carousel__pagination-item-actual, .cmp-carousel__pagination-item-total {
    position: absolute;
    width: rem(48);
    height: rem(20);
    text-align: center;
    right: 0;
    font-size: rem(15);
    line-height: rem(20);

    @include until($breakpoint-small) {
      font-size: $font-size-4-mobile;
      line-height: $font-height-4-mobile;
      height: rem(18);
      width: rem(20);
    }
  }

  .cmp-carousel__pagination-item-actual {
    color: $testimonial-carousel-pagination-color;
    top: 0;
  }

  .cmp-carousel__pagination-item-total {
    color: $testimonial-carousel-pagination-grey-color;
    bottom: 0;
  }
}