.cmp-search-results {
  padding: 0 6%;
  margin-top: rem(50);
  margin-bottom: rem(70);

  @include until($breakpoint-small) {
    padding: 0 5% rem(10);
  }
}

.cmp-search-results__search {

}

.cmp-search-results__search-head {
  display: flex;
  padding-left: rem(2);
  border-bottom: 1px solid $search-results-border-color;
  margin-top: 0;
  margin-bottom: rem(10);
  width: 91.4%;

  @include until($breakpoint-large) {
    width: 100%;
    flex-wrap: wrap;
  }
}

.cmp-search-results__search-head-item {
  display: flex;
  align-items: center;
  list-style-type: none;
  font-weight: $font-weight-semi-bold;
  font-size: $font-date;
  line-height: $font-date-height;
  margin-right: rem(67);
  margin-bottom: rem(-5);
  letter-spacing: 1px;

  strong {
    font-weight: $font-weight-light;
    font-size: $font-title-size-4;
    line-height: $font-title-height-4;
    position: relative;
    top: rem(-3);
    margin-left: rem(13);
    letter-spacing: 0;

    @include until($breakpoint-medium) {
      font-size: $font-title-size-5;
      line-height: $font-title-height-5;
      margin-left: rem(8);
    }

    @include until($breakpoint-small) {
      font-size: $font-title-size-4-mobile;
      line-height: $font-title-height-4-mobile;
      margin-left: rem(6);
    }
  }

  @include until($breakpoint-medium) {
    margin-right: rem(25);
    margin-bottom: rem(-2);
    white-space: nowrap;
  }
}

.cmp-search-results__search-field-wrap {
  position: relative;
  padding-bottom: 0;
  padding-left: rem(5);
  width: 91.4%;
  border-bottom: 1px solid $search-results-border-color;
  margin-bottom: rem(12);

  @include until($breakpoint-large) {
    width: 100%;
  }

  @include until($breakpoint-medium) {
    padding-bottom: rem(20);
  }
}

.cmp-search-results__search-field {
  position: relative;

}

.cmp-search-results__search-label {
  display: none;
}

input[type=text].cmp-search-results__search-input, input[type=text].cmp-search-results__search-autocomplete {
  padding: rem(24) rem(130) rem(24) 0;
  border-radius: 0;
  width: 100%;
  max-width: 100%;
  font-size: $font-title-size-2;
  line-height: 1.222;
  color: $search-results-color;
  position: relative;
  left: rem(-3);
  background-color: $search-results-background-color;
  border: none;
  z-index: 1;

  &::placeholder {
    font-weight: $font-weight-light;
    color: $search-results-placeholder-color;
  }

  @include until($breakpoint-medium) {
    padding: rem(18) rem(95) rem(18) 0;
    font-size: $font-title-size-5-mobile;
  }

  @include until($breakpoint-small) {
    padding: rem(18) rem(78) rem(18) 0;
    font-size: $font-title-size-6-mobile;
  }
}

input[type=text].cmp-search-results__search-input--active {
  background-color: transparent;
}

input[type=text].cmp-search-results__search-autocomplete {
  color: $search-results-autocomplete-color;
  z-index: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &:disabled {
    color: $search-results-autocomplete-color;
  }
}

.cmp-search-results__search-button, .cmp-search-results__reset-button {
  position: absolute;
  right: rem(1);
  top: rem(35);
  z-index: 2;
  width: rem(64);
  height: rem(64);
  overflow: hidden;
  text-indent: 1000%;
  white-space: nowrap;
  border: none;
  background-color: $search-results-icon-color;
  mask-size: rem(64) rem(64);
  mask-repeat: no-repeat;
  mask-position: center;
  cursor: pointer;
  transition: background-color 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  transition-property: background-color, top;

  &:hover, &:focus {
    background-color: $search-results-icon-active-color;
  }

  @include until($breakpoint-medium) {
    width: rem(48);
    height: rem(48);
    mask-size: rem(48) rem(48);
    top: rem(15);
  }

  @include until($breakpoint-small) {
    width: 2.25rem;
    height: 2.25rem;
    mask-size: 1.5rem 1.5rem;
    top: rem(13);
  }
}

.cmp-search-results__search-button {
  @extend .icon-lupe;
}

.cmp-search-results__reset-button {
  @extend .icon-close-x;
  background-color: $search-results-icon-reset-color;
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  right: rem(68);

  .cmp-search-results__search-field-wrap--active & {
    opacity: 1;
    pointer-events: auto;
  }

  @include until($breakpoint-medium) {
    right: rem(50);
  }

  @include until($breakpoint-small) {
    right: rem(40);
  }
}

.cmp-search-results__search-suggestion-wrapper {
  position: absolute;
  width: 100%;
  top: calc(100% + 1px);
  left: 0;
  z-index: 20;
  background-color: $search-results-background-color;
}

.cmp-search-results__search-suggestion {
  width: 100%;
  position: relative;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  transition-property: max-height, padding;
  border-bottom: 0 solid $search-results-border-color;
  padding: 0 rem(10);

  @include until($breakpoint-small) {
    transition-property: max-height, padding;
  }
}

.cmp-search-results__search-suggestion--active {
  padding-top: rem(4);
  padding-bottom: rem(10);
  max-height: rem(600);
  border-bottom: 1px solid $search-results-border-color;

  @include until($breakpoint-small) {
    padding-bottom: rem(6);
  }
}

.cmp-search-results__search-suggestion-list {
  padding-left: 0;
  margin-top: rem(10);
  margin-bottom: 0;

  @include until($breakpoint-small) {
    margin-top: rem(6);
  }
}

.cmp-search-results__search-suggestion-item {
  list-style-type: none;
  font-weight: $font-weight-regular;
  font-size: $font-title-size-7;
  line-height: $font-title-height-7;
  color: $search-results-suggestion-color;
  margin-bottom: rem(6);

  @include until($breakpoint-medium) {
    font-size: $font-size-1;
    line-height: $font-height-1;
  }

  @include until($breakpoint-small) {
    font-size: $font-size-3;
    line-height: $font-height-3;
  }
}

.cmp-search-results__search-suggestion-item--active {

  .cmp-search-results__search-suggestion-link {
    text-decoration: underline;
  }
}

.cmp-search-results__search-suggestion-link {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: $font-weight-regular;
  font-size: $font-title-size-7;
  line-height: $font-title-height-7;
  color: $search-results-suggestion-color;

  @include until($breakpoint-medium) {
    font-size: $font-size-1;
    line-height: $font-height-1;
  }

  @include until($breakpoint-small) {
    font-size: $font-size-2;
    line-height: $font-height-2;
  }

  &:hover, &:focus {
    text-decoration: underline;
  }
}

.cmp-search-results__tags-wrapper {
  width: 91.4%;
  padding: 0 0 rem(21);
  display: none; // TODO: hidden until filters are implemented

  @include until($breakpoint-large) {
    width: 100%;
  }
}

.cmp-search-results__tags {
  justify-content: flex-end;

}

.cmp-search-results__list-wrapper {
  position: relative;
  min-height: rem(200);
  width: 91.4%;

  @include until($breakpoint-large) {
    width: 100%;
  }
}

.cmp-search-results__list {
  padding-top: rem(16);
  display: none;
}

.cmp-search-results__list--active {
  display: block;
}

.cmp-search-results__item {
  display: flex;
  border-bottom: 1px solid $search-results-border-color;
  margin-top: rem(20);

  @include until(450px) {
    flex-wrap: wrap;
  }
}

.cmp-search-results__item-go-icon-wrapper {
  width: 8.5%;
  display: flex;
  padding-top: rem(50);

  @include until($breakpoint-medium) {
    width: rem(60);
  }

  @include until($breakpoint-small) {
    display: none;
  }
}

.cmp-search-results__item-go-icon {
  display: block;
  border: 1px solid $search-results-icon-color;
  border-radius: 50%;
  width: rem(33);
  height: rem(33);

  &:hover, &:focus {
    border-color: $search-results-icon-active-color;
  }
}

.cmp-search-results__item-go-icon-image {
  display: block;
  width: rem(32);
  height: rem(32);
  overflow: hidden;
  text-indent: 1000%;
  white-space: nowrap;
  border: none;
  background-color: $search-results-go-icon-color;
  mask-size: rem(26) rem(26);
  mask-repeat: no-repeat;
  mask-position: center;
  cursor: pointer;
  transition: background-color 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  @extend .icon-arrow-right-cool;

  &:hover, &:focus {
    background-color: $search-results-icon-active-color;
  }
}

.cmp-search-results__item-content-wrapper {
  width: 68%;

  @include until($breakpoint-medium) {
    width: calc(100% - #{rem(160)});
  }

  @include until($breakpoint-small) {
    width: calc(100% - #{rem(80)});
  }

  @include until(450px) {
    width: 100%;
  }
}

.cmp-search-results__item-breadcrumb {
  display: flex;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: rem(34);

  @include until($breakpoint-small) {
    margin-bottom: rem(22);
    flex-wrap: wrap;
  }
}

.cmp-search-results__item-breadcrumb-item {
  list-style-type: none;
  font-weight: $font-weight-semi-bold;
  font-size: $font-date;
  line-height: $font-date-height;
  letter-spacing: 1px;
  position: relative;

  a {
    text-decoration: none;
    color: $search-results-breadcrumb-color;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  &:not(:last-child) {
    padding-right: rem(40);
  }

  &:not(:last-child)::after {
    position: absolute;
    right: rem(9);
    top: rem(-3);
    content: '';
    width: rem(24);
    height: rem(24);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100%;
    background-color: $search-results-breadcrumb-separator-color;
    transform: rotate(-90deg);
    @extend .icon-arrow-down;
  }

  @include until($breakpoint-small) {
    white-space: nowrap;
  }
}

.cmp-search-results__item-content {
  display: flex;
  width: 100%;

  @include until($breakpoint-small) {
    flex-wrap: wrap;
    margin-bottom: rem(74);
  }

  @include until(450px) {
    margin-bottom: rem(0);
  }
}

.cmp-search-results__item-content-main {
  width: 100%;
  margin-bottom: rem(74);
  padding-right: rem(100);

  @include until($breakpoint-medium) {
    padding-right: rem(60);
  }

  @include until($breakpoint-small) {
    margin-bottom: 0;
    padding-right: 0;
  }
}

.cmp-search-results__image-wrapper {
  max-width: rem(188);

  a {
    display: flex;
    position: relative;
  }

  img {
    width: 100%;
    height: auto;
  }

  @include until($breakpoint-small) {
    margin-top: rem(20);
  }
}

.cmp-search-results__image-wrapper--is-video {

  a::after {
    position: absolute;
    content: '';
    width: rem(48);
    height: rem(48);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100%;
    background-color: $search-results-video-icon-color;
    top: calc(50% - #{rem(24)});
    left: calc(50% - #{rem(24)});
    @extend .icon-video-full;
  }
}

.cmp-search-results__item-toptag {
  text-transform: uppercase;
  font-weight: $font-weight-semi-bold;
  font-size: $font-flagtitle-size;
  line-height: $font-flagtitle-height;
  margin-bottom: rem(4);
  color: $search-results-top-tag-color;

  a {
    text-decoration: none;
    color: $search-results-top-tag-color;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

.cmp-search-results__item-title {
  font-weight: $font-weight-semi-bold;
  font-size: $font-title-size-7;
  line-height: $font-title-height-7;
  color: $search-results-color;
  margin-bottom: rem(18);

  a {
    text-decoration: none;
    color: $search-results-color;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  @include until($breakpoint-small) {
    font-size: $font-title-size-7-mobile;
    line-height: $font-title-height-7-mobile;
  }
}

.cmp-search-results__item-type {
  color: $search-results-type-color;
  font-weight: $font-weight-semi-bold;
  font-size: $font-flagtitle-size;
  line-height: $font-flagtitle-height;
  margin-bottom: rem(4);
}

.cmp-search-results__item-text {
  color: $search-results-color;
  font-weight: $font-weight-regular;
  font-size: $font-size-2;
  line-height: $font-height-2;

  a {
    text-decoration: none;
    color: $search-results-color;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  strong {
    color: $search-results-text-active-color;
    font-weight: $font-weight-bold;
  }

  @include until($breakpoint-small) {
    font-size: $font-size-1-b-mobile;
    line-height: $font-height-1-b-mobile;
  }
}

.cmp-search-results__item-content-thumbnail {
  margin-left: rem(20);
  left: rem(-8);
  display: flex;

  a {
    display: flex;
    position: relative;
    margin-bottom: rem(65);

    @include until($breakpoint-small) {
      margin-bottom: 0;
    }
  }

  @include until($breakpoint-small) {
    margin-left: 0;
    left: 0;
    width: 100%;
  }
}

.cmp-search-results__item-content-thumbnail--video {

  a::after {
    position: absolute;
    content: '';
    width: rem(60);
    height: rem(60);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100%;
    background-color: $search-results-media-icon-color;
    top: calc(50% - #{rem(30)});
    left: calc(50% - #{rem(30)});
    @extend .icon-video-full;
  }
}

.cmp-search-results__item-category-wrapper {
  width: 23.5%;
  padding-right: 8.5%;
  display: flex;
  justify-content: flex-end;
  padding-top: rem(52);

  @include until($breakpoint-medium) {
    padding-right: 0;
    width: rem(100);
  }

  @include until($breakpoint-small) {
    width: rem(80);
    padding-top: rem(40);
  }

  @include until(450px) {
    width: 100%;
    padding-top: rem(25);
    margin-bottom: rem(40);
    justify-content: flex-start;
  }
}

.cmp-search-results__item-category {
  position: relative;
  border: 1px solid $search-results-category-color;
  border-radius: 50%;
  width: rem(70);
  height: rem(70);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: $search-results-category-color;
  padding-bottom: rem(3);

  @include until($breakpoint-small) {
    width: rem(60);
    height: rem(60);
  }
}

.cmp-search-results__item-category-icon {
  width: rem(32);
  height: rem(32);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100%;
  background-color: $search-results-category-color;
  @extend .icon-ring-duotone-line;

  @include until($breakpoint-small) {
    width: rem(26);
    height: rem(26);
  }
}

.cmp-search-results__item-category-icon--news {
  @extend .icon-ring-duotone-line;
}

.cmp-search-results__item-category-icon--page {
  @extend .icon-articles;
}

.cmp-search-results__item-category-icon--videos {
  @extend .icon-video;
}

.cmp-search-results__item-category-icon--bilder {
  @extend .icon-image;
}

.cmp-search-results__item-category-label {
  font-weight: $font-weight-semi-bold;
  font-size: rem(10);
  line-height: 1;
  color: $search-results-category-color;
}

.cmp-search-results__footer {
  justify-content: center;
  align-items: center;
  margin-top: rem(85);
  display: none;
  width: 91.4%;

  @include until($breakpoint-large) {
    width: 100%;
  }
}

.cmp-search-results__footer--active {
  display: flex;
}

.cmp-search-results__empty-state, .cmp-search-results__error-state {
  padding-top: rem(16);
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.cmp-search-results__empty-state--active, .cmp-search-results__error-state--active {
  display: block;
}

.cmp-search-results__loading {
  position: absolute;
  left: 0;
  top: 0;
  padding-top: rem(16);
  min-height: rem(200);
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-color: $search-results-background-color;
}

.cmp-search-results__loading--active {
  opacity: 0.5;
  pointer-events: all;
}

.cmp-search-results__loading-icon {
  position: absolute;
  top: rem(120);
  left: calc(50% - #{rem(30)});
  width: rem(60);
  height: rem(60);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100%;
  background-color: $search-results-color;
  @extend .icon-loading;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cmp-search-results__select-save {
  opacity: 0.5;
}

.cmp-search-results__select-save--active {
  opacity: 1;
}

.cmp-search-results__select-wrapper {
  display: flex;
  flex-wrap: wrap;
  min-height: rem(87);
  margin-bottom: rem(63);
  width: 91.4%;

  @include until($breakpoint-large) {
    width: 100%;
  }

  @include until($breakpoint-medium) {
    margin-bottom: rem(50);
  }

  @include until($breakpoint-small) {
    min-height: rem(72);
    margin-bottom: rem(20);
  }
}

.cmp-search-results__select-title {
  font-weight: $font-weight-semi-bold;
  font-size: $font-date;
  line-height: $font-date-height;
  letter-spacing: 1px;
  color: $search-results-select-title-color;
  margin-bottom: rem(21);
  width: 100%;

  @include until($breakpoint-small) {
    margin-bottom: rem(14);
  }
}

.cmp-search-results__select {
  margin-right: rem(30);

  &:last-child {
    margin-right: 0;
  }

  @include until($breakpoint-small) {
    margin-right: rem(15);
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: rem(15);
  }
}

.cmp-search-results__select--federation {

  .ts-control {
    min-width: rem(295);

    @include until($breakpoint-small) {
      min-width: rem(230);
    }
  }
}

.cmp-search-results__active-federations {
  margin-bottom: rem(75);
  position: relative;
  min-height: rem(46);
  width: 91.4%;
  justify-content: space-between;
  padding-left: rem(60);
  display: none;

  &::before {
    content: '';
    position: absolute;
    top: rem(18);
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $search-results-active-federations-line-color;
  }

  &.cmp-search-results__active-federations--active {
    display: flex;
  }

  @include until($breakpoint-large) {
    width: 100%;
  }

  @include until($breakpoint-medium) {
    margin-bottom: rem(50);
  }

  @include until($breakpoint-small) {
    margin-bottom: rem(30);
    padding-left: 0;
    padding-right: 0;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;
  }
}

.cmp-search-results__active-federations-list {
  margin-top: 0;
  margin-bottom: 0;
  background-color: $search-results-background-color;
  padding-left: rem(47);
  padding-right: rem(8);
  position: relative;
  z-index: 1;

  .v-filter-tags__item {
    margin: 0 rem(10) rem(10) 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: rem(5);
    left: rem(6);
    width: rem(24);
    height: rem(24);
    background-color: $search-results-active-federations-icon-color;
    @extend .icon-filter;

    @include until($breakpoint-small) {
      display: none;
    }
  }

  @include until($breakpoint-small) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

.cmp-search-results__active-federations-reset {
  height: rem(36);
  background-color: $search-results-background-color;
  border: none;
  padding-left: rem(17);
  padding-right: rem(33);
  font-weight: $font-weight-semi-bold;
  font-size: $font-date;
  line-height: $font-date-height;
  letter-spacing: 1px;
  color: $search-results-select-reset-color;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  margin-left: rem(20);
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -1px;
    transform: translateY(-50%);
    width: rem(24);
    height: rem(24);
    background-color: $black;
    @extend .icon-close-x;

    @include until($breakpoint-small) {
      right: rem(10);
    }
  }

  &:focus {
    outline: 1px dashed $black;
  }

  &:active {
    outline: none;
  }

  &:hover {
    text-decoration: underline;
  }

  @include until($breakpoint-small) {
    margin-bottom: rem(15);
    max-width: rem(200);
  }
}

