@each $name, $button in $buttons {
  .button--#{$name} {

    .cmp-button {
      background-color: map-get($button, "bg-color");
      border-color: map-get($button, "border-color");
      color: map-get($button, "font-color");

      &::after {
        background-color: map-get($button, "hover-bg-color");
      }

      &:hover {
        border-color: map-get($button, "hover-border-color");
        color: map-get($button, "hover-font-color");

        & .cmp-button__text {
          color: map-get($button, "hover-font-color");
        }

        & .cmp-button__icon {
          background-color: map-get($button, "hover-icon-color");
        }
      }

      &:active {
        border-color: map-get($button, "hover-border-color");
        color: map-get($button, "active-font-color");

        & .cmp-button__text {
          color: map-get($button, "active-font-color");
        }

        & .cmp-button__icon {
          background-color: map-get($button, "active-font-color");
        }
      }

      &:focus {
        border-color: $button-outline;
        border-style: dashed;
        outline: none;
      }

      &:disabled {
        background-color: map-get($button, "disabled-bg-color");
        border-color: map-get($button, "disabled-border-color");
        color: map-get($button, "disabled-font-color");

        & .cmp-button__text {
          color: map-get($button, "disabled-font-color");
        }

        & .cmp-button__icon {
          background-color: map-get($button, "disabled-font-color");
        }
      }
    }

    .cmp-button__text {
      color: map-get($button, "font-color");
    }

    .cmp-button__icon {
      background-color: map-get($button, "icon-color");
    }
  }
}

@mixin add_button_base() {
  display: flex;
  width: fit-content;
  height: fit-content;
  margin-block: $button-margin;
  padding: $button-padding-medium;
  border-style: solid;
  border-width: 0;
  border-radius: $button-border-radius;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  font-size: $button-font-size-medium;
  font-weight: $button-font-weight;
  line-height: $button-font-height-medium;
  min-height: $button-min-height-medium;
  position: relative;
  overflow: hidden;

  span {
    position: relative;
    z-index: 1;
  }

  &.v-button--medium {

    @include from($breakpoint-small) {
      padding: $button-padding-medium;
      font-size: $button-font-size-medium;
      line-height: $button-font-height-medium;
      min-height: $button-min-height-medium;
    }
  }

  &::after {
    content: '';
    width: 100%;
    height: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: height 0.15s ease-in-out;
  }

  &:focus {
    outline: none;
  }

  &:hover {

    &::after {
      height: 100%;
    }
  }

  &:active {
    transform: scale(0.962);

    &::after {
      height: 100%;
    }
  }

  &:disabled {
    cursor: default;

    &::after {
      height: 0;
    }
  }

  @include from($breakpoint-small) {
    padding: $button-padding;
    font-size: $button-font-size;
    line-height: $button-font-height;
    min-height: $button-min-height;
    margin-block: $button-margin;
  }
}

@mixin add_button_style($name) {
  $button: map-get($buttons, $name);

  @if $button {
    background-color: map-get($button, "bg-color");
    border-color: map-get($button, "border-color");
    color: map-get($button, "font-color");

    @if $name == secondary or $name == secondary-inverted or $name == tertiary-inverted {
      border-width: 1px;
      padding: $button-padding-bordered-medium;

      @include from($breakpoint-small) {
        padding: $button-padding-bordered;
      }

      &.v-button--medium {

        @include from($breakpoint-small) {
          padding: $button-padding-bordered-medium;
        }
      }

      &:focus {
        border-color: $button-outline;
        border-style: dashed;

        &::before {
          display: none;
        }
      }
    } @else {
      border-width: 0;
      padding: $button-padding-medium;

      &:focus {

        &::before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: $button-border-radius;
          border: 1px dashed $button-outline;
        }
      }

      @include from($breakpoint-small) {
        padding: $button-padding;
      }
    }

    .v-button__icon {
      background-color: map-get($button, "icon-color");
    }

    &::after {
      background-color: map-get($button, "hover-bg-color");
    }

    &:hover {
      border-color: map-get($button, "hover-border-color");
      color: map-get($button, "hover-font-color");

      .v-button__icon {
        background-color: map-get($button, "hover-icon-color");
      }
    }

    &:disabled {
      background-color: map-get($button, "disabled-bg-color");
      border-color: map-get($button, "disabled-border-color");
      color: map-get($button, "disabled-font-color");
    }
  }
}

@mixin add_button_medium() {

  @include from($breakpoint-small) {
    padding: $button-padding-medium;
    font-size: $button-font-size-medium;
    font-weight: $button-font-weight;
    line-height: $button-font-height-medium;
    min-height: $button-min-height-medium;
  }
}
