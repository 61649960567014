.cmp-title {
  padding: 0 $content-offset-mobile;

  @include from($breakpoint-small) {
    padding: 0 $content-offset;
  }
}

.cmp-title__text {
  margin: 0;
  hyphens: auto;
  @include grid-item;
  @include grid-item--12;

  @include from($breakpoint-small) {
    @include grid-item--9;
  }

  // @include from($breakpoint-large) {
  //   @include grid-item--4;
  // }
}

.cmp-title__link {
  color: $link-color;
  text-decoration: $link-decoration-hover;

  &:hover {
    color: $link-color-hover;
    text-decoration: $link-decoration-hover;
    text-decoration-color: $link-color-hover;
  }

  &:focus {
    text-decoration: $link-decoration;
    outline: 1px dashed $link-color;
    text-decoration-color: $link-color;
  }
}