.cmp-breadcrumb {
  background-color: $breadcrumbs-background-color;
  color: $breadcrumbs-text-color;
  padding: rem(13) rem(30) rem(12) 18.9%;

  @include between($breakpoint-small, $breakpoint-medium) {
    padding: rem(11) rem(20) rem(10) 12%;
  }

  @include until($breakpoint-small) {
    padding: rem(7) rem(15) rem(6) 6%;
  }
}

.cmp-breadcrumb__list {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.cmp-breadcrumb__item {
  position: relative;

  &:not(:last-child) {
    margin-right: rem(42);

    &::after {
      content: '';
      background-color: $breadcrumbs-text-color;
      position: absolute;
      top: 50%;
      right: rem(-44);
      transform: translateY(-50%);
      width: rem(20);
      height: rem(20);
      mask-repeat: no-repeat;
      mask-size: cover;
      @extend .icon-arrow-simple-right;
    }
  }
}

.cmp-breadcrumb__item--active {

}

.cmp-breadcrumb__item > span, .cmp-breadcrumb__item-link {
  color: $breadcrumbs-text-color;
  text-decoration: none;
  font-size: $font-size-1;
  line-height: $font-height-1;
  font-weight: $font-weight-semi-bold;

  @include until($breakpoint-small) {
    font-size: $font-size-1-mobile;
    line-height: $font-height-1-mobile;
  }
}

.cmp-breadcrumb__item-link {

  &:hover {
    text-decoration: underline;
  }
}

.cmp-breadcrumb__item:first-child > span, .cmp-breadcrumb__item:first-child > .cmp-breadcrumb__item-link {
  font-size: $font-date;
  line-height: $font-date-height;
  position: relative;
  top: rem(2);
  letter-spacing: 1px;
  text-transform: uppercase;
}
