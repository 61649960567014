.newsletter-teaser {
  background-image: url('/src/default/resources/images/newsletter-teaser.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: rem(102) 6% rem(40);

  @include until($breakpoint-small) {
    background-image: url('/src/default/resources/images/newsletter-teaser-mobile.jpg');
    padding: rem(270) 5% rem(50);
  }
}

.newsletter-teaser__button {
  margin-top: 0;
  margin-bottom: rem(40);
  @include add_button_style($newsletter-teaser-button-variant);

  .v-button__icon {
    @extend .icon-arrow-diagonal-filled;
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
  }

  @include until($breakpoint-small) {
    margin-bottom: rem(50);
  }
}

.newsletter-teaser__text {
  margin-top: 0;
  margin-bottom: 0;
  color: $newsletter-teaser-text-color;
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-3;
  line-height: $font-height-3;
}
