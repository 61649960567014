.partner-fragment {
  margin-top: rem(90);
  margin-bottom: rem(90);
  padding-top: rem(50);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 6%;
    width: 88%;
    height: 1px;
    background-color: $partner-topline-sub-color;
  }

  &::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 6%;
    height: 2px;
    background-color: $partner-topline-color;
    width: 62.9%;
    display: none;

    @include until($breakpoint-small) {
      display: block;
    }
  }

  &>.cmp-container {
    padding-left: 6%;
    padding-right: 6%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 6%;
      width: 88%;
      height: 1px;
      background-color: $partner-topline-sub-color;
    }

    &>.aem-Grid {
      display: flex;
      flex-wrap: wrap;
      // column-gap: calc(12.5% + #{rem(31)});
      column-gap: 2rem;

      &::after {
        display: none;
      }

      &>.title {
        width: 100%;
      }

      &>.image {
        // width: calc(25% - #{rem(21)});
        width: calc(16.66667% - #{rem(27)});

        @include until($breakpoint-medium) {
          width: calc(33.333% - #{rem(19)});
        }

        @include until($breakpoint-small) {
          width: 50%;
        }
      }

      @include until($breakpoint-medium) {
        column-gap: rem(28);
      }

      @include until($breakpoint-small) {
        column-gap: 0;
      }
    }
  }

  .cmp-title {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    display: flex;

    // @include from($breakpoint-large) {
    //   margin-bottom: rem(54);
    // }
  }

  .cmp-title__text {
    padding-left: 0;
    padding-right: 0;
    // font-size: $font-title-size-4;
    // line-height: $font-title-height-4;
    // font-weight: $font-weight-bold;
    position: relative;
    max-width: 100%;
    width: auto;
    // font-size: 2.625rem;
    // font-weight: 500;
    // line-height: 1.19048;

    &::after {
      content: '';
      position: absolute;
      top: rem(-51);
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $partner-topline-color;
      display: block;

      @include until($breakpoint-medium) {
        top: rem(-36);
      }

      @include until($breakpoint-small) {
        display: none;
      }
    }

    @include from($breakpoint-large) {
      max-width: 33.333%;
    }

    @include until($breakpoint-small) {
      max-width: 66.666%;
      font-size: $font-title-size-4-mobile;
      line-height: $font-title-height-4-mobile;
    }
  }

  .cmp-image {
    position: relative;
    margin-top: 0;
    padding-top: 104%;
    margin-bottom: rem(35);

    @include until($breakpoint-medium) {
      margin-bottom: rem(30);
    }

    @include until($breakpoint-small) {
      margin-bottom: rem(25);
    }
  }

  .cmp-image__link {}

  .cmp-image__image {
    position: absolute;
    width: 69.1%;
    height: auto;
    left: 15.45%;
    top: 50%;
    transform: translateY(-50%);
  }

  .cmp-image__caption {
    color: $partner-text-color;
    font-size: $font-size-3;
    line-height: $font-height-3;
    font-weight: $font-weight-semi-bold;
    text-align: center;
    margin-top: rem(-10);

    a {
      color: $partner-text-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include until($breakpoint-medium) {
    margin-top: rem(60);
    margin-bottom: rem(60);
    padding-top: rem(35);
  }

  @include until($breakpoint-small) {
    margin-top: rem(20);
    margin-bottom: rem(40);
    padding-top: rem(20);
  }
}