.people-teaser {
  container: people-teaser / inline-size;
}

.cmp-people-teaser {
  position: relative;
  background-color: $people-teaser-item-background;
  border-bottom: 1px solid $people-teaser-item-borders-color;
  padding-top: rem(19);

  @include until($breakpoint-small) {
    padding-top: rem(19);
  }

  @container people-teaser (inline-size > 399px) {
    padding-top: rem(30);
  }
}

.cmp-people-teaser__photo {
  position: relative;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid $people-teaser-image-border;
  width: rem(120);
  padding-bottom: rem(120);
  margin: 0 auto rem(14);

  > a, > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  @container people-teaser (inline-size > 399px) {
    width: 54.5%;
    padding-bottom: 54.5%;
    margin: 0 auto rem(30);
  }
}

.cmp-people-teaser__name {
  margin-top: 0;
  color: $people-teaser-item-title-color;
  text-align: center;
  font-weight: $font-weight-regular;
  font-size: $font-title-size-4-mobile;
  line-height: $font-title-height-4-mobile;
  margin-bottom: rem(25);

  a {
    color: $people-teaser-item-title-color;
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: underline;
      color: $people-teaser-item-title-color;

      strong {
        text-decoration: underline;
      }
    }

    &:not([href]) {
      &:hover, &:focus {
        text-decoration: none;

        strong {
          text-decoration: none;
        }
      }
    }
  }

  strong {
    font-weight: $font-weight-bold;
    display: inline-block;

    @container people-teaser (inline-size > 399px) {
      display: block;
    }
  }

  @container people-teaser (inline-size > 399px) {
    margin-bottom: rem(30);
    font-size: $font-title-size-5;
    line-height: $font-title-height-5;
  }
}

.cmp-people-teaser__position {
  color: $people-teaser-item-text-color;
  font-size: $font-size-2;
  line-height: $font-height-2;
  font-weight: $font-weight-bold;
  border-top: 1px solid $people-teaser-item-borders-color;
  border-bottom: 1px solid $people-teaser-item-borders-color;
  padding: rem(16) calc(5.7% + #{rem(35)}) rem(10);

  @container people-teaser (inline-size > 399px) {
    padding: rem(13) calc(5.7% + #{rem(45)}) rem(13);
  }
}

.cmp-people-teaser__contact {
  padding: rem(12) 5.7%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

.cmp-people-teaser__phone, .cmp-people-teaser__mail {
  position: relative;
  font-size: $font-size-2;
  line-height: $font-height-2;
  color: $people-teaser-item-text-color;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 62%;
  padding: rem(8) 0 rem(8) rem(35);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: rem(24);
    height: rem(24);
    background-color: $people-teaser-item-text-color;
    transform: translateY(-50%);
  }

  &:hover, &:focus {
    text-decoration: underline;
    color: $people-teaser-item-title-color;
  }

  @include until($breakpoint-small) {
    width: 67%;
    padding-left: rem(35);
  }

  @container people-teaser (inline-size > 310px) {
    width: 67%;
  }

  @container people-teaser (inline-size > 340px) {
    width: 64%;
  }

  @container people-teaser (inline-size > 399px) {
    width: 67%;
    padding-left: rem(45);
  }
}

.cmp-people-teaser__phone {

  &::before {
    @extend .icon-phone;
    mask-size: 100%;
  }
}

.cmp-people-teaser__mail {
  width: 100% !important;

  &::before {
    @extend .icon-message;
    mask-size: 100%;
  }
}

.cmp-people-teaser__profile {
  position: absolute;
  right: 5.7%;
  top: rem(13);
  border-left: 1px solid $people-teaser-item-profile-border;
  display: flex;
  justify-content: flex-end;
  width: 35%;

  button, a {
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  }

  @container people-teaser (inline-size > 310px) {
    width: 35%;
  }

  @container people-teaser (inline-size > 340px) {
    width: 31%;
  }

  @container people-teaser (inline-size > 399px) {
    width: 26.5%;
  }
}
