input[type=text], input[type=email], input[type=password], input[type=tel], input[type=url], input[type=number], input[type=search], textarea {
  background-color: $input-bg-color;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  color: $input-font-color;
  font-size: $input-font-size;
  line-height: $input-line-height;
  padding: $input-padding;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: border-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out;
  max-width: rem(326);

  &:focus {
    border-color: $input-border-color-focus;
    outline: none;
  }

  &::placeholder {
    color: $input-placeholder-color;
  }

  &:disabled {
    background-color: $input-bg-color-disabled;
    border-color: $input-border-color-disabled;
    color: $input-font-color-disabled;

    &::placeholder {
      color: $input-placeholder-color-disabled;
    }
  }

  @include until($breakpoint-small) {
    font-size: $button-font-size-medium;
    line-height: $button-font-height-medium;
    padding: $input-padding-mobile;
  }
}
