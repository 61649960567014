.logo-fragment {
  background-color: $logo-wall-background-color;
  padding-top: rem(80);
  padding-bottom: rem(76);
  position: relative;

  > .cmp-container > .aem-Grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 6%;
    padding-right: 6%;

    &::before, &::after {
      display: none;
    }

    > .title {
      width: 100%;

      .cmp-title {
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        margin-bottom: rem(56);

        @include until($breakpoint-small) {
          margin-bottom: rem(44);
        }
      }

      .cmp-title__text {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        color: $logo-wall-text-color;
        font-size: $font-size-2;
        line-height: $font-height-2;
        font-weight: $font-weight-regular;

        @if $logo-wall-uppercase {
          text-transform: uppercase;
        } @else {
          text-transform: none;
        }
      }
    }

    .image {
      width: 14.82%;
      display: flex;
      align-items: center;
      justify-content: center;

      .cmp-image {
        margin-top: 0;
        width: 53.3%;
        padding-bottom: 53.3%;
        position: relative;
        margin-bottom: rem(8);

        @include until($breakpoint-small) {
          margin-bottom: rem(12);
        }
      }

      img {
        max-width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      @include until($breakpoint-medium) {
        width: 31.12%;
      }

      @include until($breakpoint-small) {
        width: 48.7%;
      }
    }

    @include until($breakpoint-small) {
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 6%;
    bottom: rem(49);
    width: 88%;
    height: rem(1);
    background-color: $logo-wall-line-color;

    @include until($breakpoint-small) {
      display: none;
    }
  }

  @include until($breakpoint-small) {
    padding-top: rem(50);
    padding-bottom: rem(38);
  }
}
