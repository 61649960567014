.cmp-image {
  margin-top: 2.25em;
  position: relative;

  &.cq-placeholder {
    padding-bottom: 50%;
    background-color: $image-placeholder-background;
  }
}

.cmp-image__link {
}

.cmp-image__image {
  display: block;
}

.cmp-image__title {
}

.cmp-image__copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: rem(10);
  line-height: rem(14);
  background-color: $black;
  color: $white;
  pointer-events: none;
  z-index: 1;
  padding: rem(3) rem(4);
  opacity: 0.7;
}

.image--stage {

  .cmp-image {
    margin: 0;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(344.2deg, rgba(0, 0, 0, 0.49) 28.19%, rgba(0, 0, 0, 0) 71.14%);
    }
  }
}
