.cmp-progressbar {
  display: block;
  margin: 0.625em 0.5em;
  overflow: hidden;
  text-align: center;
  background-color: $color-shade-1;
  border-radius: 1.31em;
}
.cmp-progressbar__label--completed {
  display: none;
}
.cmp-progressbar__label--remaining {
  display: none;
}
.cmp-progressbar__bar {
  color: $color-transparent;
  background-color: $color-accent;
}

.cmp-progressbar-completedPercentage {
  font-size: 1.125em;
  text-align: center;
}
