body.article-page {

  .breadcrumb {
    display: none;
  }

  .root {

    > .cmp-container {

      > .container--article-first {

        // Main title
        .cmp-title {
          position: relative;
          padding-left: 28.06%;
          padding-right: 13.475%;
          margin-bottom: rem(78);

          .cmp-title__text {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            font-size: $font-title-size-2;
            line-height: $font-title-height-2;
            font-weight: $article-main-title-weight;

            @if $article-main-title-uppercase {
              text-transform: uppercase;
            }

            @include until($breakpoint-small) {
              font-size: $font-title-size-2-mobile;
              line-height: $font-title-height-2-mobile;
            }
          }

          .cmp-title__article-info {
            position: absolute;
            left: 6%;
            top: rem(12);
            font-size: $font-date;
            line-height: $font-date-height;
            font-weight: $font-weight-regular;

            @include until($breakpoint-small) {
              position: relative;
              top: 0;
              left: 0;
              display: flex;
              flex-direction: row-reverse;
              justify-content: flex-end;
              align-items: center;
              margin-bottom: rem(10)
            }
          }

          .cmp-title__article-tag {
            color: $article-main-tag-color;
            margin-bottom: rem(9);

            @include until($breakpoint-small) {
              margin-bottom: 0;
            }
          }

          .cmp-title__article-date {
            color: $article-published-color;
            letter-spacing: 1px;

            @include until($breakpoint-small) {
              margin-right: rem(20);
            }
          }

          @include until($breakpoint-small) {
            margin-bottom: rem(40);
            padding-left: 5%;
            padding-right: 5%;
          }
        }

        // Main description
        .description {

          .cmp-article-properties__description {
            padding-left: 28.06%;
            padding-right: 13.475%;
            margin-bottom: rem(43);
            font-size: $font-title-size-8;
            line-height: $font-title-height-8;

            @include until($breakpoint-small) {
              font-size: $font-title-size-8-mobile;
              line-height: $font-title-height-8-mobile;
              margin-bottom: rem(20);
              padding-left: 5%;
              padding-right: 5%;
            }
          }
        }

        // Main image
        .cmp-image {
          width: 88%;
          margin: 0 6% rem(154);

          .cmp-image__more-info {
            display: flex;
            justify-content: space-between;
            padding-top: rem(28);
            color: $article-more-info-color;

            @include until($breakpoint-small) {
              padding-top: rem(15);
            }
          }

          .cmp-image__caption {
            width: 57.45%;

            @include until($breakpoint-small) {
              width: calc(100% - #{rem(100)});
              padding-right: rem(20);
            }
          }

          .cmp-image__copy {
            width: rem(100);
            text-align: right;
            font-size: $font-date;
            line-height: $font-date-height;
          }

          .cmp-image__copyright {
            display: none;
          }

          @include until($breakpoint-small) {
            width: 90%;
            margin: 0 5% rem(80);
          }
        }

        // Main article data
        .articleProperties {
          width: 22.06%;
          margin-left: 6%;

          .cmp-article-properties {
            border-top: 1px solid $article-line-color;
            padding: rem(25) rem(25) rem(25) 0;
            color: $black;
            font-size: $font-size-3;
            line-height: $font-height-3;
            font-weight: $font-weight-semi-bold;
            margin-bottom: rem(75);

            div {
              display: inline-block;
            }

            .cmp-article-properties__main-tag {
              display: none;
            }

            .cmp-article-properties__author {

              &::before {
                content: 'Von ';
              }
            }

            @include until($breakpoint-small) {
              padding: rem(15) rem(15) rem(15) 0;
              margin-bottom: rem(30);
              font-size: $font-size-3-mobile;
              line-height: $font-height-3-mobile;
            }
          }

          @include until($breakpoint-small) {
            width: 45%;
            margin-left: 5%;
          }
        }

        // Main article socian
        .social-sharing {
          float: right;
          width: 65.94%;
          margin-right: 6%;
          display: flex;
          border-top: 1px solid $article-line-color;

          .cmp-social-sharing {
            padding: rem(22) 0;
            margin-bottom: rem(75);

            @include until($breakpoint-small) {
              padding: rem(15) rem(15) rem(15) 0;
              margin-bottom: rem(30);
            }
          }

          @include until($breakpoint-small) {
            width: 45%;
            margin-right: 5%;
          }
        }
      }

      // Main article content
      > .container--article-second {

        > .cmp-container {

          > .aem-Grid {

            > .container {

              > .cmp-container {

                > .container--article-page:first-child {
                  margin-bottom: rem(100);
                  padding-left: 24.86%;
                  padding-right: 10.275%;

                  @include until($breakpoint-small) {
                    margin-bottom: rem(50);
                    padding-left: 1%;
                    padding-right: 1%;
                  }
                }
              }
            }
          }
        }

        // Main article text
        .cmp-text {

          p, blockquote, ul, ol {
            padding-left: 0;
            padding-right: 0;
            width: 100%;

            &:last-child {
              margin-bottom: 0;
            }

            &:first-child {
              margin-top: 0;
            }
          }

          p {
            font-size: $font-title-size-8;
            line-height: $font-title-height-8;

            @include until($breakpoint-small) {
              font-size: $font-size-1;
              line-height: $font-height-1;
            }
          }
        }

        & > .cmp-container > .aem-Grid > .container > .cmp-container {

          & > .container:nth-child(2) .cmp-container {
            display: flex;

            // Article author
            .title {
              margin-left: 6%;
              width: 22.06%;

              .cmp-title {
                margin-top: 0;
                padding: 0;
                position: relative;
                margin-bottom: rem(126);

                h6 {
                  padding: 0;
                  font-size: $font-size-3;
                  line-height: $font-height-3;
                  font-weight: $font-weight-semi-bold;
                  background-color: $white;
                  display: inline-block;
                  margin-top: rem(83);
                  z-index: 1;
                  position: relative;
                  width: auto;
                  padding-right: rem(30);

                  @include until($breakpoint-small) {
                    margin-top: rem(82);
                    padding-right: rem(15);
                    font-size: $font-size-3-mobile;
                    line-height: $font-height-3-mobile;
                  }

                  @media screen and (max-width: 500px) {
                    margin-top: 0;
                  }
                }

                &::after {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: rem(94);
                  height: 1px;
                  width: calc(100% - #{rem(28)});
                  background-color: $article-line-color;

                  @media screen and (max-width: 500px) {
                    top: rem(13);
                    width: 100%;
                  }
                }

                @include until($breakpoint-small) {
                  margin-bottom: rem(70);
                }

                @media screen and (max-width: 500px) {
                  margin-bottom: rem(30);
                }
              }

              @include until($breakpoint-small) {
                margin-left: 5%;
                width: 35%;
              }

              @media screen and (max-width: 500px) {
                margin-left: 5%;
                width: 90%;
                margin-right: 5%;
              }
            }

            .peopleteaser {
              width: 65.94%;

              .cmp-people-teaser {
                position: relative;
                background-color: transparent;
                padding: 0;
                border-bottom: none;
                margin-bottom: rem(126);

                &::after {
                  content: '';
                  position: absolute;
                  right: 0;
                  top: rem(94);
                  height: 1px;
                  width: calc(100% - #{rem(216)});
                  background-color: $article-line-color;

                  @media screen and (max-width: 500px) {
                    display: none;
                  }
                }

                @include until($breakpoint-small) {
                  margin-bottom: rem(70);
                }

                @media screen and (max-width: 500px) {
                  margin-bottom: rem(50);
                }
              }

              .cmp-people-teaser__photo {
                margin-left: 0;
                margin-right: 0;
                width: rem(188);
                padding-bottom: rem(188);
                margin-bottom: rem(25);
                border: none;

                @include until($breakpoint-small) {

                }
              }
              
              .cmp-people-teaser__name {
                text-align: left;
                font-size: $font-size-1;
                line-height: $font-height-1;
                font-weight: $font-weight-bold;
                margin-bottom: 0;

                strong {
                  font-weight: $font-weight-bold;
                  display: inline-block;
                }

                @include until($breakpoint-small) {
                  font-size: $font-size-1-mobile;
                  line-height: $font-height-1-mobile;
                }
              }

              .cmp-people-teaser__position {
                padding: 0;
                border-top: none;
                border-bottom: none;
                font-size: $font-size-1;
                line-height: $font-height-1;
                font-weight: $font-weight-regular;
                margin-bottom: rem(26);

                @include until($breakpoint-small) {
                  font-size: $font-size-1-mobile;
                  line-height: $font-height-1-mobile;
                  margin-bottom: rem(15);
                }
              }

              .cmp-people-teaser__contact {
                padding: 0;
                border-bottom: none;
              }

              .cmp-people-teaser__phone, .cmp-people-teaser__mail {
                padding: 0;
                font-size: $font-size-1;
                line-height: $font-height-1;
                font-weight: $font-weight-regular;

                &:before {
                  width: auto;
                  height: auto;
                  background-color: transparent;
                  position: relative;
                  top: 0;
                  left: 0;
                  transform: none;
                  mask-image: none;
                  display: inline-block;
                }

                @include until($breakpoint-small) {
                  font-size: $font-size-1-mobile;
                  line-height: $font-height-1-mobile;
                }
              }

              .cmp-people-teaser__phone {

                &:before {
                  content: 'Tel.\00a0';
                }
              }

              .cmp-people-teaser__mail {

                &:before {
                  content: 'E-mail:\00a0';
                }
              }

              @include until($breakpoint-small) {
                width: 55%;
                margin-right: 5%;
              }

              @media screen and (max-width: 500px) {
                width: 90%;
                margin-right: 5%;
                margin-left: 5%;
              }
            }

            @media screen and (max-width: 500px) {
              flex-wrap: wrap;
            }
          }

          & > .container:nth-child(3) .cmp-container {
            display: flex;
            flex-wrap: wrap;

            // Social title
            .title {
              margin-left: 6%;
              width: 22.06%;

              .cmp-title {
                margin-top: 0;
                padding: 0;
                position: relative;
                margin-bottom: rem(200);

                h6 {
                  padding: 0;
                  font-size: $font-size-3;
                  line-height: $font-height-3;
                  font-weight: $font-weight-semi-bold;
                  background-color: $white;
                  display: inline-block;
                  margin-top: rem(83);
                  z-index: 1;
                  position: relative;
                  width: auto;
                  padding-right: rem(30);

                  @include until($breakpoint-small) {
                    font-size: $font-size-3-mobile;
                    line-height: $font-height-3-mobile;
                    margin-top: rem(37);
                    padding-right: rem(15);
                  }

                  @media screen and (max-width: 500px) {
                    margin-top: 0;
                  }
                }

                &::after {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: rem(94);
                  height: 1px;
                  width: calc(100% - #{rem(28)});
                  background-color: $article-line-color;

                  @include until($breakpoint-small) {
                    top: rem(50);
                  }

                  @media screen and (max-width: 500px) {
                    top: rem(13);
                  }
                }

                @include until($breakpoint-small) {
                  margin-bottom: rem(100);
                }

                @media screen and (max-width: 500px) {
                  margin-bottom: rem(16);
                }
              }

              @include until($breakpoint-small) {
                margin-left: 5%;
                width: 35%;
              }

              @media screen and (max-width: 500px) {
                width: 90%;
                margin-right: 5%;
              }
            }

            // Social icons
            .social-sharing {
              width: 65.94%;

              .cmp-social-sharing {
                position: relative;
                background-color: transparent;
                border-bottom: none;
                margin-bottom: rem(200);
                padding: rem(74) 0 0;
                display: flex;

                &::after {
                  content: '';
                  position: absolute;
                  right: 0;
                  top: rem(94);
                  height: 1px;
                  width: calc(100% - #{rem(28)});
                  background-color: $article-line-color;

                  @include until($breakpoint-small) {
                    top: rem(50);
                  }

                  @media screen and (max-width: 500px) {
                    display: none;
                  }
                }

                .cmp-social-sharing__list {
                  padding-right: rem(28);
                  background-color: $white;
                  position: relative;
                  z-index: 1;

                  @include until($breakpoint-small) {
                    padding-right: rem(15);
                  }
                }

                @include until($breakpoint-small) {
                  margin-bottom: rem(100);
                  padding: rem(33) 0 0;
                }

                @media screen and (max-width: 500px) {
                  margin-bottom: rem(60);
                  padding: 0;
                }
              }

              @include until($breakpoint-small) {
                width: 55%;
                margin-right: 5%;
              }

              @media screen and (max-width: 500px) {
                width: 90%;
                margin-left: 5%;
              }
            }
          }

          & > .container:nth-child(4) .cmp-container {

            .news {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
