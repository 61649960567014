.h1_style,
.h2_style,
.h3_style,
.h4_style,
.h5_style,
.h6_style {

  .cmp-title__text {
    color: $title-font-color;

    @if $title-uppercase {
      text-transform: uppercase;
    }
  }
}

.h1_style {

  .cmp-title {
    margin-top: rem(20);
    margin-bottom: rem(20);

    @include from($breakpoint-small) {
      margin-top: rem(40);
      margin-bottom: rem(40);
    }
  }

  .cmp-title__text {
    font-weight: $font-title-size-1-weight;
    font-size: $font-title-size-2;
    line-height: $font-title-height-2;
    font-family: titleFont, serif;

    @include until($breakpoint-small) {
      font-size: $font-title-size-2-mobile;
      line-height: $font-title-height-2-mobile;
    }
  }

  .cmp-title__link {}
}

.h2_style {

  .cmp-title {
    margin-top: rem(20);
    margin-bottom: rem(20);

    @include from($breakpoint-small) {
      margin-top: rem(40);
      margin-bottom: rem(40);
    }
  }

  .cmp-title__text {
    font-weight: $font-title-size-2-weight;
    font-size: $font-title-size-4;
    line-height: $font-title-height-4;
    font-family: titleFont, serif;

    @include until($breakpoint-small) {
      font-size: $font-title-size-4-mobile;
      line-height: $font-title-height-4-mobile;
    }
  }

  .cmp-title__link {}
}

.h3_style {

  .cmp-title {
    margin-top: rem(16);
    margin-bottom: rem(16);

    @include from($breakpoint-small) {
      margin-top: rem(32);
      margin-bottom: rem(32);
    }
  }

  .cmp-title__text {
    font-weight: $font-title-size-3-weight;
    font-size: $font-title-size-5;
    line-height: $font-title-height-5;
    font-family: titleFont, serif;

    @include until($breakpoint-small) {
      font-size: $font-title-size-5-mobile;
      line-height: $font-title-height-5-mobile;
    }
  }

  .cmp-title__link {}
}

.h4_style {

  .cmp-title {
    margin-top: rem(10);
    margin-bottom: rem(10);

    @include from($breakpoint-small) {
      margin-top: rem(20);
      margin-bottom: rem(20);
    }
  }

  .cmp-title__text {
    font-weight: $font-title-size-4-weight;
    font-size: $font-title-size-6;
    line-height: $font-title-height-6;
    font-family: titleFont, serif;

    @include until($breakpoint-small) {
      font-size: $font-title-size-6-mobile;
      line-height: $font-title-height-6-mobile;
    }
  }

  .cmp-title__link {}
}

.h5_style {

  .cmp-title {
    margin-top: rem(10);
    margin-bottom: rem(10);

    @include from($breakpoint-small) {
      margin-top: rem(20);
      margin-bottom: rem(20);
    }
  }

  .cmp-title__text {
    font-weight: $font-title-size-5-weight;
    font-size: $font-title-size-8;
    line-height: $font-title-height-8;
    font-family: titleFont, serif;

    @include until($breakpoint-small) {
      font-size: $font-title-size-8-mobile;
      line-height: $font-title-height-8-mobile;
    }
  }

  .cmp-title__link {}
}

.h6_style {

  .cmp-title {
    margin-top: rem(10);
    margin-bottom: rem(10);

    @include from($breakpoint-small) {
      margin-top: rem(20);
      margin-bottom: rem(20);
    }
  }

  .cmp-title__text {
    font-weight: $font-title-size-6-weight;
    font-size: $font-title-size-8;
    line-height: $font-title-height-8;
    font-family: titleFont, serif;

    @include until($breakpoint-small) {
      font-size: $font-title-size-8-mobile;
      line-height: $font-title-height-8-mobile;
    }
  }

  .cmp-title__link {}
}
