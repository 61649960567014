.cmp-top-news {
  position: relative;
  margin-top: rem(84);
  margin-bottom: rem(84);
  padding-top: rem(50);
  padding-left: 6%;
  padding-right: 6%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 6%;
    width: 88%;
    height: 1px;
    background-color: $top-news-topline-sub-color;
  }

  @include until($breakpoint-small) {
    padding-top: rem(20);
    margin-top: rem(60);
    margin-bottom: rem(60);
  }
}

.cmp-top-news__header {
  margin-bottom: rem(88);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include until($breakpoint-small) {
    flex-wrap: wrap;
    margin-bottom: rem(40);
  }
}

.cmp-top-news__main-title {
  margin-top: 0;
  margin-bottom: 0;
  color: $top-news-title-color;
  padding-right: rem(30);
  position: relative;
  min-height: rem(64);
  display: flex;
  align-items: center;
  font-weight: $top-news-title-weight !important;

  @if $top-news-title-uppercase {
    text-transform: uppercase;
  }

  &::after {
    content: '';
    position: absolute;
    top: rem(-51);
    left: 0;
    width: calc(100% - #{rem(30)});
    height: 2px;
    background-color: $top-news-topline-color;

    @include until($breakpoint-small) {
      height: 1px;
      top: rem(-20);
    }
  }
}

.cmp-top-news__switch {
  display: none;
  z-index: 3;
  position: relative;
  align-items: center;

  @include until($breakpoint-small) {
    display: flex;
  }
}

.cmp-top-news__switch-label {
  margin-right: rem(15);
}

.cmp-top-news__tags {
  max-width: 80%;

  @include until($breakpoint-small) {
    width: 100%;
    max-width: 100%;
    padding-top: rem(42);
  }
}

.cmp-top-news__article-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cmp-top-news__article {
  width: 48.2%;
  margin-bottom: rem(70);
  position: relative;

  @include until($breakpoint-small) {
    width: 100%;
    margin-bottom: rem(46);
  }
}

.cmp-top-news__article-date {
  letter-spacing: 1px;
}

.cmp-top-news__article-image-wrap {
  display: flex;
}

.cmp-top-news__article-image {
  display: flex;
  width: 100%;

  > a {
    width: 100%;

    > div {
      width: 100%;
    }
  }

  .cmp-image {
    display: flex;
    width: 100%;
    padding-bottom: 74.32%;
    position: relative;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.cmp-top-news__article-image-info {
  color: $top-news-article-image-info-color;
  display: none;
  font-size: $font-size-4;
  line-height: $font-height-4;
  padding-top: rem(24);
}

.cmp-top-news__article-content {
  padding-top: rem(28);
  position: relative;

  @include until($breakpoint-small) {
    padding-top: rem(25);
  }
}

.cmp-top-news__article-length {
  color: $top-news-article-length-color;
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-4;
  line-height: $font-height-4;
  margin-bottom: rem(20);
  width: 48.36%;
}

.cmp-top-news__article-title {
  color: $top-news-article-title-color;
  font-weight: $top-news-article-title-weight;
  margin-top: 0;
  margin-bottom: rem(20);
  width: 48.36%;
  overflow-wrap: break-word;
  hyphens: auto;

  @if $top-news-article-title-uppercase {
    text-transform: uppercase;
  }
  
  a {
    text-decoration: none;
    color: $top-news-article-title-color;
    display: inline-block;

    &:hover {
      // text-decoration: underline;
      color: $soot-grey;
    }
    &:focus, :focus-visible {
      outline: none;
      border: 1px dashed $button-outline;
    }
  }

  @include until($breakpoint-large) {
    width: 65.8%;
  }

  @include until($breakpoint-small) {
    font-size: $font-title-size-6-mobile;
    line-height: $font-title-height-6-mobile;
  }
}

.cmp-top-news__article-text, .cmp-top-news__article-text-mobile {
  color: $top-news-article-text-color;
  font-weight: $font-weight-semi-bold;
  width: 48.36%;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  @include until($breakpoint-large) {
    width: 65.8%;
  }

  @include until($breakpoint-medium) {
    display: none;
  }

  @include until($breakpoint-small) {
    font-size: $font-size-1-mobile;
    line-height: $font-height-1-mobile;
    width: 100%;
  }
}

.cmp-top-news__article-text-mobile {
  display: none;

  @include until($breakpoint-medium) {
    display: block;
  }
}

.cmp-top-news__article-image-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cmp-top-news__article-category, .cmp-top-news__article-category-secondary {
  color: $top-news-article-tags-color;
  border-left: 1px solid $top-news-article-tags-line-color;
  padding-left: rem(14);
  font-weight: $font-weight-bold;
  font-size: $font-size-4;
  line-height: $font-height-4;
  min-height: rem(30);
  position: absolute;
  right: 0;
  top: rem(21);
  min-width: 31.7%;
  max-width: 60%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

  > span {
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @include until($breakpoint-small) {
    top: rem(17);
  }
}

.cmp-top-news__article-category-secondary {
  display: none;
  position: relative;
  top: 0;
  margin-top: rem(19);
}

.cmp-top-news__footer {
  display: flex;
  justify-content: center;

  @include until($breakpoint-small) {
    margin-top: rem(20);
  }

  span.v-button__icon{
    display: none;
  }
}

.cmp-top-news__article--main {

  @include from($breakpoint-small) {
    width: 100%;
  }

  @include from($breakpoint-medium) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: rem(100);

    .cmp-top-news__article-image-info {
      display: block;
    }

    .cmp-top-news__article-content {
      position: static;
      width: 30.64%;
    }

    .cmp-top-news__article-image-wrap {
      flex-direction: column;
      width: 65.76%;
    }

    .cmp-top-news__article-title, .cmp-top-news__article-length, .cmp-top-news__article-text {
      width: 76.1%;
    }

    .cmp-top-news__article-category {
      display: none;
    }

    .cmp-top-news__article-category-secondary {
      display: flex;
      width: auto;
    }
  }
}

.cmp-top-news--compact {

  @include until($breakpoint-small) {

    .cmp-top-news__article {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: rem(56);
    }

    .cmp-top-news__article-text-mobile {
      display: none;
    }

    .cmp-top-news__article-image-wrap {
      width: 34%;
      padding-top: rem(46);
    }

    .cmp-top-news__article-image {
      overflow: hidden;

      a {
        display: block;
      }

      img {
        width: 110%;
        max-width: 110%;
        margin-left: -5%;;
      }
    }

    .cmp-top-news__article-content {
      position: static;
      width: 64%;
      padding-top: 0;
    }

    .cmp-top-news__article-length {
      width: 100%;
      padding-top: rem(6);
    }

    .cmp-top-news__article-title {
      width: 100%;
      margin-bottom: 0;
    }

    .cmp-top-news__article-category {
      top: 0;
      width: 34%;
      padding-left: rem(12);
    }

    .cmp-top-news__article-category, .cmp-top-news__article-category-secondary {
    }

    .cmp-top-news__footer {
      margin-top: rem(10);
    }
  }
}

.cmp-top-news--loading {

  .cmp-top-news__article-list, .cmp-top-news__tags {

    &:after {
      content: '';
      position: absolute;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100%;
      background-color: $search-results-color;
      @extend .icon-loading;
      animation: spin 1.2s linear infinite;
      opacity: 0.5;
      z-index: 3;
    }
  }

  .cmp-top-news__article-list {
    min-height: rem(400);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      z-index: 2;
      opacity: 0.5;
    }

    &:after {
      top: rem(120);
      left: calc(50% - #{rem(30)});
      width: rem(60);
      height: rem(60);
    }
  }

  .cmp-top-news__tags {
    position: relative;
    min-height: rem(64);

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      z-index: 2;

      @include until($breakpoint-small) {
        top: rem(42);
        height: calc(100% - #{rem(42)});
      }
    }

    &:after {
      top: rem(12);
      left: calc(50% - #{rem(20)});
      width: rem(40);
      height: rem(40);

      @include until($breakpoint-small) {
        top: rem(55);
        left: calc(50% - #{rem(15)});
        width: rem(30);
        height: rem(30);
      }
    }

    @include until($breakpoint-small) {
      min-height: rem(98);
    }
  }
}

// Top news and similar news
.news {

  .similarNews-news, .recentNews-news {

    .cmp-top-news {
      margin-top: 0;
      margin-bottom: rem(20);

      @include until($breakpoint-small) {
        margin-bottom: rem(10);
      }
    }

    .cmp-top-news__header {
      margin-bottom: rem(66);

      @include until($breakpoint-small) {
        margin-bottom: rem(20);
      }
    }

    .cmp-top-news__article-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: rem(28);

      @include until($breakpoint-small) {
        margin-bottom: rem(40);
      }
    }

    .cmp-top-news__article {
      flex-basis: calc(33.33% - #{rem(18.66)});
      width: auto;

      @include until($breakpoint-small) {
        flex-basis: 100%;
        margin-bottom: 0;
      }
    }

    .cmp-top-news__article-content {
      padding-top: rem(14);

      @include until($breakpoint-small) {
        padding-top: rem(8);
      }
    }

    .cmp-top-news__article-date {
      font-size: $font-size-4;
      line-height: $font-height-4;
      font-weight: $font-weight-semi-bold;
      margin-bottom: rem(20);

      @include until($breakpoint-small) {
        font-size: $font-size-4-mobile;
        line-height: $font-height-4-mobile;
        margin-bottom: rem(15);
      }
    }

    .cmp-top-news__article-title {
      width: 66.75%;

      @include until($breakpoint-small) {
        width: 100%;
      }
    }

    .cmp-top-news__article-author {
      font-size: $font-size-2;
      line-height: $font-height-2;
      font-weight: $font-weight-semi-bold;

      @include until($breakpoint-small) {
        font-size: $font-size-2-mobile;
        line-height: $font-height-2-mobile;
      }
    }
  }
}
