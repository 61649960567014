.cmp-header__auth {
  pointer-events: none;
  padding-top: rem(17);
  padding-bottom: rem(150);

  @include until($breakpoint-medium) {
    padding-top: rem(17);
    padding-bottom: rem(118);
  }
}

.cmp-header__auth--active {
  pointer-events: auto;
}

.cmp-header__auth-close {

}

.cmp-header__auth-menu {
  width: 100%;
  display: flex;
}

.cmp-header__auth-menu-item {
  position: relative;
  border: none;
  background-color: transparent;
  padding: rem(27) 0;
  color: $header-auth-font-unactive-color;
  font-weight: $font-weight-light;
  font-size: $font-title-size-5;
  line-height: $font-title-height-5;
  margin-right: rem(65);
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: rem(-2);
    left: 0;
    width: 0;
    height: rem(2);
    background-color: $header-auth-icon-active-color;
    transition: width 0.5s cubic-bezier(0.33, 1, 0.68, 1);
    transition-property: width, color;
  }

  &:hover, &:focus, &.cmp-header__auth-menu-item--active {
    color: $header-auth-font-color;

    &::after {
      width: 100%;
    }
  }

  @include until($breakpoint-medium) {
    padding: rem(20) 0;
    margin-right: rem(40);
    font-size: $font-title-size-5-mobile;
    line-height: $font-title-height-5-mobile;
  }
}

.cmp-header__auth-content {
  width: 100%;
  position: relative;
}

.cmp-header__auth-register, .cmp-header__auth-login {
  padding-top: rem(40);
  display: none;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.33, 1, 0.68, 1);

  @include until($breakpoint-medium) {
    padding-top: rem(20);
  }
}

.cmp-header__auth-register--active, .cmp-header__auth-login--active {
  opacity: 1;
  display: block;
}

.cmp-header__auth-form-control {
  margin-bottom: rem(22);
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  @include until($breakpoint-medium) {
    margin-bottom: rem(14);
  }
}

.cmp-header__auth-form-control--switch {
  display: flex;
  align-items: center;
  margin-top: rem(-10);

  .cmp-header__auth-label {
    margin-left: rem(16);
  }

  @include until($breakpoint-medium) {
    margin-top: rem(-18);
  }
}

.cmp-header__auth-label {
  display: block;
  font-size: $font-size-3;
  line-height: $font-height-3;
  font-weight: $font-weight-regular;
  color: $header-auth-label-color;
}

.cmp-header__auth-label--hidden {
  display: none;
}

.cmp-header__auth-input {

  &[type="password"] {
    padding-right: rem(48);
  }

  @include until(500px) {
    max-width: 100% !important;
  }
}

.cmp-header__auth-show-password {
  position: absolute;
  top: rem(7);
  left: rem(280);
  width: rem(34);
  height: rem(34);
  @extend .icon-view-hide;
  background-color: $header-main-icon-color;
  mask-repeat: no-repeat;
  mask-size: rem(24) rem(24);
  mask-position: center;
  cursor: pointer;
  transition: background-color 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  &:hover, &:focus {
    background-color: $header-main-active-color;
  }

  @include until($breakpoint-medium) {
    top: rem(3);
  }

  @include until(500px) {
    left: auto;
    right: rem(12);
  }
}

.cmp-header__auth-show-password--active {
  @extend .icon-view;
}

.cmp-header__auth-form-control-sublink {
  display: flex;
  justify-content: flex-end;
  max-width: rem(326);
  padding: rem(8) rem(20);

  a {
    font-weight: $font-weight-regular;
    font-size: $font-date;
    line-height: $font-date-height;
    color: $header-auth-label-color;
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

.cmp-header__auth-button {
  @include add_button_base();
  @include add_button_style($header-auth-button-type);
  margin-bottom: 0 !important;
  margin-top: rem(43) !important;

  @include until($breakpoint-medium) {
    margin-top: rem(30) !important;
  }
}

.cmp-header__auth-alert {
  margin-top: rem(24);
  margin-bottom: 0;
}
