@use 'sass:math';

.v-switch {
  position: relative;
  display: inline-block;
  width: $switch-width;
  height: $switch-height;
}

.v-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.v-switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $switch-background;
  border: 1px solid $switch-border;
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  border-radius: $switch-radius;
}

.v-switch__slider::before {
  position: absolute;
  content: "";
  height: #{math.div($switch-width, 2) + 0.0625rem};
  width: #{math.div($switch-width, 2) + 0.0625rem};
  left: rem(2);
  bottom: rem(1);
  background-color: $switch-color;
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  border-radius: 50%;
}

input:checked + .v-switch__slider {
  border-color: $switch-border-active;
  background-color: $switch-background-active;
}

input:focus + .v-switch__slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .v-switch__slider::before {
  transform: translateX(#{math.div($switch-width, 2) + 0.0625rem - 0.5rem});
  background-color: $switch-color-active;
}

.v-switch--medium {
  width: $switch-width-medium;
  height: $switch-height-medium;

  .v-switch__slider::before {
    height: #{math.div($switch-width-medium, 2)};
    width: #{math.div($switch-width-medium, 2)};
    left: rem(2);
    bottom: rem(1);
  }
}

.v-switch--medium input:checked + .v-switch__slider::before {
  transform: translateX(#{math.div($switch-width, 2) - 0.7rem});
}
