.register-teaser {
  container: register-teaser / inline-size;
  position: relative;
  padding-bottom: 59.97%;
  background-color: $register-teaser-background-color;
  background-image: url('/src/default/resources/images/register-teaser-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include until($breakpoint-small) {
    background-image: url('/src/default/resources/images/register-teaser-top-bg-mobile.png');
    padding: 0;
    height: rem(400);
  }
}

.register-teaser__content {
  position: absolute;
  left: 0;
  top: 0;
  width: rem(631);
  height: rem(611);
  z-index: 1;
  background-image: url('/src/default/resources/images/register-teaser-top-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-left: 6%;
  padding-top: rem(80);

  @include until($breakpoint-large) {
    padding-top: rem(60);
    width: rem(560);
    height: rem(542);
  }

  @include until($breakpoint-medium) {
    padding-left: 5%;
    padding-top: rem(40);
    width: rem(440);
    height: rem(426);
  }

  @include until($breakpoint-small) {
    width: 100%;
    height: 100%;
  }

  @container register-teaser (inline-size > 2400px) {
    width: rem(720);
    height: rem(697);
  }
}

.register-teaser__title {
  color: $register-teaser-text-color;
  font-weight: $font-weight-semi-bold;
  font-size: $font-title-size-2;
  line-height: $font-title-height-2;
  margin-bottom: rem(39);
  max-width: rem(400);

  @include until($breakpoint-large) {
    margin-bottom: rem(30);
  }

  @include until($breakpoint-medium) {
    font-size: $font-title-size-2-mobile;
    line-height: $font-title-height-2-mobile;
    margin-bottom: rem(20);
    max-width: rem(250);
  }
}

.register-teaser__text, .register-teaser__text p {
  color: $register-teaser-text-color;
  font-weight: $font-weight-regular;
  font-size: $font-title-size-5;
  line-height: $font-title-height-5;

  @include until($breakpoint-medium) {
    font-size: $font-title-size-5-mobile;
    line-height: $font-title-height-5-mobile;
  }

  @include until($breakpoint-small) {
    font-size: $font-title-size-6-mobile;
    line-height: $font-title-height-6-mobile;
  }
}

.register-teaser__text {
  margin-bottom: rem(60);
  max-width: rem(400);

  @include until($breakpoint-large) {
    margin-bottom: rem(45);
  }

  @include until($breakpoint-medium) {
    margin-bottom: rem(30);
    max-width: rem(340);
  }

  @include until($breakpoint-small) {
    max-width: rem(280);
  }
}

.register-teaser__text p {

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

.register-teaser__button {

}
