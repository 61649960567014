.columncontainer {

  .cmp-container { // TODO: check in finalised component
    display: grid;
    grid-template-columns: repeat(3, 31.9%);
    grid-column-gap: 2.15%;
    grid-row-gap: rem(50);
    background-color: $custom-container-background-white;
    padding: rem(175) 6% rem(175);

    > .title, > .cmp-container--title { // TODO: maybe .cmp-container--title, need wait for finalised component
      grid-column: 1 / -1;

      .cmp-title {
        padding-left: 0;
        padding-right: 0;
        margin-top: rem(82);
        margin-bottom: rem(82);
        font-weight: $custom-container-title-weight;

        @if $custom-container-title-uppercase {
          text-transform: uppercase;
        }

        @include until($breakpoint-large) {
          margin-top: rem(65);
          margin-bottom: rem(65);
        }

        @include until($breakpoint-medium) {
          margin-top: rem(55);
          margin-bottom: rem(55);
        }

        @include until($breakpoint-small) {
          margin-top: rem(45);
          margin-bottom: rem(45);
        }
      }

      .cmp-title__text {
        padding-left: 0;
        padding-right: 0;
        color: $custom-container-title-white;
        font-weight: $custom-container-title-weight;
        font-size: $font-title-size-4;
        line-height: $font-title-height-4;

        @if $custom-container-title-uppercase {
          text-transform: uppercase;
        }

        @include from($breakpoint-large) {
          @include grid-item--5;
        }

        @include until($breakpoint-small) {
          font-size: $font-title-size-4-mobile;
          line-height: $font-title-height-4-mobile;
        }
      }

      .cmp-title__link {
        color: $custom-container-title-white;
      }

      &:first-child .cmp-title {
        position: relative;
        margin-bottom: rem(22);
        margin-top: 0;

        @include until($breakpoint-large) {
          margin-top: 0;
        }

        @include until($breakpoint-medium) {
          margin-top: 0;
        }

        @include until($breakpoint-small) {
          margin-top: 0;
          margin-bottom: rem(35);
        }
      }
    }

    > .image {

      .cmp-image {
        margin-top: 0;
      }
    }

    &.columncontainer--has-title {
      padding-top: rem(125);

      @include until($breakpoint-large) {
        padding-top: rem(115);
      }

      @include until($breakpoint-medium) {
        padding-top: rem(95);
      }

      @include until($breakpoint-small) {
        padding-top: rem(65);
      }
    }

    @include until($breakpoint-large) {
      grid-row-gap: rem(35);
      padding-top: rem(145);
      padding-bottom: rem(145);
    }

    @include until($breakpoint-medium) {
      grid-template-columns: repeat(2, 48.925%);
      grid-row-gap: rem(20);
      padding-top: rem(125);
      padding-bottom: rem(125);
    }

    @include until($breakpoint-small) {
      grid-template-columns: repeat(1, 100%);
      padding: rem(95) 5% rem(95);
    }
  }

  &.container--one-col {

    .cmp-container {
      grid-template-columns: repeat(1, 100%);
    }
  }

  &.container--two-col {

    .cmp-container {
      grid-template-columns: repeat(2, 48.925%);

      @include until($breakpoint-small) {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }

  &.container--three-col {

    .cmp-container {
      grid-template-columns: repeat(3, 31.9%);

      @include until($breakpoint-medium) {
        grid-template-columns: repeat(2, 48.925%);
      }

      @include until($breakpoint-small) {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }

  &.container--four-col {

    .cmp-container {
      grid-template-columns: repeat(4, 23.3875%);

      @include until($breakpoint-large) {
        grid-template-columns: repeat(3, 31.9%);
      }

      @include until($breakpoint-medium) {
        grid-template-columns: repeat(2, 48.925%);
      }

      @include until($breakpoint-small) {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }

  &.container--white {

    .cmp-container {
      background-color: $custom-container-background-white;

      > .title, > .cmp-container--title { // TODO: maybe .cmp-container--title, need wait for finalised component

        .cmp-title__text {
          color: $custom-container-title-white;
        }

        .cmp-title__link {
          color: $custom-container-title-white;
        }
      }
    }
  }

  &.container--gray {

    .cmp-container {
      background-color: $custom-container-background-gray;

      > .title, > .cmp-container--title { // TODO: maybe .cmp-container--title, need wait for finalised component

        .cmp-title__text {
          color: $custom-container-title-gray;
        }

        .cmp-title__link {
          color: $custom-container-title-gray;
        }
      }

      .text {
        color: $custom-container-text-gray;

        a {
          color: $custom-container-text-gray;
        }
      }
    }
  }

  &.container--gray-secondary {

    .cmp-container {
      background-color: $custom-container-background-gray-secondary;

      > .title, > .cmp-container--title { // TODO: maybe .cmp-container--title, need wait for finalised component

        .cmp-title__text {
          color: $custom-container-title-gray-secondary;
        }

        .cmp-title__link {
          color: $custom-container-title-gray-secondary;
        }
      }

      .text {
        color: $custom-container-text-gray-secondary;

        a {
          color: $custom-container-text-gray-secondary;
        }
      }
    }
  }

  &.container--quaternary {

    .cmp-container {
      background-color: $custom-container-background-quaternary;

        > .title, > .cmp-container--title { // TODO: maybe .cmp-container--title, need wait for finalised component

        .cmp-title__text {
          color: $custom-container-title-quaternary;
        }

        .cmp-title__link {
          color: $custom-container-title-quaternary;
        }
      }

      .text {
        color: $custom-container-text-quaternary;

        a {
          color: $custom-container-text-quaternary;
        }
      }
    }
  }

  &.container--for-text {

    .cmp-container {
      padding: rem(155) 6% rem(155);

      @include until($breakpoint-large) {
        padding-top: rem(125);
        padding-bottom: rem(125);
      }

      @include until($breakpoint-medium) {
        padding-top: rem(105);
        padding-bottom: rem(105);
      }

      @include until($breakpoint-small) {
        padding: rem(75) 5% rem(75);
      }

      &.columncontainer--has-title {
        padding-top: rem(115);

        @include until($breakpoint-large) {
          padding-top: rem(105);
        }

        @include until($breakpoint-medium) {
          padding-top: rem(85);
        }

        @include until($breakpoint-small) {
          padding-top: rem(55);
        }
      }

      > .title, > .cmp-container--title {

        &:first-child .cmp-title {
          margin-bottom: rem(17);

          .cmp-title__text {
            padding-left: 0;
            padding-right: 0;
            font-weight: $custom-container-title-weight;
            font-size: $font-title-size-4;
            line-height: $font-title-height-4;

            @if $custom-container-title-uppercase {
              text-transform: uppercase;
            }

            @include from($breakpoint-large) {
              @include grid-item--4;
            }

            @include until($breakpoint-small) {
              font-size: $font-title-size-4-mobile;
              line-height: $font-title-height-4-mobile;
            }
          }

          @include until($breakpoint-large) {
            margin-top: 0;
          }

          @include until($breakpoint-medium) {
            margin-top: 0;
          }

          @include until($breakpoint-small) {
            margin-top: 0;
            margin-bottom: rem(35);
          }
        }
      }

      .cmp-text {
        padding-left: 0;
        padding-right: 0;

        p, blockquote, ul, ol {
          padding-left: 0;
          padding-right: 0;
          width: 100%;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
