.teaser.teaser--facts-figures {

  .cmp-teaser {
    position: relative;
    padding-bottom: 59%;

    @include between($breakpoint-medium, $breakpoint-large) {
      padding-bottom: 90%;
    }

    @include between($breakpoint-small, $breakpoint-medium) {
      padding-bottom: 110%;
    }

    @include until($breakpoint-small) {
      padding-bottom: 120%;
    }

    @media screen and (max-width: 560px) {
      padding-bottom: 130%;
    }

    @media screen and (max-width: 480px) {
      padding-bottom: 168.7%;
    }
  }

  .cmp-teaser__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      opacity: 0.16;
    }

    & .cmp-image {
      margin: 0;
      height: 100%;
    }

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cmp-teaser__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: rem(120);
    left: 6%;
    z-index: 1;
    width: 88%;
    height: calc(100% - #{rem(120)});
    padding-top: 15.8%;
    padding-left: 37.5%;
    border-top: 1px solid $fact-figures-teaser-top-line-color;

    @media screen and (max-width: 1400px) {
      padding-top: 11%;
    }

    @media screen and (max-width: 1300px) {
      padding-top: 10%;
    }

    @include between($breakpoint-medium, $breakpoint-large) {
      padding-top: 15%;
    }

    @include between($breakpoint-small, $breakpoint-medium) {
      padding-top: 22%;
      padding-left: 26%;
      top: rem(80);
      height: calc(100% - #{rem(80)});
    }

    @include until($breakpoint-small) {
      padding-left: 10%;
      top: rem(47);
      padding-top: rem(30);
      height: calc(100% - #{rem(47)});
      justify-content: center;
    }
  }

  .cmp-teaser__pretitle {
    position: absolute;
    left: 0;
    top: rem(50);
    font-size: $font-title-size-4;
    line-height: $font-title-height-4;
    font-weight: $fact-figures-teaser-title-weight;
    margin: 0;
    color: $fact-figures-teaser-pretitle-color;

    @if $fact-figures-teaser-title-uppercase {
        text-transform: uppercase;
    }

    &::before {
      content: '';
      position: absolute;
      top: rem(-52);
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $fact-figures-teaser-highlighted-top-line-color;

      @include between($breakpoint-small, $breakpoint-medium) {
        top: rem(-37);
      }

      @include until($breakpoint-small) {
        top: rem(-22);
      }
    }

    @include between($breakpoint-small, $breakpoint-medium) {
      top: rem(35);
    }

    @include until($breakpoint-small) {
      top: rem(20);
      font-size: $font-title-size-4-mobile;
      line-height: $font-title-height-4-mobile;
      font-weight: $font-weight-semi-bold;
    }
  }

  .cmp-teaser__title {
    display: none;
  }

  .cmp-teaser__description {
    font-size: $font-title-size-0;
    line-height: $font-title-height-0;
    color: $fact-figures-teaser-description-color;
    font-weight: $fact-figures-teaser-description-weight;
    margin-bottom: rem(40);
    hyphens: auto;
    overflow-wrap: break-word;

    @if $fact-figures-teaser-description-uppercase {
      text-transform: uppercase;
    }

    p {
      font-size: $font-title-size-1;
      line-height: $font-title-height-1;
      margin-top: 0;
      margin-bottom: 0;

      @media screen and  (min-width: 1200px) and (max-width: 1350px) {
        font-size: $font-title-size-2;
        line-height: $font-title-height-2;
      }

      @include until($breakpoint-large) {
        font-size: $font-title-size-2;
        line-height: $font-title-height-2;
      }

      @include until($breakpoint-small) {
        font-size: $font-title-size-0-mobile;
        line-height: $font-title-size-0-mobile;
      }

      @media screen and (max-width: 360px) {
        font-size: $font-title-size-1-mobile;
        line-height: $font-title-size-1-mobile;
      }
    }

    @media screen and  (min-width: 1200px) and (max-width: 1350px) {
      font-size: $font-title-size-1;
      line-height: $font-title-height-1;
    }

    @include until($breakpoint-large) {
      font-size: $font-title-size-2;
      line-height: $font-title-height-2;
    }

    @include until($breakpoint-small) {
      font-size: $font-title-size-0-mobile;
      line-height: $font-title-size-0-mobile;
      margin-bottom: rem(30);
    }

    @media screen and (max-width: 360px) {
      font-size: $font-title-size-1-mobile;
      line-height: $font-title-size-1-mobile;
    }
  }

  .cmp-teaser__action-container {
    display: flex;
    flex-wrap: wrap;
  }

  .cmp-teaser__action-link {
    @extend .cmp-button;
    @include add_button_style($fact-figures-teaser-button-variant);
    margin: rem(10) 0 0 0 !important;

    &:not(:last-child) {
      margin-right: rem(20) !important;

      @include until($breakpoint-small) {
        margin-right: rem(20) !important;
      }
    }
  }
}
