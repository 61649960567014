.teaser.teaser--image {

  .cmp-teaser {
    position: relative;
    padding-bottom: 41.667%;

    @include until($breakpoint-small) {
      margin-left: 5%;
      margin-right: 5%;
      width: 90%;
      padding-bottom: 124.3%;
    }

    @include from($breakpoint-small) {
      cursor: none;
    }
  }

  .cmp-teaser__image {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(344.2deg, rgba(0, 0, 0, 0.49) 28.19%, rgba(0, 0, 0, 0) 71.14%);
    }

    & .cmp-image {
      margin: 0;
      height: 100%;
    }

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cmp-teaser__content {

    a {
      text-decoration: none;
    }

    @include between($breakpoint-small, $breakpoint-medium) {
      bottom: rem(40);
      left: rem(40);
      width: calc(100% - #{rem(80)});
    }

    @include until($breakpoint-small) {
      bottom: rem(20);
      left: rem(20);
      width: calc(100% - #{rem(40)});
    }
  }

  .cmp-teaser__pretitle {
    display: none;
  }

  .cmp-teaser__title {
    position: absolute;
    bottom: 13%;
    left: 6%;
    z-index: 2;
    width: 88%;
    color: $image-teaser-title-color;
    font-size: $font-title-size-3;
    line-height: $font-title-height-3;
    font-weight: $image-teaser-title-weight;
    hyphens: auto;
    overflow-wrap: break-word;

    @if $image-teaser-title-uppercase {
      text-transform: uppercase;
    }

    a {
      @include from($breakpoint-small) {
        cursor: none;
      }
    }

    @include until($breakpoint-small) {
      font-size: $font-title-size-3-mobile;
      line-height: $font-title-height-3-mobile;
      bottom: 18%;
    }

    @media screen and (max-width: 500px) {
      bottom: 22%;
    }
  }

  .cmp-teaser__title-link {
    color: $image-teaser-title-color;

    &:hover {
      color: $image-teaser-title-color;
      text-decoration: none;
    }
  }

  .cmp-teaser__description {
    display: none;
  }

  .cmp-teaser__action-container {
  }

  .cmp-teaser__action-link {
    @include add_button_base();
    @include add_button_style("primary-inverted");
    margin: 0 !important;
    z-index: 1;

    &:not(:first-child) {
      margin-left: $gap;
    }

    @include until($breakpoint-small) {
      position: absolute;
      left: 5%;
      bottom: 7.62%;
    }

    @include from($breakpoint-small) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent !important;
      border: none !important;
      border-radius: 0 !important;
      opacity: 0;
      cursor: none;

      &::after {
        display: none;
      }
    }
  }
}
