.cmp-header.cmp-header__overlay-lv--active {

  .cmp-header__navigation-overlay-lv {
    padding: rem(125) 6% rem(150);
    pointer-events: auto;
    height: rem(1200);
    opacity: 1;
    max-height: 100dvh;
    overflow-y: auto;

    @include until($breakpoint-medium) {
      padding: rem(30) 5% rem(40);
      height: rem(1600);
    }
  }

  .cmp-header__logo {
    &.nav-overlay-active {
      opacity: 0;
      pointer-events: none;
    }

    z-index: 13;
    pointer-events: none;
    padding-right: rem(15);

    >a>img {
      opacity: 0;
    }

    a::after {
      content: '';
      position: absolute;
      width: rem(140);
      height: rem(38);
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      z-index: 1;
      background-color: $white;
      @extend .logo-inverted;
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;

      @include until($breakpoint-medium) {
        width: rem(100);
        height: rem(27);
      }
    }
  }

  .cmp-header__generic-nav-more-link {
    background-color: $white;
  }

  .cmp-header__logo-text {
    opacity: 0;
  }

  .cmp-header__generic-nav-more {

    .z-window-content {
      background-color: $white;
    }
  }
}

.cmp-header__navigation-overlay-lv {
  display: block !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: $header-nav-overlay-lv-background;
  z-index: 12;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 0;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.33, 1, 0.68, 1);
  transition-property: opacity, max-height, padding;
  pointer-events: none;

  .cmp-list {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    gap: rem(28);
    margin-top: 0;
    margin-bottom: 0;

    @include until($breakpoint-medium) {
      gap: 0;
      margin-top: rem(20);
    }
  }

  .cmp-list__item {
    width: calc(25% - #{rem(21)});
    list-style-type: none;
    display: flex;
    flex-direction: column;
    margin-bottom: rem(-8);

    @include until($breakpoint-large) {
      width: calc(33.33% - #{rem(20)});
    }

    @include until($breakpoint-medium) {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .cmp-list__item-link {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    background-color: $header-nav-overlay-lv-item-background;
    border: 1px solid $header-nav-overlay-lv-item-background;
    color: $header-nav-overlay-lv-item-color;
    padding: rem(10) rem(23);
    border-radius: rem(50);
    text-decoration: none;
    align-items: center;
    flex-grow: 1;

    .cmp-list__item-name {
      display: block;
      width: rem(46);
      margin-right: rem(13);
      flex-shrink: 0;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include until($breakpoint-medium) {
        display: none;
        margin-right: rem(4);
        width: auto;

        &::after {
          content: ' -';
        }
      }
    }

    &:after {
      position: absolute;
      content: '';
      width: 1px;
      height: calc(100% - #{rem(20)});
      top: rem(10);
      left: rem(72);
      background-color: $header-nav-overlay-lv-item-line-color;

      @include until($breakpoint-medium) {
        display: none;
      }
    }

    &:hover {
      background-color: $header-nav-overlay-lv-background;
      color: $header-nav-overlay-lv-item-background;

      @include until($breakpoint-medium) {
        color: $header-nav-overlay-lv-item-mobile-color;
        background-color: transparent;
      }
    }

    @include until($breakpoint-medium) {
      padding: rem(16) 0;
      font-size: $font-size-1-mobile;
      line-height: $font-height-1-mobile;
      background-color: transparent;
      border: none;
      color: $header-nav-overlay-lv-item-mobile-color;
    }
  }

  .cmp-list__item-title {}

  &:not(.cmp-header__navigation-overlay-lv--active) {
    padding-top: 0 !important;
  }

  @include until($breakpoint-medium) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.cmp-header__generic-nav-overlay-lv-header {
  display: flex;
  position: relative;
  min-height: rem(40);
  align-items: center;
  margin-bottom: rem(70);

  @include until($breakpoint-medium) {
    margin-bottom: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: rem(140);
    align-items: flex-end;

    &::before {
      content: "";
      width: 100%;
      height: 5px;
      background: linear-gradient(#0000006e 0%, #0000 100%);
      position: absolute;
      left: 0;
      bottom: rem(-5);
    }
  }
}

.cmp-header__generic-nav-overlay-lv-title {
  color: $header-nav-overlay-lv-color;
  font-weight: $font-weight-regular;
  font-size: $font-title-size-7;
  line-height: $font-title-height-7;
  margin-right: rem(25);

  @include until($breakpoint-medium) {
    display: none;
  }
}

.cmp-header__generic-nav-overlay-lv-button {
  border-color: $header-nav-overlay-lv-button-color;
  color: $header-nav-overlay-lv-button-color;
  margin-top: 0;
  margin-bottom: 0;

  &:link {
    color: $header-nav-overlay-lv-button-color;
  }

  &::after {
    background-color: $header-nav-overlay-lv-button-color;
  }

  &:hover {
    border-color: $header-nav-overlay-lv-button-color;
    color: $header-nav-overlay-lv-background;
  }

  @include until($breakpoint-medium) {
    display: none;
  }
}

.cmp-header__generic-nav-overlay-lv-close {
  position: absolute;
  right: 0;
  top: calc(50% - #{rem(17)});
  width: rem(34);
  height: rem(34);
  @extend .icon-close-x;
  background-color: $header-nav-overlay-lv-close-icon-color;
  mask-repeat: no-repeat;
  mask-size: rem(24) rem(24);
  mask-position: center;
  cursor: pointer;
  transition: background-color 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  overflow: hidden;
  text-indent: 1000%;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: $header-nav-overlay-lv-close-icon-active-color;
  }

  &::after {
    content: '';
    width: rem(24);
    height: rem(24);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $header-main-mobile-menu-icon-shape-color;
    mask-repeat: no-repeat;
    mask-size: cover;
    transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    @extend .icon-close-x;
    opacity: 0;
  }

  @include until($breakpoint-medium) {
    position: absolute;
    right: 5%;
    top: 1.75rem;
    z-index: 12;
    width: rem(40);
    height: rem(40);
    border: none;
    background-color: $header-main-mobile-menu-icon-color;
    border-radius: 50%;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    mask: none;

    &::after {
      opacity: 1;
    }
  }
}

.cmp-header__generic-nav-overlay-lv-mobile-back {
  display: none;
  line-height: $font-date-height;
  color: $header-nav-overlay-lv-button-color;
  font-weight: $font-weight-semi-bold;
  letter-spacing: 1px;
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  align-items: center;
  cursor: pointer;
  padding: .75rem 5%;

  &::before {
    content: "";
    margin-top: 1px;
    width: rem(24);
    height: rem(24);
    background-color: $header-nav-overlay-lv-button-color;
    mask-repeat: no-repeat;
    mask-size: cover;
    transform: rotate(90deg);
    display: inline-block;
    @extend .icon-arrow-navi;
  }

    @include until($breakpoint-medium) {
      display: flex;
    }
}
