.v-title {
  color: $title-font-color;
}

.v-title--h1 {
  font-weight: $font-weight-light;
  font-size: $font-title-size-1;
  line-height: $font-title-height-1;
  font-family: titleFont, serif;
  margin: rem(40) 0;

  @include until($breakpoint-small) {
    font-size: $font-title-size-1-mobile;
    line-height: $font-title-height-1-mobile;
    margin: rem(20) 0;
  }
}

.v-title--h2 {
  font-weight: $font-weight-light;
  font-size: $font-title-size-2;
  line-height: $font-title-height-2;
  font-family: titleFont, serif;
  margin: rem(40) 0;

  @include until($breakpoint-small) {
    font-size: $font-title-size-2-mobile;
    line-height: $font-title-height-2-mobile;
    margin: rem(20) 0;
  }
}

.v-title--h3 {
  font-weight: $font-weight-regular;
  font-size: $font-title-size-3;
  line-height: $font-title-height-3;
  font-family: titleFont, serif;
  margin: rem(32) 0;

  @include until($breakpoint-small) {
    font-size: $font-title-size-3-mobile;
    line-height: $font-title-height-3-mobile;
    margin: rem(16) 0;
  }
}

.v-title--h4 {
  font-weight: $font-weight-semi-bold;
  font-size: $font-title-size-4;
  line-height: $font-title-height-4;
  font-family: titleFont, serif;
  margin: rem(32) 0;

  @include until($breakpoint-small) {
    font-size: $font-title-size-4-mobile;
    line-height: $font-title-height-4-mobile;
    margin: rem(16) 0;
  }
}

.news .v-title--h4 {
  font-weight: $font-weight-semi-bold;
  font-size: $font-title-size-5;
  line-height: $font-title-height-5;
  font-family: titleFont, serif;

  @include until($breakpoint-small) {
    font-size: $font-title-size-5-mobile;
    line-height: $font-title-height-5-mobile;
  }
}

.v-title--h5 {
  font-weight: $font-weight-regular;
  font-size: $font-title-size-5;
  line-height: $font-title-height-5;
  font-family: titleFont, serif;
  margin: rem(20) 0;

  @include until($breakpoint-small) {
    font-size: $font-title-size-5-mobile;
    line-height: $font-title-height-5-mobile;
    margin: rem(10) 0;
  }
}

.v-title--h6 {
  font-weight: $font-weight-regular;
  font-size: $font-title-size-6;
  line-height: $font-title-height-6;
  font-family: titleFont, serif;
  margin: rem(20) 0;

  @include until($breakpoint-small) {
    font-size: $font-title-size-6-mobile;
    line-height: $font-title-height-6-mobile;
    margin: rem(10) 0;
  }
}

.v-title--h7 {
  font-weight: $font-weight-semi-bold;
  font-size: $font-title-size-7;
  line-height: $font-title-height-7;
  font-family: titleFont, serif;
  margin: rem(20) 0;

  @include until($breakpoint-small) {
    font-size: $font-title-size-7-mobile;
    line-height: $font-title-height-7-mobile;
    margin: rem(10) 0;
  }
}

.v-title--h8 {
  font-weight: $font-weight-regular;
  font-size: $font-title-size-8;
  line-height: $font-title-height-8;
  font-family: titleFont, serif;
  margin: rem(20) 0;

  @include until($breakpoint-small) {
    font-size: $font-title-size-8-mobile;
    line-height: $font-title-height-8-mobile;
    margin: rem(10) 0;
  }
}