.carousel:not(.carousel--testimonial):not(.carousel--gallery):not(.carousel--facts-figures) {

  .cmp-image {
    margin-top: 0;
  }

  .cmp-carousel__prev, .cmp-carousel__next {
    display: none;
  }

  .cmp-carousel__pagination {
    position: absolute;
    width: 82%;
    height: rem(30);
    left: 6%;
    bottom: rem(75);
    display: flex;
    gap: rem(28);

    .cmp-carousel__pagination-item {
      flex-grow: 1;
      transition: opacity 0.6s cubic-bezier(0.33, 1, 0.68, 1);
      height: 100%;
      position: relative;
      z-index: 2;
      cursor:pointer;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $stage-carousel-pagination-color;
        top: rem(15);
        left: 0;
        z-index: 1;
        opacity: 0.3;

        @include until($breakpoint-medium) {
          top: rem(10);
        }
      }

      span {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        background-color: $stage-carousel-pagination-active-color;
        top: rem(14);
        left: 0;
        z-index: 2;

        @include until($breakpoint-medium) {
          top: rem(9);
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 7.32%;
      height: 1px;
      background-color: $stage-carousel-pagination-color;
      top: rem(15);
      right: 100%;
      z-index: 1;
      opacity: 0.3;

      @include until($breakpoint-medium) {
        top: rem(10);
      }

      @include until($breakpoint-small) {
        display: none;
      }
    }

    @include until($breakpoint-medium) {
      height: rem(20);
      bottom: rem(55);
      gap: rem(20);
    }

    @include until($breakpoint-small) {
      width: 60%;
      left: 20%;
      gap: rem(16);
      bottom: rem(20);
    }
  }

  .cmp-carousel__pause {
    position: absolute;
    right: 6%;
    bottom: rem(75);
    z-index: 2;
    width: rem(30);
    height: rem(30);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: $stage-carousel-icon-color;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;

    svg {
      --progress: 0;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      stroke-width: 2px;
      stroke: $stage-carousel-icon-color;
      fill: none;
      stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
      stroke-dasharray: 125.6;
      transform: rotate(-90deg);
    }

    span {
      position: absolute;
      width: rem(20);
      height: rem(20);
      mask-position: center;
      mask-repeat: no-repeat;
      background-color: $stage-carousel-icon-color;
      @extend .icon-stage-pause;
      left: rem(5);
      top: rem(5);
    }

    @include until($breakpoint-medium) {
      bottom: rem(45);
    }

    @include until($breakpoint-small) {
      bottom: rem(16);
      right: calc(20% - #{rem(46)});
    }
  }

  .cmp-carousel__pause--paused {

    span {
      @extend .icon-stage-play;
    }
  }

  .cmp-carousel__scroll-down {
    position: absolute;
    bottom: rem(17);
    left: 50%;
    width: rem(60);
    height: rem(60);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    @extend .icon-arrow-down-cool;
    background-color: $stage-carousel-icon-color;
    border: none;
    cursor: pointer;
    transform: translateX(-50%);
    z-index: 2;

    @include until($breakpoint-medium) {
      bottom: rem(10);
      width: rem(40);
      height: rem(40);
    }

    @include until($breakpoint-small) {
      display: none;
    }
  }
}

