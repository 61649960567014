$select-font-size:								$font-size-2;
$select-line-height:							$font-height-2;
$select-font-color:								$black;
$select-color-border:							$t-select-border-color;
$select-color-input:							$t-select-color-input;
$select-color-disabled:							$t-select-color-disabled;
$select-color-item:								$t-select-color-item;
$select-color-item-text:						$t-select-color-item-text;
$select-color-item-border:						transparent;
$select-color-item-active:						$t-select-color-item-active;
$select-color-item-active-text:					$t-select-color-item-active-text;
$select-color-item-active-border:				transparent;
$select-color-dropdown:							$t-select-color-dropdown;
$select-color-dropdown-border:					$t-select-color-dropdown;
$select-color-dropdown-border-top:				$t-select-color-dropdown;
$select-color-dropdown-item-active:				transparent;
$select-color-dropdown-item-active-text:		$t-select-color-dropdown-item-active-text;
$select-opacity-disabled:						1;
$select-shadow-input:							none;
$select-shadow-input-focus:						0px 8px 14px 0px rgba(0,0,0,0.1);
$select-border-width:							1px;
$select-border-radius:							rem(24);
$select-max-height-dropdown:					18.75rem;
$select-padding-x:								1.25rem;
$select-padding-y:								0.6875rem;
$select-padding-item-x:							1.25rem;
$select-padding-item-y:							0.6875rem;
$select-padding-dropdown-item-x:				1.25rem;
$select-padding-dropdown-item-y:				0.6875rem;
$select-margin-item-x:							0;
$select-margin-item-y:							0;

@import 'node_modules/tom-select/dist/scss/tom-select';

.ts-wrapper {
  z-index: 10;

  &.dropdown-active {
    position: relative;
    z-index: 30;
  }
}

.ts-wrapper--hidden {
  display: none;
}

.ts-control {
  min-width: rem(215);
  min-height: rem(48);
  cursor: pointer;

  &:focus {
    outline: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: rem(24);
      border: 1px dashed $black;

      @include until($breakpoint-small) {
        border-radius: rem(20);
      }
    }
  }

  .dropdown-active & {
    border-color: $t-select-active-bolder-color;

    &::before {
      opacity: 0 !important;
    }

    &::after {
      @extend .icon-arrow-simple-up;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: rem(20);
    transform: translateY(-50%);
    width: rem(24);
    height: rem(24);
    background-color: $t-select-icon-color;
    @extend .icon-arrow-simple-down;

    @include until($breakpoint-small) {
        right: rem(15);
        width: rem(20);
        height: rem(20);
        mask-size: 100%;
    }
  }

  @include until($breakpoint-small) {
    min-width: rem(180);
    min-height: rem(40);
    border-radius: rem(20);
  }
}

.ts-dropdown {
  border-radius: 0 rem(6) rem(6) 0;
  padding: rem(38) rem(10) rem(18) 0;
  z-index: 0;
  top: calc(100% - #{rem(29)});

  .option {
    color: $t-select-option-color;
    position: relative;
    padding-right: rem(50);

    &.selected, &.active {
      color: $t-select-active-option-color;
    }

    &.active {
      text-decoration: underline;
    }

    &.selected {

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: rem(10);
        transform: translateY(-50%);
        width: rem(24);
        height: rem(24);
        background-color: $t-select-active-option-icon-color;
        @extend .icon-checkmark;

        @include until($breakpoint-small) {
          right: rem(8);
          width: rem(20);
          height: rem(20);
          mask-size: 100%;
        }
      }
    }

    @include until($breakpoint-small) {
      font-size: $font-button-mobile;
      line-height: $font-button-height-mobile;
      padding-top: rem(9);
      padding-bottom: rem(9);
      padding-right: rem(36);
    }
  }

  @include until($breakpoint-small) {
    padding: rem(34) rem(10) rem(14) 0;
  }
}

.tomselect-checkbox {
  display: none;
}

.ts-item-hidden {
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
  margin: 0 !important;
  padding: 0 !important;
}

.ts-custom-label {
  padding: $select-padding-y rem(46) $select-padding-y $select-padding-x;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  pointer-events: none;
  border: 1px solid transparent;

  @include until($breakpoint-small) {
    font-size: $font-button-mobile;
    line-height: $font-button-height-mobile;
    padding-top: rem(9);
    padding-bottom: rem(9);
    padding-right: rem(36);
  }
}

.ts-custom-save-label {
  padding: $select-padding-y rem(46) $select-padding-y $select-padding-x;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 4;
  opacity: 0;
  pointer-events: none;
  background-color: $t-select-save-label-background-color;
  border: 1px solid $t-select-save-label-background-color;
  border-radius: $select-border-radius;
  color: $t-select-save-label-color;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: rem(20);
    transform: translateY(-50%);
    width: rem(24);
    height: rem(24);
    background-color: $t-select-save-label-icon-color;
    @extend .icon-download;

    @include until($breakpoint-small) {
      right: rem(15);
      width: rem(20);
      height: rem(20);
      mask-size: 100%;
    }
  }

  @include until($breakpoint-small) {
    font-size: $font-button-mobile;
    line-height: $font-button-height-mobile;
    padding-top: rem(9);
    padding-bottom: rem(9);
    padding-right: rem(36);
  }
}

.ts-custom-save-label--active {
  opacity: 0;

  .ts-wrapper--touched & {
    opacity: 1;
  }
}

.ts-wrapper--touched:not(.ts-wrapper--save-label-active) {

  .ts-control {
    border-color: $t-select-touched-background-color;
    background-color: $t-select-touched-background-color;

    &::after {
      background-color: $t-select-touched-icon-color;
    }
  }

  .ts-custom-label {
    color: $t-select-touched-color;
  }
}
