body.overview-page {

  .root {

    > .cmp-container {

      > .container--overview-first {

        > .cmp-container {
          position: relative;

          > .aem-Grid {

            // Breadcrumbs
            > .breadcrumb {

              .cmp-breadcrumb {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
              }
            }

            // Main image
            > .image {

              .cmp-image {
                margin-top: 0;
                margin-bottom: 0;
                position: relative;
                padding-bottom: 41.667%;

                img {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }

                .cmp-image__copyright {
                  left: auto;
                  right: 0;

                  @include until($breakpoint-small) {
                    bottom: auto;
                    top: 0;
                  }
                }

                @include until($breakpoint-small) {
                  padding-bottom: 64%;
                }
              }
            }

            // Main sublinks
            > .list.list--text {

              &.aem-GridColumn--default--3 {
                width: 13% !important;
                margin-right: 6% !important;
                margin-left: 0 !important;
                float: right !important;

                @include between($breakpoint-medium, $breakpoint-large) {
                  width: 15% !important;
                }

                @include between($breakpoint-small, $breakpoint-medium) {
                  width: 18% !important;
                }

                @include until($breakpoint-small) {
                  width: 45% !important;
                  margin-right: 5% !important;
                }

                @media screen and (max-width: 400px) {
                  width: 90% !important;
                  margin-right: 5% !important;
                }
              }

              .cmp-list {
                position: relative;

                &::before {
                  content: '';
                  position: absolute;
                  top: rem(-82);
                  left: rem(-8);
                  width: rem(44);
                  height: rem(44);
                  @extend .icon-arrow-down-cool;
                  mask-size: 100%;
                  mask-repeat: no-repeat;
                  mask-position: center;
                  background-color: $white;

                  @include until($breakpoint-small) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      > .container--overview-second {

        > .cmp-container > .aem-Grid > .container > .cmp-container {

          // Main text
          .columncontainer {

            .cmp-container {
              padding-top: rem(80);
              padding-bottom: rem(160);

              @include between($breakpoint-small, $breakpoint-medium) {
                padding-top: rem(60);
                padding-bottom: rem(110);
              }

              @include until($breakpoint-small) {
                padding-top: rem(40);
                padding-bottom: rem(80);
              }

              > .title {
                grid-column: auto;

                .cmp-title {
                  margin-bottom: 0;
                }

                .cmp-title__text {
                  width: 47.75%;

                  @include between($breakpoint-medium, $breakpoint-large) {
                    width: 66%;
                  }

                  @include between($breakpoint-small, $breakpoint-medium) {
                    width: 80%;
                  }

                  @include until($breakpoint-small) {
                    width: 80%;
                  }

                  @media screen and (max-width: 500px) {
                    width: 100%;
                  }
                }
              }

              > .text {

                .cmp-text {
                  padding-left: 0;
                  padding-right: 0;

                  p, blocquote, ul, ol {
                    padding-left: 0;
                    padding-right: 0;
                    width: 100%;

                    &:first-child {
                      margin-top: 0;
                    }

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }

          // Testimonials
          .carousel--testimonial {

          }
        }
      }
    }
  }
}
