.carousel.carousel--facts-figures {

  .cmp-carousel__prev, .cmp-carousel__next {
    display: none;
  }

  .cmp-carousel__pagination {
    position: absolute;
    width: 30%;
    left: 6%;
    top: rem(137);
    padding-top: 15.8%;
    display: flex;
    flex-direction: column;
    gap: rem(58);

    .cmp-carousel__pagination-item {
      flex-grow: 1;
      transition: opacity 0.6s cubic-bezier(0.33, 1, 0.68, 1);
      position: relative;
      z-index: 2;
      cursor:pointer;
      padding-left: rem(16);
      min-height: rem(54);

      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: $fact-figures-carousel-line-color;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0.5;

        @include until($breakpoint-medium) {
          top: rem(10);
        }

        @include until($breakpoint-small) {
          height: 2px;
          width: 100%;
          top: 0;
        }
      }

      @include until($breakpoint-small) {
        width: rem(48);
        height: rem(52);
        text-align: left;
        padding: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
      }
    }

    @media screen and (max-width: 1400px) {
      padding-top: 11%;
      gap: rem(50);
    }

    @media screen and (max-width: 1300px) {
      padding-top: 10%;
    }

    @include between($breakpoint-medium, $breakpoint-large) {
      padding-top: 15%;
    }

    @include between($breakpoint-small, $breakpoint-medium) {
      padding-top: 22%;
      top: rem(97);
      gap: rem(38);
    }

    @include until($breakpoint-small) {
      width: 90%;
      left: 5%;
      top: auto;
      bottom: rem(15);
      flex-direction: row;
      justify-content: center;
      gap: rem(16);
    }
  }

  .cmp-carousel__pagination-line-vertical {
    content: '';
    position: absolute;
    width: 2px;
    height: 0;
    background-color: $fact-figures-carousel-active-line-color;
    top: 0;
    left: 0;
    z-index: 2;

    @include until($breakpoint-small) {
      display: none;
    }
  }

  .cmp-carousel__pagination-line-horizontal {
    content: '';
    display: none;
    position: absolute;
    width: 0;
    height: 2px;
    background-color: $fact-figures-carousel-active-line-color;
    top: 0;
    left: 0;
    z-index: 2;

    @include until($breakpoint-small) {
      display: block;
    }
  }

  .cmp-carousel__pagination-item-title {
    font-size: $font-size-2;
    line-height: $font-height-2;
    font-weight: $font-weight-semi-bold;
    color: $fact-figures-carousel-text-color;
    opacity: 0.5;

    span {
      display: block;

      @include until($breakpoint-small) {
        display: none;
      }
    }
  }

  .swiper-pagination-bullet-active {
    .cmp-carousel__pagination-item-title {
      opacity: 1;
    }
  }
}
