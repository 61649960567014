@import './src/default/components/button/_button_factory.scss';

.v-button {
  @include add_button_base();
  @include add_button_style("primary");
}

.v-button--size-medium {

}

.v-button--icon-right, .v-button--icon-left {

  .v-button__icon {
    content: "";
    display: inline-block;
    width: 1.375rem;
    height: 1.375rem;
    margin: -0.0625rem 0.625rem -0.0625rem -0.5625rem;
    background-color: map-get($buttons, primary, font-color);
    transition: background-color 0.2s ease-in-out;
    position: relative;
    z-index: 1;
    mask-size: 100%;
    mask-repeat: no-repeat;

    @include from($breakpoint-small) {
      width: 1.75rem;
      height: 1.75rem;
      margin: -0.3125rem 0.75rem -0.3125rem -0.625rem;
    }
  }

  &.v-button--medium .v-button__icon {

    @include from($breakpoint-small) {
      width: 1.375rem;
      height: 1.375rem;
      margin: -0.0625rem 0.625rem -0.0625rem -0.5625rem;
    }
  }
}

.v-button--icon-right {
  display: flex;
  flex-direction: row-reverse;

  .v-button__icon {
    margin: -0.0625rem -0.5625rem -0.0625rem 0.625rem;

    @include from($breakpoint-small) {
      margin: -0.3125rem -0.625rem -0.3125rem 0.75rem;
    }
  }
}

.v-button--icon-arrow-down {

  .v-button__icon {
    @extend .icon-arrow-down;
  }
}

.v-button--primary {
  @include add_button_style("primary");
}

.v-button--primary-inverted {
  @include add_button_style("primary-inverted");
}

.v-button--secondary {
  @include add_button_style("secondary");
}

.v-button--secondary-inverted {
  @include add_button_style("secondary-inverted");
}
