.global-cursor {
  width: rem(94);
  height: rem(94);
  border-radius: 50%;
  color: $cursor-color;
  background-color: $cursor-background;
  opacity: 0;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: width .3s, height .3s, opacity .3s;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(10);
  font-size: $font-size-2;
  line-height: 1.125;
  text-align: center;
  font-weight: $font-weight-semi-bold;

  &.active {
    opacity: 1;
  }

  &.pressed {
    color: $cursor-active-color;
    background-color: $cursor-active-background;
  }

  @include until($breakpoint-small) {
    display: none;
  }
}
