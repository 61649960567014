//== Core

@import 'site/mixins';
@import 'site/grid';
@import 'site/inner-grid';

//== Site global

@import 'npm:normalize.css';
@import 'site/fonts';
@import 'site/base';
@import 'site/icons';
@import 'site/form';
@import 'site/global-cursor';

//== Visual components

@import './visual/v-title';
@import './visual/v-filter-tags';
@import './visual/v-button';
@import './visual/v-switch';
@import './visual/v-alert';
@import './visual/v-tooltip';

//== Core components

@import './components/text/text.scss';

@import './components/title/title.scss';
@import './components/title/title_styles.scss';

@import './components/image/image.scss';
@import './components/image/image_caption.scss';
@import './components/image/image_standard.scss';

@import './components/button/button_factory.scss';
@import './components/button/button.scss';

@import './components/separator/separator.scss';
@import './components/separator/separator_small.scss';
@import './components/separator/separator_standard.scss';

@import './components/progressbar/progressbar.scss';

@import './components/teaser/basic-teaser.scss';
@import './components/teaser/image-teaser.scss';
@import './components/teaser/link-list-teaser.scss';
@import './components/teaser/testimonial-teaser.scss';
@import './components/teaser/stage-teaser.scss';
@import './components/teaser/topic-teaser.scss';
@import './components/teaser/cta-teaser.scss';
@import './components/teaser/facts-figures.scss';

@import './components/video/video.scss';
@import './components/stage-video/stage-video.scss'; // dynamicmedia

@import './components/download/download.scss';
@import './components/ad/ad.scss';
@import './components/link/link.scss';
@import './components/back-to-top-button/back-to-top.scss';

//== Lists and Navigation components

@import './components/list/list.scss';

@import './components/navigation/navigation.scss';

@import './components/languagenavigation/languagenavigation.scss';

@import './components/breadcrumb/breadcrumb.scss';

@import './components/search/search.scss';

//== Container components

@import './components/container/container.scss';

@import './components/carousel/testimonial-carousel.scss';
@import './components/carousel/gallery-carousel.scss';
@import './components/carousel/stage-carousel.scss';
@import './components/carousel/facts-figures-carousel.scss';

@import './components/tabs/tabs.scss';
@import './components/tabs/tabs_horizontal.scss';
@import './components/tabs/tabs_vertical.scss';

@import './components/accordion/accordion.scss';

//== Fragments (CF + XF) components

@import './components/experiencefragment/experiencefragment_footer.scss';
@import './components/experiencefragment/experiencefragment_partner.scss';
@import './components/experiencefragment/experiencefragment_logo-wall.scss';

//== Form components

@import './components/form/form.scss';
@import './components/form-button/form-button.scss';
@import './components/form-text/form-text.scss';
@import './components/form-options/form-options.scss';

//== Embed components

@import './components/embed/embed.scss';

@import './components/pdfviewer/pdfviewer.scss';

@import './components/socialmediasharing/social_media_sharing.scss';

//== Custom components

@import './components/top-news/top-news.scss';
@import './components/top-article/top-article.scss';
@import './components/people-teaser/people-teaser.scss';
@import './components/intro-text/intro-text.scss';
@import './components/header/header.scss';
@import './components/header/header-generic-nav.scss';
@import './components/header/header-main-nav.scss';
@import './components/header/header-auth.scss';
@import './components/header/header-search.scss';
@import './components/header/header-overlay-lv.scss';
@import './components/search-results/search-results.scss';
@import './components/columncontainer/columncontainer.scss';
@import './components/social-sharing/social-sharing.scss';
@import './components/register-teaser/register-teaser.scss';
@import './components/newsletter-teaser/newsletter-teaser.scss';
@import './components/presenter/presenter.scss';

//== Templates

@import './templates/article-template/article-template.scss';
@import './templates/overview-template/overview-template.scss';

//== Libraries

@import './components/tom-select/tom-select.scss';
@import "node_modules/swiper/swiper.scss";
