.introtext {

  .cmp-text {
    color: $intro-text-color;
    font-size: $font-title-size-1;
    line-height: $font-title-height-1;
    font-weight: $intro-text-weight;
    padding-top: rem(50);
    padding-bottom: rem(130);
    //min-height: 100vh;
    display: flex;
    align-items: center;
    hyphens: auto;

    @if $intro-text-uppercase {
      text-transform: uppercase;
    }

    p, strong {
      font-size: 3.6rem;
      line-height: 1.2;

      @include until($breakpoint-small) {
        font-size: $font-title-size-1-mobile;
        line-height: $font-title-height-1-mobile;
      }
    }

    p {
      font-weight: $intro-text-weight;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    strong {
      color: $intro-text-strong-color;
      font-weight: $intro-text-strong-weight;
    }

    @include until($breakpoint-small) {
      font-size: $font-title-size-1-mobile;
      line-height: $font-title-height-1-mobile;
      padding-top: rem(50);
      padding-bottom: rem(80);
    }
  }

  // Huge text
  &.introtext--primary, &.introtext--primary-inverted {

    .cmp-text {
      width: 88%;
      margin: rem(80) auto;
      padding: rem(170) 0;
      background-color: $intro-text-huge-text-background;
      color: $intro-text-huge-text-color;
      font-size: $font-title-size-1;
      line-height: $font-title-height-1;
      font-weight: $intro-text-weight;
      justify-content: center;

      p, strong {
        color: $intro-text-huge-text-color;
        font-size: $font-title-size-1;
        line-height: $font-title-height-1;
        font-weight: $intro-text-weight;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        @include until($breakpoint-small) {
          font-size: $font-title-size-1-mobile;
          line-height: $font-title-height-1-mobile;
          padding-left: 0;
          padding-right: 0;
        }
      }

      strong {
        font-weight: $intro-text-strong-weight;
      }

      @include until($breakpoint-medium) {
        margin: rem(40) auto;
        padding: rem(90) 0;
      }

      @include until($breakpoint-small) {
        width: 90%;
        margin: rem(20) auto;
        padding: rem(50) rem(30);
        font-size: $font-title-size-1-mobile;
        line-height: $font-title-height-1-mobile;
      }
    }
  }

  &.introtext--primary {

  }

  &.introtext--primary-inverted {

    .cmp-text {
      background-color: $intro-text-huge-text-inverted-background;
      color: $intro-text-huge-text-inverted-color;

      p, strong {
        color: $intro-text-huge-text-inverted-color;
      }
    }
  }
}
