.button {
  padding: 0 $content-offset-mobile;

  @include from($breakpoint-small) {
    padding: 0 $content-offset;
  }
}

.cmp-button {
  margin-left: $grid-half-gap;
  margin-right: $grid-half-gap;
  display: flex;
  width: fit-content;
  height: fit-content;
  margin-block: $button-margin-medium;
  padding: $button-padding-medium;
  border-style: solid;
  border-width: 0;
  border-radius: $button-border-radius;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  font-size: $button-font-size-medium;
  font-weight: $button-font-weight;
  line-height: $button-font-height-medium;
  min-height: $button-min-height-medium;
  // default primary style
  background-color: map-get($buttons, primary, bg-color); //map-get($buttons, primary, bg-color));
  border-color: map-get($buttons, primary, border-color);
  color: map-get($buttons, primary, font-color);
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: map-get($buttons, primary, hover-bg-color);
    transition: height 0.15s ease-in-out;
  }

  &:hover {
    border-color: map-get($buttons, primary, hover-border-color);
    color: map-get($buttons, primary, hover-font-color);

    &::after {
      height: 100%;
    }
  }

  &:active {
    border-color: map-get($buttons, primary, hover-border-color);
    color: map-get($buttons, primary, active-font-color);
    transform: scale(0.962);

    &::after {
      height: 100%;
    }
  }

  &:focus {
    outline: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $button-border-radius;
      border: 1px dashed $button-outline;
    }
  }

  &:disabled {
    background-color: map-get($buttons, primary, disabled-bg-color);
    border-color: map-get($buttons, primary, disabled-border-color);
    color: map-get($buttons, primary, disabled-font-color);
    cursor: default;

    &::after {
      height: 0;
    }
  }

  span:not(.cmp-link__screen-reader-only) {
    position: relative;
    z-index: 1;
  }

  .button--secondary & {
    border-width: 1px;
    padding: $button-padding-bordered-medium;

    &:focus {
      border-color: $button-outline;
      border-style: dashed;
      outline: none;

      &::before {
        content: none;
      }
    }

    @include from($breakpoint-small) {
      padding: $button-padding-bordered;
    }
  }

  .button--icon-right & {
    flex-direction: row-reverse;
  }

  .button--icon-only & {
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    @include from($breakpoint-small) {
      padding-left: 1.625rem;
      padding-right: 1.625rem;
    }
  }

  .button--icon-only.button--secondary & {
    padding-left: 1.1875rem;
    padding-right: 1.1875rem;

    @include from($breakpoint-small) {
      padding-left: 1.5625rem;
      padding-right: 1.5625rem;
    }
  }

  .button--size-medium.button--icon-only & {

    @include from($breakpoint-small) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }

  .button--size-medium.button--icon-only.button--secondary & {

    @include from($breakpoint-small) {
      padding-left: 1.1875rem;
      padding-right: 1.1875rem;
    }
  }

  @include from($breakpoint-small) {
    padding: $button-padding;
    font-size: $button-font-size;
    line-height: $button-font-height;
    min-height: $button-min-height;
    margin-block: $button-margin;
  }

  .button--size-medium & {

    @include from($breakpoint-small) {
      padding: $button-padding-medium;
      font-size: $button-font-size-medium;
      font-weight: $button-font-weight;
      line-height: $button-font-height-medium;
      min-height: $button-min-height-medium;
    }
  }

  .button--size-medium.button--secondary & {

    @include from($breakpoint-small) {
      padding: $button-padding-bordered-medium;
    }
  }
}

.cmp-button__text {
  display: inline-block;
  font-weight: $font-weight-semi-bold;
  font-size: $button-font-size-medium;
  line-height: $button-font-height-medium;
  text-decoration: none;
  // default primary style
  color: map-get($buttons, primary, font-color);
  position: relative;
  z-index: 1;

  .cmp-button:hover & {
    color: map-get($buttons, primary, hover-font-color);
  }

  .cmp-button:active & {
    color: map-get($buttons, primary, active-font-color);
  }

  .cmp-button:disabled & {
    color: map-get($buttons, primary, disabled-font-color);
  }

  .button--icon-only & {
    display: none;
  }

  @include from($breakpoint-small) {
    font-size: $font-button;
    line-height: $font-button-height;

    .button--size-medium & {
      font-size: $button-font-size-medium;
      line-height: $button-font-height-medium;
    }
  }
}

.cmp-button__icon {
  display: inline-block;
  width: 1.375rem;
  height: 1.375rem;
  margin: -0.0625rem 0.625rem -0.0625rem -0.5625rem;
  background-color: map-get($buttons, primary, icon-color);
  transition: background-color 0.2s ease-in-out;
  position: relative;
  z-index: 1;
  mask-size: 100%;
  mask-repeat: no-repeat;

  .cmp-button:hover & {
    background-color: map-get($buttons, primary, hover-font-color);
  }

  .cmp-button:active & {
    background-color: map-get($buttons, primary, active-font-color);
  }

  .cmp-button:disabled & {
    background-color: map-get($buttons, primary, disabled-font-color);
  }

  .button--icon-right & {
    margin: -0.0625rem -0.5625rem -0.0625rem 0.625rem;

    @include from($breakpoint-small) {
      margin: -0.3125rem -0.625rem -0.3125rem 0.75rem;
    }
  }

  .button--icon-only & {
    margin: -0.0625rem -0.6875rem;

    @include from($breakpoint-small) {
      margin: -0.3125rem -0.875rem;
    }
  }

  .button--size-medium.button--icon-right & {

    @include from($breakpoint-small) {
      margin: -0.0625rem -0.5625rem -0.0625rem 0.625rem;
    }
  }

  .button--size-medium.button--icon-only & {

    @include from($breakpoint-small) {
      margin: -0.0625rem -0.6875rem;
    }
  }

  @include from($breakpoint-small) {
    width: 1.75rem;
    height: 1.75rem;
    margin: -0.3125rem 0.75rem -0.3125rem -0.625rem;

    .button--size-medium & {
      width: 1.375rem;
      height: 1.375rem;
      margin: -0.0625rem 0.625rem -0.0625rem -0.5625rem;
    }
  }
}
