.v-alert {
  color: $alert-font-color;
  background-color: $alert-background;
  border: $alert-border;
  border-radius: $alert-border-radius;
  font-size: $alert-font-size;
  line-height: $alert-font-height;
  padding: $alert-padding;
  margin: $alert-margin;
}

.v-alert--success {
  color: $alert-font-color-success;
  background-color: $alert-background-success;
  border: $alert-border-success;
}

.v-alert--error {
  color: $alert-font-color-error;
  background-color: $alert-background-error;
  border: $alert-border-error;
}
