.dynamicmedia:not(.dynamicmedia--stage) {

  .video {

    &.s7mouseinput, &.s7touchinput {

      .s7iconeffect {
        background-image: none;

        &::before {
          content: '';
          width: rem(60);
          height: rem(60);
          background-color: $video-controls-color;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          mask-position: center;
          mask-size: contain;
          mask-repeat: no-repeat;
          @extend .icon-video-play;
        }
      }

      .s7controlbar {
        background-image: none;
        min-height: rem(80);
      }

      .s7playpausebutton {
        display: none;
      }

      .s7videoscrubber {
        top: calc(50% - #{rem(2)});
        left: 6%;
        transform: translateY(-50%);
        width: calc(88% - #{rem(184)}) !important;

        @include until($breakpoint-small) {
          left: 5%;
          width: calc(90% - #{rem(184)}) !important;
        }
      }

      .s7track {
        background-color: transparent;

        &::before {
          content: '';
          width: 100%;
          height: 1px;
          background-color: $video-controls-color;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.3;
        }
      }

      .s7trackloaded {
        background-color: transparent;

        &::before {
          content: '';
          width: 100%;
          height: 1px;
          background-color: $video-controls-color;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.6;
        }
      }

      .s7trackplayed {
        background-color: transparent;

        &::before {
          content: '';
          width: 100%;
          height: 2px;
          background-color: $video-controls-color;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .s7knob {
        background-image: none;
        background-color: transparent;

        &::before {
          content: '';
          width: rem(2);
          height: rem(14);
          background-color: $video-controls-color;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .s7videotime {
        font-family: defaultFont, serif;
        font-size: $font-button;
        line-height: $font-button-height;
        color: $video-controls-color;
        padding: 0;
        display: flex;
        align-items: center;
        min-width: rem(80);
        right: calc(6% + #{rem(98)}) !important;
        top: 50%;
        transform: translateY(-50%);
        background-image: none;

        div {
          width: 100%;
          text-align: center;
        }

        @include until($breakpoint-small) {
          right: calc(5% + #{rem(98)}) !important;
        }
      }

      .s7fullscreenbutton {
        right: 6%;
        top: 50%;
        transform: translateY(-50%);

        @include until($breakpoint-small) {
          right: 5%;
        }
      }

      .s7fullscreenbutton[state][selected] {
        background-image: none;
        width: rem(33);
        height: rem(33);

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $video-controls-color;
          mask-repeat: no-repeat;
          mask-size: 100%;
          transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
          @extend .icon-full;
        }
      }

      .s7fullscreenbutton[selected='true'][state='up'],
      .s7fullscreenbutton[selected='true'][state='over'],
      .s7fullscreenbutton[selected='true'][state='down'],
      .s7fullscreenbutton[selected='true'][state='disabled'] {

        &::before {
          @extend .icon-in;
        }
      }

      .s7fullscreenbutton[selected='true'][state='over'],
      .s7fullscreenbutton[selected='true'][state='down'],
      .s7fullscreenbutton[selected='false'][state='over'],
      .s7fullscreenbutton[selected='false'][state='down'] {

        &::before {
          background-color: $video-controls-active-color;
        }
      }

      .s7fullscreenbutton[selected='true'][state='disabled'],
      .s7fullscreenbutton[selected='false'][state='disabled'] {

        &::before {
          opacity: 0.3;
        }
      }

      .s7mutablevolume {
        right: calc(6% + #{rem(51)}) !important;
        top: 50%;
        transform: translateY(-50%);
        width: rem(33);
        height: rem(33);

        &:focus {
          outline: none;
        }

        @include until($breakpoint-small) {
          right: calc(5% + #{rem(51)}) !important;
        }
      }

      .s7mutebutton {
        background-image: none !important;
        width: rem(33) !important;
        height: rem(33) !important;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $video-controls-color;
          mask-repeat: no-repeat;
          mask-size: 100%;
          transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
          @extend .icon-sound-max;
        }
      }

      .s7mutebutton[selected='true'][state='up'],
      .s7mutebutton[selected='true'][state='over'],
      .s7mutebutton[selected='true'][state='down'],
      .s7mutebutton[selected='true'][state='disabled'] {

        &::before {
          @extend .icon-sound-mute;
        }
      }

      .s7mutebutton[selected='true'][state='over'],
      .s7mutebutton[selected='true'][state='down'],
      .s7mutebutton[selected='false'][state='over'],
      .s7mutebutton[selected='false'][state='down'] {

        &::before {
          background-color: $video-controls-active-color;
        }
      }

      .s7mutebutton[selected='true'][state='disabled'],
      .s7mutebutton[selected='false'][state='disabled'] {

        &::before {
          opacity: 0.3;
        }
      }


      .s7verticalvolume {
        background-color: transparent;

        .s7track {
          background-color: transparent;

          &::before {
            content: '';
            width: 1px;
            height: 100%;
            background-color: $video-controls-color;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, 0);
            opacity: 0.3;
          }
        }

        .s7filledtrack {
          background-color: transparent;

          &::before {
            content: '';
            width: rem(2);
            height: 100%;
            background-color: $video-controls-color;
            position: absolute;
            left: rem(3);
            top: 0;
          }
        }

        .s7knob {
          width: rem(13);
          height: rem(13);

          &::before {
            content: '';
            width: rem(13);
            height: rem(2);
            background-color: $video-controls-color;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .s7tooltip {
        background-color: $video-controls-color;
        color: $video-text-color;
        font-family: defaultFont, serif;
        font-size: rem(12);
        border: 1px solid $video-border-color;
        border-radius: 0;
      }
    }

    &.s7mouseinput {

      .s7container:not([mode='fullscreen']) {

        .s7videoplayer {
          cursor: none;
        }
      }

      .s7iconeffect {
        display: none;
      }
    }

    &.volume-control-hidden {

      .s7videotime {
        right: calc(6% + #{rem(47)}) !important;

        @include until($breakpoint-small) {
          right: calc(5% + #{rem(47)}) !important;
        }
      }

      .s7videoscrubber {
        width: calc(88% - #{rem(133)}) !important;

        @include until($breakpoint-small) {
          width: calc(90% - #{rem(133)}) !important;
        }
      }
    }
  }
}
