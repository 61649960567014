.cmp-header__main-nav {
  padding-top: rem(37);
  padding-bottom: rem(39);
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  @include until($breakpoint-large) {
    padding-top: rem(26);
    padding-bottom: rem(36);
  }

  @include until($breakpoint-medium) {
    max-height: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    padding-top: 0;
    padding-bottom: 0;
    background-color: $header-background;
    z-index: 12;
    transition: max-height 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    transition-property: height, max-height, opacity, padding-top;
  }

  &>.cmp-header__main-nav-mobile-close {
    display: none;
  }
}

.cmp-header__main-nav--hidden {
  opacity: 0;
  pointer-events: none;
}

.cmp-header__main-nav--active {
  @include until($breakpoint-medium) {
    max-height: rem(1000);

    .cmp-header__main-nav-list::before {
      width: 91.87%;
    }
  }

  @include until(500px) {

    .cmp-header__main-nav-list::before {
      width: 88%;
    }
  }
}

.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group {
  display: flex;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  @include until($breakpoint-medium) {
    box-shadow: 0px 2px 4px 0px #00000040;
    flex-direction: column;
    align-items: center;
    padding: 0 5%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: rem(16);
      left: 0;
      width: 0;
      height: 1px;
      background-color: $header-main-border-color;
      transition: width 0.8s cubic-bezier(0.33, 1, 0.68, 1);
      z-index: 10;
    }
  }
}

.cmp-header__main-nav .navigation-main .cmp-navigation__item {
  list-style-type: none;

  @include until($breakpoint-medium) {
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid $pebble-grey;
    }
  }

  .cmp-navigation__group & {
    position: relative;
  }
}

.cmp-navigation__item-link {
  &:focus-visible {
    border: 1px dashed $tag-outline;
    outline: none;
  }
}

.cmp-header__main-nav .navigation-main .cmp-navigation__item-link,
.cmp-header__main-nav-link {
  position: relative;
  display: inline-block;
  padding: rem(16) rem(23) rem(26);
  font-weight: $font-weight-regular;
  font-size: $font-size-2;
  line-height: $font-height-1;
  color: $header-main-font-color;
  text-decoration: none;
  background-color: transparent;
  border: none;

  &:focus-visible {
    outline: 1px dashed $button-outline;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: rem(23);
    left: rem(23);
    width: 0;
    height: 1px;
    background-color: $header-main-active-color;
    transition: width 0.4s cubic-bezier(0.33, 1, 0.68, 1);

    @media screen and (max-width: 1400px) {
      left: rem(10);
    }

    @include until($breakpoint-medium) {
      bottom: rem(-1);
      left: rem(0);
    }
  }

  @include until($breakpoint-medium) {
    width: 100%;
  }

  &:hover,
  &:focus {
    color: $header-main-font-color;

    &::before {
      width: calc(100% - #{rem(46)});

      @media screen and (max-width: 1400px) {
        width: calc(100% - #{rem(20)});
      }

      @include until($breakpoint-medium) {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    padding: rem(16) rem(10) rem(26);
  }

  @media screen and (max-width: 1300px) {
    font-size: $font-size-1-mobile;
    line-height: $font-height-1-mobile;
  }

  @include until($breakpoint-large) {
    font-size: $font-size-1-b-mobile;
    line-height: $font-height-1-b-mobile;
  }

  @include until($breakpoint-medium) {
    padding: rem(16) 0;
    font-size: $font-size-1;
    line-height: $font-height-1;
  }
}

// active link
.cmp-header__main-nav .navigation-main .cmp-navigation__item--active>.cmp-navigation__item-link,
.cmp-header__main-nav .navigation-main .cmp-navigation__item>.cmp-navigation__item-link--active {
  color: $header-main-font-color;

  &::before {
    width: calc(100% - #{rem(46)});

    @media screen and (max-width: 1400px) {
      width: calc(100% - #{rem(20)});
    }

    @include until($breakpoint-medium) {
      width: 100%;
    }
  }
}

.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group .cmp-navigation__group .cmp-navigation__item {
  position: relative;
  width: 100%;
}

// submenu link
.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group .cmp-navigation__group .cmp-navigation__item-link {
  padding: rem(9) rem(45) rem(9) 0;
  font-weight: $font-weight-light;
  font-size: $font-title-size-5;
  line-height: $font-title-height-5;
  color: $header-main-submenu-color;
  width: 72%;
  hyphens: auto;
  cursor: pointer;

  &::before {
    display: none;
  }

  &:hover,
  &:focus-visible {
    color: $header-main-active-color;

    &+ul+.cmp-header__main-nav-submenu-toggle {
      background-color: $header-main-active-color;
    }
  }

  &:focus-visible {
    outline: 1px dashed $button-outline;

    &+ul+.cmp-header__main-nav-submenu-toggle {
      outline: 1px dashed $button-outline;
    }
  }

  @include until($breakpoint-large) {
    width: 100%;
  }

  @include until($breakpoint-medium) {
    padding: rem(10) rem(45) rem(10) 0;
    font-size: $font-title-size-5-mobile;
    line-height: $font-title-height-5-mobile;
  }
}

// active submenu link
.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group .cmp-navigation__group .cmp-navigation__item--active>.cmp-navigation__item-link,
.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group .cmp-navigation__group .cmp-navigation__item>.cmp-navigation__item-link--expanded {
  color: $header-main-active-color;
}

// submenu 2nd level link
.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group .cmp-navigation__group .cmp-navigation__group .cmp-navigation__item-link {
  font-weight: $font-weight-regular;
  font-size: $font-size-2;
  line-height: $font-height-2;
  padding: rem(3) 0;

  @include until($breakpoint-medium) {
    font-size: 1rem;
    line-height: $font-height-1-b-mobile;
    padding: rem(2) 0;
  }
}

.cmp-header__main-nav-item-mobile-list {
  display: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  .cmp-header__main-nav-item {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .cmp-header__main-nav--active:not(.cmp-header__main-nav--submenu-active) & {
    @include from($breakpoint-medium) {
      max-height: rem(100);
    }
  }

  @include until($breakpoint-medium) {
    display: block;
    max-height: rem(52);

    .cmp-header__main-nav-item {
      flex-direction: column;
    }

    .cmp-header__main-nav--submenu-active & {
      max-height: 0;
    }

    .cmp-header--auth-active & {
      max-height: none;
    }
  }
}

.cmp-header__main-nav-link--btn-mobile-auth {
  padding: rem(13) 5% rem(13) calc(5% + #{rem(34)});
  background-color: $header-mobile-log-in-background;
  color: $header-mobile-log-in-text-color;
  text-align: left;

  &::after {
    content: '';
    position: absolute;
    left: 5%;
    top: rem(10);
    width: rem(28);
    height: rem(28);
    background-color: $carbon-grey;
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
    @extend .icon-login;
  }

  &::before {
    display: none;
  }

  &:not(.is-logged-in) {
    position: relative;

    > .btn-mobile-auth {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;

      div {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.is-logged-in {
    background-color: $header-mobile-log-in-background-active;
    color: $header-mobile-log-in-text-color-active;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: $header-mobile-log-in-icon-color-active;
      mask-repeat: no-repeat;
      mask-size: cover;
      @extend .icon-arrow-navi;
      transform: rotate(-90deg);
      width: rem(24);
      height: rem(24);
      top: rem(14);
      right: 5%;
      left: auto;
      bottom: auto;
    }

    &::after {
      background-color: $header-mobile-log-in-icon-color-active;
    }
  }
}

.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group>.cmp-navigation__item>.cmp-navigation__item-link:not(:only-child) {
  padding-right: rem(42);

  &::after {
    content: '';
    position: absolute;
    width: rem(16);
    height: rem(16);
    top: rem(17);
    right: rem(22);
    background-color: $header-main-font-color;
    mask-repeat: no-repeat;
    mask-size: cover;
    @extend .icon-arrow-navi;

    @media screen and (max-width: 1400px) {
      right: rem(10);
    }

    @media screen and (max-width: 1300px) {
      top: rem(19);
    }

    @include until($breakpoint-large) {
      top: rem(17);
    }

    @include until($breakpoint-medium) {
      transform: rotate(-90deg);
      width: rem(24);
      height: rem(24);
      top: rem(17);
      right: rem(0);
    }
  }

  &:hover,
  &:focus {

    &::after {
      background-color: $header-main-active-color;
      @extend .icon-arrow-navi-hi;
    }

    &::before {
      width: calc(100% - #{rem(65)});

      @media screen and (max-width: 1400px) {
        width: calc(100% - #{rem(40)});
      }

      @include until($breakpoint-medium) {
        width: 100%;
      }
    }
  }

  &.cmp-navigation__item-link--expanded {
    color: $header-main-active-color;

    &::after {
      @extend .icon-arrow-navi-hi;
    }
  }

  @media screen and (max-width: 1400px) {
    padding-right: rem(30);
  }

  @include until($breakpoint-medium) {
    padding-left: 0;
    padding-right: 0;
  }
}

.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group>.cmp-navigation__item--active>.cmp-navigation__item-link:not(:only-child),
.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group>.cmp-navigation__item>.cmp-navigation__item-link--active:not(:only-child) {

  &::before {
    width: calc(100% - #{rem(65)});

    @media screen and (max-width: 1400px) {
      width: calc(100% - #{rem(40)});
    }

    @include until($breakpoint-medium) {
      width: 100%;
    }
  }
}

.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group>.cmp-navigation__item>.cmp-navigation__item-link--active:not(:only-child) {

  &::after {
    background-color: $header-main-active-color;
    @extend .icon-arrow-navi-hi;
  }
}

.cmp-header__main-nav-submenu-toggle {
  color: transparent;
  display: block;
  position: absolute;
  top: rem(3);
  right: 26.8%;
  width: rem(43);
  height: rem(43);
  background-color: $header-main-font-color;
  mask-repeat: no-repeat;
  mask-size: rem(33) rem(33);
  mask-position: top center;
  @extend .icon-arrow-navi;
  z-index: 1;
  cursor: pointer;
  pointer-events: none;

  &:hover,
  &:focus {
    background-color: $header-main-active-color;
  }

  &.cmp-header__main-nav-submenu-toggle--active {
    @extend .icon-arrow-navi-hi;
    background-color: $header-main-mobile-menu-icon-color;
  }

  @include until($breakpoint-large) {
    right: 8.8%;
  }

  @include until($breakpoint-small) {
    right: 0;
    mask-size: rem(28) rem(28);
  }
}

.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group>.cmp-navigation__item>.cmp-navigation__group,
.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group>.cmp-navigation__item>.cmp-navigation__group .cmp-navigation__group,
.cmp-header__auth,
.cmp-header__search {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  position: absolute;
  z-index: 10;
  left: 0;
  top: calc(100% - #{rem(40)});
  width: 100%;
  padding: .75rem 5% 3.125rem;
  background-color: $header-background;
  transition: opacity 0.8s cubic-bezier(0.33, 1, 0.68, 1);
  transition-property: opacity, max-height;


  @include from($breakpoint-medium) {
    padding: 0 13.5% 0;
    transition-property: opacity, max-height, padding;

    &::before {
      content: '';
      position: absolute;
      top: rem(16);
      left: 0;
      width: 0;
      height: 1px;
      background-color: $header-main-border-color;
      transition: width 0.8s cubic-bezier(0.33, 1, 0.68, 1);
      z-index: 10;
    }
  }

  @include until($breakpoint-medium) {
    top: 100%;
  }
}

.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group>.cmp-navigation__item>.cmp-navigation__group,
.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group>.cmp-navigation__item>.cmp-navigation__group .cmp-navigation__group {
  pointer-events: none;

  @include until($breakpoint-medium) {
    top: 0;
  }
}

.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group>.cmp-navigation__item>.cmp-navigation__group .cmp-navigation__group {
  position: relative;
  top: 0;
  left: 0;
  padding: 0 rem(2) 0;

  &::before {
    display: none;
  }
}

.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group>.cmp-navigation__item>.cmp-navigation__group.cmp-navigation__group--active,
.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group>.cmp-navigation__item>.cmp-navigation__group .cmp-navigation__group.cmp-navigation__group--active,
.cmp-header__auth--active,
.cmp-header__search--active {
  max-height: rem(1200);
  opacity: 1;
  padding: rem(105) 13.5% rem(178);
  z-index: 10;
  pointer-events: auto;

  &::before {
    width: 91.87%;

    @include until(500px) {
      width: 88%;
    }
  }

  @include until($breakpoint-medium) {
    padding: rem(12) 5% rem(50);
  }
}

.cmp-header__main-nav .navigation-main>.cmp-navigation>.cmp-navigation__group>.cmp-navigation__item>.cmp-navigation__group .cmp-navigation__group.cmp-navigation__group--active {
  padding: rem(8) rem(2) rem(35);

  @include until($breakpoint-medium) {
    padding: rem(10) rem(2) rem(32);
  }
}

.cmp-header__main-nav-submenu-title-wrapper {
  list-style-type: none;
  width: 100%;

  @include until($breakpoint-medium) {
    position: relative;
    margin-bottom: 1.5rem;

    &::after {
      content: "";
      width: calc(100% + 14%);
      height: 5px;
      background: linear-gradient(#7a7a7a6e 0%, #0000 100%);
      position: absolute;
      left: -7%;
    }
  }
}

.cmp-header__main-nav-submenu-title {
  font-size: $font-date;
  line-height: $font-date-height;
  color: $header-main-title-color;
  font-weight: $font-weight-semi-bold;
  margin-bottom: rem(10);
  letter-spacing: 1px;
  width: 100%;

  @include until($breakpoint-medium) {
    font-size: 1rem;
    margin-bottom: rem(16);
    display: flex;
    align-items: center;
    cursor: pointer;

    &::before {
      content: "";
      margin-top: 1px;
      width: rem(24);
      height: rem(24);
      background-color: $header-main-font-color;
      mask-repeat: no-repeat;
      mask-size: cover;
      transform: rotate(90deg);
      display: inline-block;
    }
  }

  &::before {
    @extend .icon-arrow-navi;
  }
}

.cmp-header__main-nav-submenu-list {
  padding-left: 0;
  width: 50%;

  &:nth-of-type(2) {
    padding-left: rem(15);

    @include until(600px) {
      padding-left: 0;
    }
  }

  @include until($breakpoint-medium) {
    .cmp-navigation__item {
      border: none !important;
    }
  }

  .cmp-header__main-nav-submenu .cmp-header__main-nav-submenu & {
    width: 100%;

    &:nth-of-type(2) {
      padding-left: 0;
    }
  }

  @include until(600px) {
    width: 100%;
  }
}

.cmp-header__main-nav-submenu-list-wrapper {
  display: flex;
  width: 100%;

  @include until(600px) {
    display: block;
  }
}

.cmp-header__background-shape-left {
  mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Vrstva_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='52px' height='56px' viewBox='0 0 52 56' enable-background='new 0 0 52 56' xml:space='preserve'%3E%3Cpath fill='%23010202' d='M52,0H0l1.604,0.389c3.396,0.823,6.19,3.363,7.397,6.626C9.028,7.087,9.053,7.17,9.075,7.262l-0.012,7.057 C9.063,37.239,27.605,56,50.453,56H52V0z'/%3E%3C/svg%3E%0A");
}

.cmp-header__background-shape-right {
  mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Vrstva_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='52px' height='56px' viewBox='0 0 52 56' enable-background='new 0 0 52 56' xml:space='preserve'%3E%3Cpath fill='%23010202' d='M0,0h52l-1.604,0.389c-3.396,0.823-6.19,3.363-7.397,6.626c-0.027,0.072-0.052,0.155-0.074,0.247 l0.012,7.057C42.937,37.239,24.395,56,1.547,56H0V0z'/%3E%3C/svg%3E%0A");
}

.cmp-header__auth-close,
.cmp-header__main-nav-mobile-close,
.cmp-header__generic-nav-mobile-close {
  position: absolute;
  top: rem(1);
  left: 92.15%;
  width: rem(34);
  height: rem(34);
  @extend .icon-close-x;
  background-color: $header-main-icon-color;
  mask-repeat: no-repeat;
  mask-size: rem(24) rem(24);
  mask-position: center;
  cursor: pointer;
  transition: background-color 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  &:hover,
  &:focus {
    background-color: $header-main-active-color;
  }

  @include until(500px) {
    left: 89.5%;
  }
}

.cmp-header__main-nav-submenu-close {
  position: absolute;
  top: rem(1);
  left: 92.15%;
  list-style-type: none;

  @include until(500px) {
    left: 89.5%;
  }

  @include until($breakpoint-medium) {
    visibility: hidden;
  }
}

.cmp-header__main-nav-submenu-close-btn {
  color: transparent;
  width: rem(34);
  height: rem(34);
  @extend .icon-close-x;
  background-color: $header-main-icon-color;
  mask-repeat: no-repeat;
  mask-size: rem(24) rem(24);
  mask-position: center;
  cursor: pointer;
  transition: background-color 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  &:hover,
  &:focus {
    background-color: $header-main-active-color;
  }
}

.cmp-header__main-nav-mobile-close,
.cmp-header__generic-nav-mobile-close {
  display: none;
  z-index: 10;

  @include until($breakpoint-medium) {
    display: block;
  }
}

.cmp-header__main-nav-mobile-btn {
  display: none;
  position: absolute;
  right: 5%;
  top: rem(28);
  z-index: 12;
  width: rem(40);
  height: rem(40);
  border: none;
  background-color: $header-main-mobile-menu-icon-color;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  text-indent: 1000%;
  white-space: nowrap;

  &::before,
  &::after {
    content: '';
    width: rem(24);
    height: rem(24);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $header-main-mobile-menu-icon-shape-color;
    mask-repeat: no-repeat;
    mask-size: cover;
    transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  }

  &::before {
    @extend .ico-menu-hamburger;
  }

  &::after {
    @extend .icon-close-x;
    opacity: 0;
  }

  @include until($breakpoint-medium) {
    display: block;
  }
}

.cmp-header__main-nav-mobile-btn--active {

  &::before {
    opacity: 0;
  }

  &::after {
    opacity: 1;
  }
}

.cmp-header--auth-active {
  .cmp-header__logo {
    &.nav-overlay-active {
      opacity: 0;
      pointer-events: none;
    }

    z-index: 13;
    pointer-events: none;
    padding-right: rem(15);

    > a> img, > a > .cmp-header__logo-text {
      opacity: 0;
    }

    a::after {
      content: '';
      position: absolute;
      width: rem(140);
      height: rem(38);
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      z-index: 1;
      background-color: $white;
      @extend .logo-inverted;
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;

      @include until($breakpoint-medium) {
        width: rem(100);
        height: rem(27);
      }
    }
  }
}

.cmp-header__logged-in-mobile-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  background-color: $header-mobile-log-in-background-active;
  z-index: 12;
  transition: height 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  overflow: hidden;
  box-shadow: 0 2px 4px 0 #00000050;

  .cmp-header--auth-active & {
    height: calc(100% + #{rem(52)});
  }

  &::before {
    content: '';
    position: absolute;
    right: 5%;
    top: rem(28);
    z-index: 12;
    width: rem(40);
    height: rem(40);
    border-radius: 50%;
    background-color: $header-mobile-log-in-icon-color-active;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    right: 5%;
    top: rem(28);
    z-index: 12;
    width: rem(40);
    height: rem(40);
    background-color: $header-mobile-log-in-background-active;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: rem(24) rem(24);
    @extend .icon-close-x;
    pointer-events: none;
  }
}

.cmp-header__overlay--active {
  color: $header-mobile-log-in-text-color-active;
}

.cmp-header__logged-in-mobile-overlay-button {
  position: absolute;
  display: flex;
  align-items: flex-end;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  padding: rem(16) calc(5% + #{rem(24)});
  color: $header-mobile-log-in-text-color-active;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    right: 5%;
    bottom: rem(14);
    width: rem(28);
    height: rem(28);
    background-color: $header-mobile-log-in-icon-color-active;
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
    @extend .icon-login;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    background-color: $header-mobile-log-in-icon-color-active;
    mask-repeat: no-repeat;
    mask-size: cover;
    @extend .icon-arrow-navi;
    width: rem(24);
    height: rem(24);
    bottom: rem(15);
    left: 5%;
    transform: rotate(90deg);
  }
}

.navigation-main .cmp-navigation,
.navigation-generic .cmp-navigation {
  max-height: 80rem;
  transition: max-height 0.4s cubic-bezier(0.33, 1, 0.68, 1);
}

.cmp-header__generic-nav-logged-in-menu-wrapper {

  @include until($breakpoint-medium) {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  }
}

.cmp-header--auth-active {

  @include until($breakpoint-medium) {
    .navigation-main .cmp-navigation {
      overflow: hidden;
      max-height: 0;
    }

    .navigation-generic .cmp-navigation {
      overflow: hidden;
      max-height: 0;
    }

    .cmp-header__generic-nav-logged-in-menu-wrapper {
      max-height: 125rem;
    }
  }
}
