@mixin grid-item {
  padding-left: $grid-half-gap;
  padding-right: $grid-half-gap;
}

@mixin grid-item--1 {
  width: $grid-column;
}

@mixin grid-item--2 {
  width: 2 * $grid-column + 2* $grid-gap;
}

@mixin grid-item--3 {
  width: 3 * $grid-column + 3 * $grid-gap;
}

@mixin grid-item--4 {
  width: 4 * $grid-column + 4 * $grid-gap;
}

@mixin grid-item--5 {
  width: 5 * $grid-column + 5 * $grid-gap;
}

@mixin grid-item--6 {
  width: 50%;
}

@mixin grid-item--7 {
  width: 7 * $grid-column + 7 * $grid-gap;
}

@mixin grid-item--8 {
  width: 8 * $grid-column + 8 * $grid-gap;
}

@mixin grid-item--9 {
  width: 9 * $grid-column + 9 * $grid-gap;
}

@mixin grid-item--10 {
  width: 10 * $grid-column + 10 * $grid-gap;
}

@mixin grid-item--11 {
  width: 11 * $grid-column + 11 * $grid-gap;
}

@mixin grid-item--12 {
  width: 100%;
}

.grid-wrapper {
  display: flex;
}

.grid-item {
  @include grid-item;
}

.grid-item--1 {
  @include grid-item--1;
}

.grid-item--2 {
  @include grid-item--2;
}

.grid-item--3 {
  @include grid-item--3;
}

.grid-item--4 {
  @include grid-item--4;
}

.grid-item--5 {
  @include grid-item--5;
}

.grid-item--6 {
  @include grid-item--6;
}

.grid-item--7 {
  @include grid-item--7;
}

.grid-item--8 {
  @include grid-item--8;
}

.grid-item--9 {
  @include grid-item--9;
}

.grid-item--10 {
  @include grid-item--10;
}

.grid-item--11 {
  @include grid-item--11;
}

.grid-item--12 {
  @include grid-item--12;
}
