.cmp-presenter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 6%;
  padding-right: 6%;
  min-height: rem(90);
  margin-bottom: rem(20);

  &::before {
    content: '';
    position: absolute;
    width: 88%;
    height: 2px;
    background-color: $presenter-line-color;
    top: calc(50% - 1px);
    left: 6%;
  }

  @include until($breakpoint-large) {
    margin-bottom: rem(15);
  }

  @include until($breakpoint-small) {
    min-height: rem(70);
    margin-bottom: rem(10);
  }
}

.cmp-presenter__text {
  font-size: $font-size-3;
  font-weight: $font-weight-semi-bold;
  padding-left: rem(10);
  padding-right: rem(10);
  position: relative;
  z-index: 1;
  background-color: $white;

  @include until($breakpoint-small) {
    font-size: $font-size-4;
    padding-left: rem(6);
    padding-right: rem(6);
  }
}

.cmp-presenter__image {
  display: flex;
  position: relative;
  z-index: 1;
  padding-left: rem(10);
  background-color: $white;

  a {
    display: flex;
  }

  img {
    max-height: rem(90);
    width: auto;

    @include until($breakpoint-small) {
      max-height: rem(70);
    }
  }

  @include until($breakpoint-small) {
    padding-left: rem(6);
  }
}
